import { Table } from "antd";
import styled from "styled-components";
import { FormField } from "../../../../../common-components/form-field/FormField";

export const ContentSpace = styled.div`
  height: ${({ space }) => space || "28px"};
`;

export const InviteType = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.color.text};
  margin-right: 12px;
`;

export const InviteMessageLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin: 18px 0 14px;
  color: ${({ theme }) => theme.color.surface};
`;

export const inviteAsWidth = { width: "201px" };

export const InviteSwitchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  div {
    display: flex;
    align-items: center;
  }
  p {
    display: inline-block;
    margin: 0 12px 0 0;
  }
`;

export const SessionRoleTable = styled(Table)`
  .ant-spin-nested-loading .ant-table-container {
    box-shadow: none;
    font-size: small;
    padding: 0;
  }
`;
