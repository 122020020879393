import { Radio } from "antd";
import React from "react";
import { RadioButton } from "./style";

export const GroupButton = ({
  activeIndex = 0,
  setActiveIndex = () => null,
  buttonList = [
    { label: "Label 1", value: 0 },
    { label: "Label 2", value: 1 },
    { label: "Label 3", value: 2 },
  ],
  buttonWidth,
  activeTextColor,
  width
}) => {
  return (
    <Radio.Group
      value={activeIndex}
      onChange={(event) => {
        setActiveIndex(event.target.value);
      }}
      style={width ? {width} : {}}
    >
      {buttonList.map((option, index) => (
        <RadioButton
          value={option.value}
          key={index}
          buttonWidth={buttonWidth}
          activeTextColor={activeTextColor}
        >
          {option.label}{" "}
        </RadioButton>
      ))}
    </Radio.Group>
  );
};
