import { Content } from "antd/lib/layout/layout";

import styled from "styled-components";
import theme from "../../../styles/theme";

export const ChooseFileRow = styled.div`
  width: 246px;
`;

export const SpeakerContent = styled(Content)`
  padding-left: 36px;
  padding-bottom: 40px;
`;

export const BioFieldStyle = styled.div`
  width: 577px;
  margin-bottom: 20px;
`;

export const leftButtonStyle = `
  border: 1px solid ${theme.color.textActive};
  color: ${theme.color.sonicSilver};
`;
export const rightButtonStyle = `
  color: ${theme.color.textOnSurface};
  background-color:  ${theme.color.textActive};
`;
