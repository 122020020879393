import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { SwitchBoxContainer, SwitchLabel } from "./style";

export const SwitchBox = ({
  label = "",
  onChange = () => null,
  disabled = false,
  containerWidth = {},
  checked,
  labelColor = "",
  unCheckedChildren = "",
  checkedChildren = "On",
  rightLabel = "",
}) => {
  const [active, setActive] = useState(checked);
  const onChangeValue = (value) => {
    setActive(value);
    onChange(value);
  };
  useEffect(() => {
    setActive(checked);
  }, [checked]);
  return (
    <SwitchBoxContainer disabled={disabled} style={containerWidth}>
      <SwitchLabel labelColor={labelColor}>{label}</SwitchLabel>
      <Switch
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={onChangeValue}
        disabled={disabled}
        checked={active}
      />
      {rightLabel ? (
        <SwitchLabel labelColor={labelColor} rightLabel>
          {rightLabel}
        </SwitchLabel>
      ) : null}
    </SwitchBoxContainer>
  );
};
