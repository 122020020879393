import styled, { css } from "styled-components";

export const AddPollHeader = styled.div`
  margin: 20px 0 29px;
`;

export const PollRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PollToggleWrapper = styled.div`
  width: 45%;
  > div {
    > span {
    font-weight: 600;
    :first-child {
      ${({ multiChoice }) =>
        multiChoice &&
        css`
          opacity: 0.5;
        `}
    }
    :last-child {
      ${({ multiChoice }) =>
        !multiChoice &&
        css`
          opacity: 0.5;
        `}
    }
  }
  }
 
`;

export const Div = styled.div`
  margin-top: 10px;
`;

export const NumberOfText = styled.i`
  font-size: 9px;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black_4};
  margin-left: 2px;
`;

export const pollQuestionStyle = { width: "100%", paddingTop: "5px" }