import { Button } from "antd";
import styled, { css } from "styled-components";

export const ActionHeaderContainer = styled.div`
  display: flex;
  justify-content: ${({ titlePresent }) =>
    titlePresent ? "space-between" : "flex-end"};
  margin-top: 26px;
`;

export const CancelButton = styled(Button)`
  border-color: ${({ theme }) => theme.color.grey27};
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.grey27};
  margin-right: 12px;
  border-radius: 2px;
  width: 84px;
  ${({ leftButtonStyle }) =>
    leftButtonStyle &&
    css`
      ${leftButtonStyle}
    `}
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.grey27};
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.textOnSurface};
  border-radius: 2px;
  width: 84px;
  ${({ rightButtonStyle }) =>
    rightButtonStyle &&
    css`
      ${rightButtonStyle}
    `}
`;
