import styled from "styled-components";

export const EventInfoContainer = styled.div`
  display: flex;
  .ant-form-item-explain {
    font-size: x-small;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.color.grey91};
  border-style: solid;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.text};
  height: 43px;
  padding-left: 26px;
`;

export const RightContainer = styled.div`
  width: 60%;

  .ant-form-item {
    display: inline-block;
  }

  .time-container {
    display: flex;
    align-items: baseline;
  }
`;
export const Wrapper = styled.div`
  padding: 23px 19px 23px 26px;
  .ant-space {
    display: flex;
  }
  .ant-input,
  .ant-select {
    font-size: 12px;
    line-height: 20px;
    height: 30px;
  }
  .ant-picker-input input {
    font-size: 12px;
  }
`;

export const ToText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.grey35};
  margin: 0 ${({ gap = "9" }) => gap}px;
`;

export const LeftContainer = styled.div`
  border-style: solid;
  border-width: 0;
  border-right-width: 1px;
  border-color: ${({ theme }) => theme.color.grey91};
  width: 40%;
  padding-left: 15px;
  ${Title} {
    padding-left: 16px;
  }
  ${Wrapper} {
    padding-left: 16px;
  }
`;

export const selectWidth = { width: "460px" };
export const dateStyle = { height: "30px", width: "170px" };
export const descriptionStyle = { paddingTop: "6px" };
