import { DeleteFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { SideMenu } from "../../../../../common-components/side-menu/SideMenu";
import { SessionMenuOption } from "./style";
import moment from "moment";
import {
  SpeakerAddButton,
  SpeakerMenuFooter,
} from "../../../speaker/components/speaker-side-menu/style";
import { Popconfirm } from "antd";

export const SessionSideMenu = ({
  sessionList,
  onChangeSession,
  selectedSession,
  deleteSession,
  footer = true,
}) => {
  const onSelect = (session_id) => {
    console.log(session_id);
    onChangeSession(session_id);
  };
  const [showDeletePopover, setShowDeletePopover] = React.useState(false);
  const [deleteSessionId, setDeleteSessionId] = useState(false);

  const showPopconfirm = (session_id) => {
    setDeleteSessionId(session_id);
    setShowDeletePopover(true);
  };

  const handleOk = (session_id) => {
    deleteSession(session_id);
    setShowDeletePopover(false);
  };

  const handleCancel = (e) => {
    setShowDeletePopover(false);
    e.stopPropagation();
  };

  const footerContent = footer ? (
    <SpeakerMenuFooter className="footer">
      <SpeakerAddButton onClick={() => onSelect()} data-key="">
        + Add Session
      </SpeakerAddButton>
      {/* <PreviousButton>Previous Speakers</PreviousButton> */}
    </SpeakerMenuFooter>
  ) : null;

  return (
    <SideMenu footer={footerContent}>
      {sessionList.map((data, index) => (
        <SessionMenuOption
          onClick={() => onSelect(data.session_id)}
          active={data.session_id == selectedSession.session_id}
        >
          <p>{data.name}</p>
          <div>
            <span onClick={() => onSelect(data.session_id)}>
              <span>{moment(data.start_time).format("LL")}</span>
              <span>{moment(data.start_time).format("HH:mm")}</span>
            </span>
            <Popconfirm
              title="Delete this session?"
              visible={showDeletePopover && deleteSessionId === data.session_id}
              onConfirm={() => handleOk(data.session_id)}
              onCancel={handleCancel}
            >
            <DeleteFilled
              onClick={(e) => {
                showPopconfirm(data.session_id);
                e.stopPropagation();
              }}
            />
            </Popconfirm>
          </div>
        </SessionMenuOption>
      ))}
    </SideMenu>
  );
};
