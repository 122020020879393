import { Table } from "antd";
import React, { Fragment } from "react";
import { UserCountText } from "../../vmeet/events/attendees/style";
import { InputSearch } from "../input-search/InputSearch";
import { TableSubHeader } from "./style";

export const TableComponent = ({
  subHeaderRightElement,
  columns,
  data = [],
  scroll = { y: "calc(100vh - 300px)" },
  pagination = false,
  searchPlaceholder,
  style,
  hideSubHeader = false,
  searchOnChange = () => null,
}) => {
  return (
    <Fragment>
      {hideSubHeader ? null : (
        <TableSubHeader>
          <div>
            <InputSearch
              placeholder={searchPlaceholder || "Search user"}
              inputOnChange={searchOnChange}
            />
            <UserCountText>
              {(data || []).length} users in the list
            </UserCountText>
          </div>
          {subHeaderRightElement}
        </TableSubHeader>
      )}
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        scroll={scroll}
        style={style}
      />
    </Fragment>
  );
};
