import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useParams } from "react-router";
import { NewEvent } from "./events/new-event";
import { Upcomingevent } from "./events/upcoming-events";

import Layout from "antd/lib/layout/layout";
import {
  LayoutSider,
  MenuItem,
  MenuTitle,
  SubMenuItem,
  VmeetContainer,
} from "./style";
import { EventDetails } from "./events";
import {
  vmeetAuditTrailRoute,
  vmeetEventRoute,
  vmeetPeopleRoute,
} from "../constant/routeConstant";
import { People } from "./people";
import { AuditTrail } from "./audit-trail";
import { useDispatch } from "react-redux";
import { setCurrentEvent } from "../actions/setCurrentEvent";
import LoginForm from "../login";
import { userManagementHttp, vMeetHttp } from "../http/axiosInstances";
import { useWillMount } from "../hooks/useWillMount";

const menus = {
  menu_1_1: vmeetEventRoute,
  1: "/vmeet/events/1/basicinfo",
  menu_2: vmeetPeopleRoute,
  menu_3: vmeetAuditTrailRoute,
};

export const Vmeet = ({ history }) => {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([
    {
      label: "Upcoming Events",
      value: "menu_1_1",
      events: [],
    },
    {
      label: "Ongoing Events",
      value: "menu_1_2",
      events: [],
    },
  ]);

  const menu = [
    {
      label: "Events",
      value: "menu_1",
      subMenuOptions: [],
    },
    {
      label: "People",
      value: "menu_2",
      subMenuOptions: [],
    },
    {
      label: "Audit Trail",
      value: "menu_3",
      subMenuOptions: [],
    },
  ];

  const getEventDetails = (eventId) => {
    console.log(eventId);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await vMeetHttp.get("/event/" + eventId);
        return resolve(response.data.data);
      } catch (error) {
        console.error(error);
        return reject(error);
      }
    });
  };

  const { id } = useParams();

  useWillMount(() => {
    console.log("index use effect.");
    if (!vMeetHttp.defaults.headers.common["userId"]) {
      let code = localStorage.getItem("vmAdmin_code");
      let org = localStorage.getItem("vmAdmin_organizationId");
      let userId = localStorage.getItem("vmAdmin_userId");

      vMeetHttp.defaults.headers.common["Authorization"] = "Bearer " + code;
      vMeetHttp.defaults.headers.common["userId"] = userId;
      vMeetHttp.defaults.headers.common["organizationId"] = org;

      userManagementHttp.defaults.headers.common["Authorization"] =
        "Bearer " + code;
      userManagementHttp.defaults.headers.common["userId"] = userId;
      userManagementHttp.defaults.headers.common["organizationId"] = org;
    }

    let id = localStorage.getItem("vmAdmin_eventId");

    if (id) {
      getEventDetails(id).then((event) => dispatch(setCurrentEvent(event)));
    }
  });

  /* useEffect(() => {}, []); */

  useEffect(() => {
    let events = eventList[0].events;
    if (events.length > 0) {
      if (
        window.location.pathname === "/vmeet" ||
        window.location.pathname === "/vmeet/events"
      )
        loadEvent(events[0]);
      getEventDetails(events[0].id).then((event) =>
        dispatch(setCurrentEvent(event))
      );
    }
  }, [eventList]);

  const eventRoute = (eventId) => `/vmeet/events/${eventId}/basicinfo`;

  const loadEvent = (event) => {
    const path = eventRoute(event.id);
    history.push(path);
  };

  const onMenuClick = (e) => {
    const path = menus[e["key"]];
    history.push(path);
  };
  const { pathname } = history.location;

  const onMenuTitleClick = (key) => {
    const path = menus[key];
    history.push(path);
  };

  return (
    <Layout>
      <LayoutSider style={{ display: "none" }}>
        <Menu mode="inline" onClick={onMenuClick} expandIcon={<Fragment />}>
          {menu.map((menu) => (
            <SubMenu
              key={menu.value}
              title={
                <MenuTitle
                  onClick={(e) => {
                    e.preventDefault();
                    onMenuTitleClick(menu.value);
                  }}
                  className={pathname === menus[menu.value] ? "active" : ""}
                >
                  {menu.label}
                </MenuTitle>
              }
            >
              {eventList.map((subMenu) => {
                return (
                  <SubMenuItem
                    key={subMenu.value}
                    title={subMenu.label}
                    className={`sub-menu ${
                      pathname === menus[subMenu.value] ? "active" : ""
                    }`}
                    onTitleClick={onMenuClick}
                  >
                    {subMenu.events.map((event) => {
                      const activeSubMenu =
                        pathname === menus[event.id] ||
                        pathname.includes(`${vmeetEventRoute}/${event.id}`);
                      return (
                        <MenuItem
                          key={event.id}
                          className={activeSubMenu ? "active" : ""}
                          onClick={() => loadEvent(event)}
                        >
                          {event.name}
                        </MenuItem>
                      );
                    })}
                  </SubMenuItem>
                );
              })}
            </SubMenu>
          ))}
        </Menu>
      </LayoutSider>
      <VmeetContainer>
        <Switch>
          <Route path="/vmeet" exact component={LoginForm} />
          <Route path={vmeetEventRoute} exact component={Upcomingevent} />
          <Route path={`${vmeetEventRoute}/:id`} component={EventDetails} />
          <Route path={vmeetPeopleRoute} component={People} />
          <Route path={vmeetAuditTrailRoute} component={AuditTrail} />
        </Switch>
      </VmeetContainer>
    </Layout>
  );
};
