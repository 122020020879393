import { Col, Input, Space, Row } from "antd";
import React, { useState } from "react";
import { ActionButtonHeader } from "../../../common-components/action-button-header/ActionButtonHeader";
import { GroupButton } from "../../../common-components/group-button/GroupButton";
import { FormField } from "../../../common-components/form-field/FormField";
import theme from "../../../styles/theme";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { RichTextEditor } from "../../../common-components/rich-text-editor/RichTextEditor";

const labelLetterSpacing = { letterSpacing: "0.075em" };
const rowBottom = { marginTop: "21px" }

const groupButtonList = [
  { label: "Terms and conditions", value: 0 },
  { label: "Privacy Policy", value: 1 },
  { label: "Cookie Policy", value: 2 },
];

export const Legal = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <EventLayout space='34px'>
      <ActionButtonHeader
        title="Legal"
        leftButtonAction={() => null}
        rightButtonAction={() => null}
      />
      <Row style={rowBottom}>
        <Col flex="0.6">
          <Space size={[27]} className="flex" direction="vertical" >
            <GroupButton
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              buttonList={groupButtonList}
              width={'550px'}
            />

            <FormField
              label={`${groupButtonList[activeIndex].label}*`}
              labelColor={theme.color.surface}
            >
              <RichTextEditor />
            </FormField>

            <FormField label={"Label"} labelColor={theme.color.surface}>
              <Input
                placeholder="Label name"
                size="small"
                disabled={true}
                value={groupButtonList[activeIndex].label}
                style={labelLetterSpacing}
              />
            </FormField>

          </Space>
        </Col>
      </Row>
    </EventLayout>
  );
};
