import { Button, Image } from "antd";
import styled from "styled-components";
import theme from "../../../../../../styles/theme";

export const LogoPageContainer = styled.div`
  padding-top: 30px;
  .ant-table-wrapper .ant-table-container {
    margin-right: -36px;
    background-color: ${({ theme }) => theme.color.textOnSurface};
    box-shadow: none;
    padding: 0;
    margin-bottom: 8px;
  }
  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => theme.color.textOnSurface};
  }
`;

export const ActionButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.color.grey87};
  padding: 0 12px;
  height: 25px;
  border: 1px solid ${({ theme }) => theme.color.grey87};
  font-size: 9px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.dimGrey};
  background: ${({ theme }) => theme.color.textOnSurface};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddRow = styled.div`
  border-bottom: 3px solid ${({ theme }) => theme.color.grey91};
  padding-bottom: 4px;
  padding-left: 16px;
  margin-bottom: 11px;
`;

export const FileName = styled.span`
  color: ${({ theme }) => theme.color.black_2};
`;

export const ImageContainer = styled.div`
  margin: 24px 0px;
`;

export const ActionBar = styled.div`
  display: flex;

  button {
    margin: 0 6px;
  }

  .upload-container {
    display: inline-block;

    button {
      /* padding: 0 12px; */
      height: 25px;
      font-size: xx-small;
      background: ${theme.color.textOnSurface};

      span {
        width: unset !important;
      }
    }
  }
`;

export const ErrorInfoText = styled.div`
  font-size: x-small;
  color: ${theme.color.errorBorderColor};
`;
