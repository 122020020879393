import { Button, Table } from "antd";
import styled from "styled-components";
import theme from "../../../styles/theme";

export const ScheduleTable = styled(Table)`
  margin: 10px 0;
  &.ant-table-wrapper .ant-table-container {
    padding: 0;
    border-top: 1px;
    border-left: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.grey77};
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid ${({ theme }) => theme.color.grey77};

      :first-child {
        background-color: ${({ theme }) => theme.color.surfaceLighter_2};
        border-right: 1px solid ${({ theme }) => theme.color.grey77};
        color: ${({ theme }) => theme.color.dimGrey_3};
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.075em;
      }
      :last-child {
        padding: 0;
      }
    }
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td {
    border-right: 1px solid ${({ theme }) => theme.color.grey77};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    :first-child {
      width: 90%;
    }
    :last-child {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const AddDay = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 190px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.color.grey29};
`;

export const ScheduleDateWrapper = styled.div`
  padding: 31px 0 13px;
  width: 160px;
`;

export const plusCircleStyle = { color: theme.color.textActive, fontSize: "18px" };

export const dateStyle = { width: "100%", height: "30px" }