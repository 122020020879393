import React from "react";
import { Modal } from "antd";

const BasicModal = ({
  title,
  message,
  isBasicModalVisible,
  handleOk = () => {},
  handleCancel = () => {},
}) => {
  return (
    <Modal
      title={title}
      centered
      visible={isBasicModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </Modal>
  );
};

export default BasicModal;
