import { Col, Input, Row, Select, Space, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ActionDrawer } from "../../../../../common-components/action-drawer/ActionDrawer";
import theme from "../../../../../styles/theme";
import { BulkBlockContent } from "../bulk-block-content/BulkBlockContent";
import { SingleBlockcontent } from "../single-block-content/SingleBlockContent";
import {
  ContentSpace,
  inviteAsWidth,
  InviteMessageLabel,
  InviteSwitchContainer,
  InviteType,
  SessionRoleTable,
} from "./style";
import { RichTextEditor } from "../../../../../common-components/rich-text-editor/RichTextEditor";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import { GroupButton } from "../../../../../common-components/group-button/GroupButton";

import { FormField } from "../../../../../common-components/form-field/FormField";
import { Option } from "antd/lib/mentions";

const FormRow = ({ leftElement, rightElement }) => (
  <Row gutter={20}>
    <Col span={12}>{leftElement}</Col>
    <Col span={12}>{rightElement}</Col>
  </Row>
);

const hcpList = [
  { label: "My Top HCPs (2,471)", value: "" },
  { label: "Recently Modified (230)", value: "Recently Modified (230)" },
  { label: "Other HCPs (1,264)", value: "" },
];
const labelColor = theme.color.surface;

export const InviteDrawer = ({
  onClose,
  modalVisible,
  mode,
  currentProfile,
  currentEditSandbox,
  rightButtonAction,
  leftButtonAction,
  sessionList = [],
  languages = [],
}) => {
  const [inviteType, setInviteType] = useState(0);
  const [sendEmail, setSendEmail] = useState(false);
  const [translatorRole, setTranslatorRole] = useState(false);

  const setInviteEmailContent = (content) => {
    currentEditSandbox.current.inviteEmailContent = content;
  };
  const setInviteEmailSubject = (e) => {
    currentEditSandbox.current.inviteEmailSubject = e.target.value;
  };

  const onChange = (checked) => {
    setSendEmail(checked);
    currentEditSandbox.current.sendInviteEmail = checked;
    console.log(currentEditSandbox.current);
  };

  const handleRoleChange = (session, role) => {
    if (role === -1 && Array.isArray(currentEditSandbox.current.sessionRole))
      return (currentEditSandbox.current.sessionRole =
        currentEditSandbox.current.sessionRole.filter(
          (roleAccess) => roleAccess.sessionId !== session.session_id
        ));
    let accessObject = { sessionId: session.session_id, participatingAs: role };
    if (!Array.isArray(currentEditSandbox.current.sessionRole)) {
      currentEditSandbox.current.sessionRole = [accessObject];
    } else {
      currentEditSandbox.current.sessionRole =
        currentEditSandbox.current.sessionRole.filter(
          (roleAccess) => roleAccess.sessionId !== session.session_id
        );
      currentEditSandbox.current.sessionRole.push(accessObject);
    }
    if (role === 6) {
      setTranslatorRole(true);
    } else {
      let obj = currentEditSandbox.current.sessionRole.find(
        (o) => o.participatingAs === 6
      );

      if (obj === undefined) {
        setTranslatorRole(false);
      }
    }
    console.log(currentEditSandbox.current.sessionRole);
  };

  const columns = [
    { title: "Session Name", dataIndex: "name", key: "1" },
    {
      title: "Access",
      fixed: "right",
      width: 150,
      render: (key, session) => (
        <Select
          style={{ width: "100%" }}
          placeholder="Select"
          onChange={(roleId) => handleRoleChange(session, roleId)}
        >
          <Option value={1}>Moderator</Option>
          <Option value={3}>Speaker</Option>
          <Option value={4}>Attendee</Option>
          <Option value={5}>Medical Writer</Option>
          <Option value={6}>Translator</Option>
          <Option value={-1}>No Access</Option>
        </Select>
      ),
    },
  ];

  useEffect(() => {
    console.log(modalVisible);
    setSendEmail(false);
    currentEditSandbox.current.sendInviteEmail = false;
  }, [modalVisible]);

  return (
    <ActionDrawer
      width="482px"
      visible={modalVisible}
      onClose={onClose}
      rightButtonText={mode === "invite" ? "Add" : "Done"}
      rightButtonAction={rightButtonAction}
      leftButtonText={"Cancel"}
      leftButtonAction={leftButtonAction}
    >
      {/* {mode === "invite" && (
        <>
          <InviteType>Invite type</InviteType>
          <GroupButton
            activeIndex={inviteType}
            setActiveIndex={setInviteType}
            buttonList={[
              { label: "Single", value: 0 },
              { label: "Bulk", value: 1 },
              { label: "HCP List", value: 2 },
            ]}
            buttonWidth="114px"
          />
          <ContentSpace />
        </>
      )} */}
      <Space className="flex" direction="vertical">
        {inviteType === 1 ? (
          <BulkBlockContent />
        ) : inviteType === 2 ? (
          <SelectDropdown
            label="HCP Lists"
            labelColor={labelColor}
            defaultValue="Recently Modified (230)"
            options={hcpList}
          />
        ) : (
          <SingleBlockcontent
            currentProfile={currentProfile}
            currentEditSandbox={currentEditSandbox}
            mode={mode}
            languages={languages}
            translatorRole={translatorRole}
          />
        )}
      </Space>
      {mode === "invite" && (
        <SessionRoleTable
          columns={columns}
          dataSource={sessionList}
          scroll={{ y: 500 }}
          sticky
        />
      )}
      {mode === "invite" && (
        <FormRow
          leftElement={
            <InviteSwitchContainer>
              <FormField label="Send Email?" labelColor={labelColor}>
                <Switch checked={sendEmail} onChange={onChange} size="small" />
              </FormField>
            </InviteSwitchContainer>
          }
        />
      )}
      {mode === "invite" && sendEmail && (
        <>
          <FormRow
            leftElement={
              <FormField label="Email Subject" labelColor={labelColor}>
                <Input
                  size="small"
                  disabled={false}
                  onChange={setInviteEmailSubject}
                />
              </FormField>
            }
          />
          <InviteMessageLabel>Invite Message</InviteMessageLabel>
          <RichTextEditor onStateChange={setInviteEmailContent} />
          <ContentSpace space="20px" />
          {inviteType === 2 ? (
            <SelectDropdown label="Invite as" style={inviteAsWidth} />
          ) : null}
        </>
      )}
    </ActionDrawer>
  );
};
