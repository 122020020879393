const vmeetPath = "/vmeet";
export const vmeetEventRoute = `${vmeetPath}/events`;

export const vmeetEventChildRoutes = {
  basicInfo: `${vmeetEventRoute}/:id/basicinfo`,
  attendees: `${vmeetEventRoute}/:id/attendees`,
  speakers: `${vmeetEventRoute}/:id/speakers`,
  schedule: `${vmeetEventRoute}/:id/schedule`,
  session: `${vmeetEventRoute}/:id/session`,
  lounge: `${vmeetEventRoute}/:id/lounge`,
  legal: `${vmeetEventRoute}/:id/legal`,
  userConsent: `${vmeetEventRoute}/:id/user-consent`,
  notifications: `${vmeetEventRoute}/:id/notifications`,
  loungleTables: `${vmeetEventRoute}/:id/lounge`,
  bannerLogo: `${vmeetEventRoute}/:id/banner-logo`,
};

export const vmeetPeopleRoute = `${vmeetPath}/people`;
export const vmeetPeopleChildRoutes = {}

export const vmeetAuditTrailRoute = `${vmeetPath}/audit-trail`;
export const vmeetAuditTrailChildRoutes = {}

export const basicRoutes = {
    login: '/login',
    vmeet: '/vmeet',
}