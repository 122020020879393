import React, { useState } from "react";
import { InputSearch } from "../../../../../common-components/input-search/InputSearch";
import { MenuOption } from "../../../../../common-components/menu-option/MenuOption";
import { SideMenu } from "../../../../../common-components/side-menu/SideMenu";
import theme from "../../../../../styles/theme";
import {
  PreviousButton,
  SpeakerCount,
  SpeakerMenu,
  SpeakerMenuFooter,
  SpeakerAddButton,
} from "./style";

export const WriterMenuBar = ({
  onChangeSpeaker,
  selectedSpeaker,
  speakerList = [],
  footer = true,
  width,
  MenuRightElement,
  addSpeaker
}) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTextValue, setSearchTextValue] = useState("");
  const [speakerSearchListArray, setSpeakerSearchListArray] = useState([]);
  const inputOnChange = (e) => {
    //console.log(e.target.value);
    let searchText = e.target.value.toLowerCase();
    setSearchTextValue(searchText);
    if (searchText !== "") {
      setIsSearchActive(true);
      var tempArray;
      tempArray = [...speakerList];
      const result = tempArray.filter(({ label }) =>
        label.toLowerCase().includes(searchText)
      );
      setSpeakerSearchListArray(result);
    } else {
      setIsSearchActive(false);
      setSpeakerSearchListArray([]);
    }
  };

  const footerContent = footer ? (
    <SpeakerMenuFooter className="footer">
      {/* <SpeakerAddButton onClick={addSpeaker}>+ Add Speaker</SpeakerAddButton> */}
      {/* <PreviousButton>Previous Speakers</PreviousButton> */}
    </SpeakerMenuFooter>
  ) : null;
  return (
    <SideMenu width={width} footer={footerContent}>
      <SpeakerMenu>
        <SpeakerCount>{speakerList.length} Writers in this event</SpeakerCount>
        <InputSearch
          inputOnChange={inputOnChange}
          width="95%"
          searchIconColor={theme.color.textOnSurface}
        />
        <MenuOption
          menuList={isSearchActive ? speakerSearchListArray : speakerList}
          RightElement={MenuRightElement}
          onSelectOption={onChangeSpeaker}
          defaultSelectedValue={selectedSpeaker}
        />
      </SpeakerMenu>
    </SideMenu>
  );
};
