import { Drawer } from "antd";
import React from "react";
import {
  ActionButtonContainer,
  SendInviteButton,
  ClearButton,
  Content,
  CloseIcon,
  drawerHeaderStyle,
  drawerBodyStyle,
} from "./style";

export const ActionDrawer = ({
  width = 556,
  placement = "right",
  onClose = () => null,
  visible = true,
  children,
  leftButtonAction = () => null,
  rightButtonAction = () => null,
  leftButtonText = "Clear all",
  rightButtonText = "Send invite",
  bodyStyle = {},
  buttonStyle = {},
  title,
}) => {
  return (
    <Drawer
      width={width}
      placement={placement}
      closable={true}
      closeIcon={<CloseIcon />}
      headerStyle={drawerHeaderStyle}
      bodyStyle={{ ...drawerBodyStyle, ...bodyStyle }}
      onClose={onClose}
      visible={visible}
      title={title}
    >
      <Content>{children}</Content>
      <ActionButtonContainer>
        <ClearButton size="middle" onClick={leftButtonAction} {...buttonStyle}>
          {leftButtonText}
        </ClearButton>
        <SendInviteButton
          size="middle"
          onClick={rightButtonAction}
          {...buttonStyle}
        >
          {rightButtonText}
        </SendInviteButton>
      </ActionButtonContainer>
    </Drawer>
  );
};
