import { Button } from "antd";
import styled from "styled-components";

export const SpeakerMenu = styled.div`
  .ant-input-affix-wrapper {
    margin: 0 5px 9px;
    background: ${({ theme }) => theme.color.grey};
    border: none;
    > input.ant-input {
      height: 22px;
      background: ${({ theme }) => theme.color.grey};
      color: ${({ theme }) => theme.color.textOnSurface};
    }
  }
`;

export const SpeakerCount = styled.i`
  font-size: 10px;
  line-height: 140%;
  color: ${({ theme }) => theme.color.textOnSurface};
  margin: 2px 5px 0 0;
  display: block;
  text-align: right;
`;

export const SpeakerMenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled(Button)`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const PreviousButton = styled(ActionButton)`
  width: 110px;
  background-color: ${({ theme }) => theme.color.grey};
  color: ${({ theme }) => theme.color.textOnSurface};
  margin-left: 6px;
`;

export const SpeakerAddButton = styled(ActionButton)`
  color: ${({ theme }) => theme.color.grey29};
  width: 175px;
  margin: 0 auto;
`;
