import React, { useState } from "react";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../../../common-components/notification/Notification";
import { userManagementHttp } from "../../../../../http/axiosInstances";
import { ProfileUploadModal } from "../profile-upload-modal/ProfileUploadModal";
import { ProfileCardContainer, ProfileImage } from "./style";

export const ProfileCard = ({ speaker, disabled, changeProfile }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const modalVisibleToggle = () => {
    setIsModalVisible(!isModalVisible);
  };
  const removeProfileImage = (userId) => {
    notifyProgress("Changes are being saved...");
    const removeProfileURL = `/organization/${localStorage.getItem(
      "vmAdmin_organizationId"
    )}/user/${userId}/profile/image`;
    userManagementHttp
      .delete(removeProfileURL)
      .then(() => {
        notifySuccess("Removed successfully");
        changeProfile("");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error in Removing");
      });
  };
  const uploadProfileImage = (file, userId) => {
    console.log(file);
    notifyProgress("Changes are being saved...");
    const request = { feedProfileImageLink: file };
    const uploadProfileURL = `/organization/${localStorage.getItem(
      "vmAdmin_organizationId"
    )}/user/${userId}/profile/image`;
    userManagementHttp
      .patch(uploadProfileURL, request)
      .then(() => {
        notifySuccess("Upload successfully");
        changeProfile(file);
        modalVisibleToggle();
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error in Removing");
      });
  };
  return (
    <ProfileCardContainer>
      <ProfileImage profile={speaker.feedProfileImageLink} />
      {!disabled && (
        <>
          <p onClick={modalVisibleToggle}>Upload Profile Picture</p>
          <span
            onClick={() => {
              if (window.confirm("Are you sure you want to remove?")) {
                removeProfileImage(speaker.system_user_id);
              }
            }}
          >
            Remove
          </span>
        </>
      )}

      <ProfileUploadModal
        isModalVisible={isModalVisible}
        handleCancel={modalVisibleToggle}
        uploadProfileImage={uploadProfileImage}
        userId={speaker.system_user_id}
      />
    </ProfileCardContainer>
  );
};
