import styled, { css } from "styled-components";

export const MenuOptionWrapper = styled.div`
width: ${({ width }) => width || '100%'};
position: relative;
  .ant-menu.ant-menu-dark {
    background: ${({ theme }) => theme.color.transparent};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-menu-item {
    margin: 1px !important;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:nowrap;
    background: ${({ theme }) => theme.color.surface};
    > span:first-of-type{
      width:100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
  }
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: ${({ theme }) => theme.color.textActive};
  }

  .right{
      margin-left: 4px;
  }
`;
