import axios from "axios";
import { AppConfig } from "../app-config";

export const vMeetHttp = axios.create({
  baseURL: AppConfig.api_url + AppConfig.api_version,
});

export const vMeetHttpWithoutVersion = axios.create({
  baseURL: AppConfig.api_url + "api/",
});

export const userManagementHttp = axios.create({
  baseURL: AppConfig.login_url + AppConfig.login_version,
});
