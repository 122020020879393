import styled from "styled-components";

export const AddConsent = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
  color: ${({ theme }) => theme.color.black_3};
`;

export const AdditionalOptionText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.surface};
`;

export const OptionDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.dimGrey_4};
  margin: 13px 10px 12px;
`;

export const UserConsentContainer = styled.div`
  width: 60%;
  padding-top: 26px;
`;

export const consentWidth = { width: "161px" };
