import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionButtonHeader } from "../../../common-components/action-button-header/ActionButtonHeader";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import theme from "../../../styles/theme";
import { EventInfo } from "./components/event-info/EventInfo";
import Loader from "../../../common-components/loader";
import { useParams } from "react-router";

import { notification } from "antd";
import { updateCurrentEvent } from "../../../actions/setCurrentEvent";
import { vMeetHttp } from "../../../http/axiosInstances";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";

export const BasicInformation = () => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const formDataRef = useRef();
  const eventInfo = useSelector((state) => state.currentEvent);
  const dispatch = useDispatch();
  const { id } = useParams();

  const saveEdit = async () => {
    notifyProgress("Changes are being saved...");
    try {
      console.log(formDataRef.current);
      await vMeetHttp.put("/event/" + id, formDataRef.current);
      notifySuccess("Changes saved successfully!");
      dispatch(updateCurrentEvent(formDataRef.current));
    } catch (error) {
      console.error(error);
      setEnableEdit(true);
      if (error.response) {
        let validationError = error.response.data.validationError;
        let errMessage = error.response.data.message;
        let errorMessage = (
          <div>
            {errMessage}
            {validationError && (
              <ul>
                {validationError.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            )}
          </div>
        );
        notifyError("Error saving changes.", errorMessage);
      } else notifyError("Error saving changes.", "Unkown Error");
    }
  };

  const handleEdit = () => {
    if (!enableEdit) setEnableEdit(true);
    else {
      setEnableEdit(false);
      saveEdit();
    }
  };

  const handleFormCancel = () => {
    setEnableEdit(false);
    //To reset the form, unmount and remount the EventInfo component
    setRenderForm(true);
    setTimeout(() => {
      setRenderForm(false);
    }, 1);
  };

  if (Object.keys(eventInfo).length === 0)
    return (
      <EventLayout>
        <Loader></Loader>
      </EventLayout>
    );

  return (
    <EventLayout>
      <ActionButtonHeader
        leftButtonText={enableEdit ? "Cancel" : "Cancel"}
        rightButtonText={enableEdit ? "Done" : "Edit"}
        buttonWidth="77px"
        leftButtonStyle={`
            border: 1px solid ${theme.color.textActive};
            color: ${theme.color.sonicSilver};
          `}
        rightButtonStyle={`
            color: ${theme.color.textOnSurface};
            background-color:  ${theme.color.textActive};
          `}
        leftButtonAction={handleFormCancel}
        rightButtonAction={handleEdit}
        leftButtonDisabled={!enableEdit}
      />
      {!renderForm && (
        <EventInfo disableFields={!enableEdit} formDataRef={formDataRef} />
      )}
    </EventLayout>
  );
};
