import React from "react";
import { TableComponent } from "../../../../common-components/table-component/TableComponent";
import { AuditTrailTableContainer, StatusText } from "./style";

const columns = [
  {
    title: "Sr No.",
    dataIndex: "index",
    width: 150,
    render: (text) => <i>{text}</i>,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 150,
  },
  {
    title: "Time",
    dataIndex: "time",
    width: 150,
  },
  {
    title: "Event name",
    dataIndex: "eventName",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => (
      <StatusText success={text === "Successful"}>{text}</StatusText>
    ),
    width: 200,
  },
];

const data = [...Array(30)].map((_, index) => ({
  index: `#00${index}`,
  date: "11-08-2020",
  time: "04.25 PM",
  eventName: "Event created from excel file",
  status: index % 2 ? "Successful" : "Unsuccessful",
}));

export const AuditTrailTable = () => (
  <AuditTrailTableContainer>
    <TableComponent
      data={data}
      columns={columns}
      searchPlaceholder="Search entry"
    />
  </AuditTrailTableContainer>
);
