import styled from "styled-components";

export const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LoaderInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
