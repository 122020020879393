import {
  CardDescription,
  CardFooter,
  CardTitle,
  FlexContainer,
  NotificationCardContainer,
  ScheduleText,
} from "./style";

import React, { Fragment, useState } from "react";
import {
  DeleteFilled,
  PlusSquareOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";

export const NotificationCard = ({
  title = "Title",
  description = "",
  scheduleTime = "",
  completed = false,
  time = "",
  hideCopyIcon,
  hideSchedule,
  cardId,
  editAction,
  deleteAction,
  cardFor,
}) => {
  const [showDeletePopover, setShowDeletePopover] = useState(false);

  const showPopconfirm = () => {
    setShowDeletePopover(true);
  };

  const handleOk = (cardId) => {
    deleteAction(cardId);
    setShowDeletePopover(false);
  };

  const handleCancel = () => {
    setShowDeletePopover(false);
  };
  return (
    <NotificationCardContainer completed={completed}>
      <FlexContainer>
        <CardTitle>{title}</CardTitle>
        {time ? (
          <CardTitle width={"40%"} rightPadding={"0"}>
            {time}
          </CardTitle>
        ) : null}
      </FlexContainer>
      <FlexContainer direction="column" flex={1}>
        <CardDescription lineCount={hideSchedule ? 4 : 2}>
          {description}
        </CardDescription>
        {hideSchedule ? null : (
          <div>
            <ScheduleText>Scheduled:</ScheduleText>
            <ScheduleText>{scheduleTime}</ScheduleText>
          </div>
        )}
        <CardFooter>
          <div onClick={() => editAction(cardId)}>
            {!completed ? (
              <Fragment>
                <span>Edit</span> <RightOutlined />
              </Fragment>
            ) : null}
          </div>
          <span>
            {hideCopyIcon ? null : <PlusSquareOutlined />}
            {!completed ? (
              <>
                <Popconfirm
                  title={
                    cardFor == "poll"
                      ? "Delete this poll?"
                      : "Delete this notification?"
                  }
                  visible={showDeletePopover}
                  onConfirm={() => handleOk(cardId)}
                  onCancel={handleCancel}
                >
                  <DeleteFilled
                    onClick={showPopconfirm}
                    style={{ marginLeft: "8px" }}
                  />
                </Popconfirm>
              </>
            ) : null}
          </span>
        </CardFooter>
      </FlexContainer>
    </NotificationCardContainer>
  );
};
