import styled, { css } from "styled-components";

export const SessionMenuOption = styled.div`
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme, active }) =>
    theme.color[active ? "accent" : "surface"]};
  color: ${({ theme }) => theme.color.textOnSurface};
  cursor: pointer;
  height: 90px;
  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.color.textOnSurface};

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
      font-size: 10px;
      line-height: 14px;
      display: block;
    }
  }
`;
