import { Space } from "antd";
import React from "react";
import { AddButton } from "../../../../../common-components/add-button/AddButton";
import { SectionTitle } from "../../../../../common-components/section-title/SectionTitle";
import { NotificationCard } from "../notification-card/NotificationCard";
import {
  NotificationSectionContainer,
  NotificationSectionHeader,
} from "./style";
import moment from "moment";
import {
  dateTimeFormat,
  scheduleTimeFormat,
} from "../../../../../constant/constant";

export const NotificationSection = ({
  title = "Title",
  cardList = [],
  completed = false,
  marginBottom,
  buttonText = "",
  buttonAction,
  setMode,
  onEdit,
  onDelete,
  disabled,
  sectionFor,
}) => {
  const create = () => {
    buttonAction();
    setMode("create");
  };
  const edit = (id) => {
    onEdit(id);
    setMode("edit");
    buttonAction();
  };
  return (
    <NotificationSectionContainer marginBottom={marginBottom}>
      <NotificationSectionHeader>
        <SectionTitle title={title} />
        {buttonAction ? (
          <AddButton
            onClick={create}
            name={buttonText || "+ New Notification"}
            disabled={disabled}
          />
        ) : null}
      </NotificationSectionHeader>
      <Space size="middle" wrap>
        {cardList.length > 0 ? (
          cardList.map((cardData, i) => {
            // let end_time = moment(cardData.end_time);
            // let start_time = moment(cardData.start_time);
            // let duration = moment.duration(end_time.diff(start_time));
            // var minutes = duration.asMinutes();
            if (sectionFor == "poll") {
              var cardId = cardData.poll_id;
              var cardTitle = "Poll " + parseInt(i + 1);
              var scheduleTime = "";
              var description = cardData.question;
            } else {
              var cardId = cardData.id;
              var cardTitle =
                cardData.name.length > 12
                  ? cardData.name.substring(0, 12) + "..."
                  : cardData.name;
              completed = cardData.status == "completed" ? true : false;
              var scheduleTime = moment.utc(cardData.schedule_time).local().format(scheduleTimeFormat);
              //"October 11, 2020 08:05PM (UTC+05:30)"
              var description = cardData.title;
            }
            return (
              <NotificationCard
                title={cardTitle}
                description={description}
                scheduleTime={scheduleTime}
                completed={completed}
                time={sectionFor == "poll" ? "5 min" : cardData.type}
                hideCopyIcon={true}
                hideSchedule={sectionFor == "poll" ? true : false}
                disabled={false}
                cardId={cardId}
                editAction={edit}
                deleteAction={onDelete}
                cardFor={sectionFor}
              />
            );
          })
        ) : (
          <p>
            No {sectionFor == "poll" ? "polls" : "notification"} for this
            session
          </p>
        )}
      </Space>
    </NotificationSectionContainer>
  );
};
