import styled from 'styled-components';

import { Button } from "antd";
export const NewEventButton = styled(Button)`
 background: ${({ theme }) => theme.color.accent};
 color: ${({ theme }) => theme.color.textOnSurface} !important;
 :hover, :active{
    color: ${({ theme }) => theme.color.black_2} !important;
 }
 width: 154px;
 display: inline-flex;
 justify-content: space-between;
 align-items: center;   
 padding: 0 19px;
 margin-left: 14px;
`
