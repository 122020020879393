import styled from "styled-components";

export const AuditTrailTableContainer = styled.section`
  margin-top: 16px;
  padding-left: 28px;
`;

export const StatusText = styled.span`
  font-weight: 600;
  color: ${({ theme, success }) =>
    theme.color[success ? "light_green" : "red"]};
`;
