import { SectionTitle } from "../section-title/SectionTitle";
import {
  ActionHeaderContainer,
  CancelButton,
  SaveButton,
} from "./style";

export const ActionButtonHeader = ({
  title = "",
  leftButtonAction = () => null,
  rightButtonAction = () => null,
  leftButtonText = "Cancel",
  rightButtonText = "Save",
  leftButtonStyle,
  rightButtonStyle,
  leftButtonDisabled,
  rightButtonDisabled
}) => (
  <ActionHeaderContainer titlePresent={!!title}>
    <SectionTitle title={title} />

    <div>
      <CancelButton
        onClick={leftButtonAction}
        leftButtonStyle={leftButtonStyle}
        disabled={leftButtonDisabled}
      >
        {leftButtonText}
      </CancelButton>
      <SaveButton
        onClick={rightButtonAction}
        rightButtonStyle={rightButtonStyle}
        disabled={rightButtonDisabled}
      >
        {rightButtonText}
      </SaveButton>
    </div>
  </ActionHeaderContainer>
);
