import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

export const SideMenuContainer = styled(Sider)`
  background: ${({ theme }) => theme.color.grey29};
  min-width: ${({width}) => width || '211px'} !important;
  height: calc(100vh - 142px);
  position: sticky;
  top: 0;
  bottom: 0;
`;

export const SideMenuContent = styled.div`
    height: 94%;
   overflow-y: scroll;
   overflow-x: hidden
`;

export const Footer = styled.div`
  padding: 0 10px;
  width: 100%;
`