import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Slider } from "antd";
import React, { useCallback, useState } from "react";
import ImageCropper from "react-easy-crop";
import { CropContainer, ImageCropSliderRow, SearchWrapper } from "./style";

export const ReactImageCropper = ({image}) => {
  const [, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback(() => {
    // console.log(croppedArea, croppedAreaPixels);
  }, []);

  const stepCount = 0.2;
  const maxCropLimit = 10;

  const sliderIconClick = ({ isMinus }) => {
    const allowCrop = isMinus ? zoom > 1 : zoom < maxCropLimit
    if (allowCrop) {
        console.log("here2");
      if (isMinus) {
        setZoom((prevValue) => prevValue - stepCount);
      } else {
        setZoom((prevValue) => prevValue + stepCount);
      }
    }
  };

  return (
    <CropContainer>
      <ImageCropper
        image={image || "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"}
        crop={{ x: 0, y: 0 }}
        zoom={zoom}

        aspect={1}
        onCropChange={()=>null}
        onCropComplete={onCropComplete}
        cropShape="round"
        cropSize={{ width: 132, height: 132 }}
        showGrid={false}
        style={{
          containerStyle: {
            width: "180px",
            height: "180px",
          },
        }}
        onZoomChange={setZoom}
      />
      <ImageCropSliderRow>
        <SearchWrapper>
          <MinusCircleOutlined
            onClick={() => sliderIconClick({ isMinus: true })}
          />
        </SearchWrapper>

        <Slider
          max={maxCropLimit}
          min={1}
          className="image-slider"
          tooltipVisible={false}
          onChange={setZoom}
          step={stepCount}
          value={zoom}
          onCropChange={setCrop}
        />
        <SearchWrapper>
          <PlusCircleOutlined onClick={sliderIconClick} />
        </SearchWrapper>
      </ImageCropSliderRow>
    </CropContainer>
  );
};
