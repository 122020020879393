import { Form, Input, Button, Checkbox, Spin } from "antd";

import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loader from "../common-components/loader";
import { userManagementHttp, vMeetHttp } from "../http/axiosInstances";
import theme from "../styles/theme";
import { ActionButton } from "../vmeet/events/new-event/style";
import { LoaderWrapper, LoginFormInner, LoginWrapper } from "./styles";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 9,
    span: 16,
  },
};

const LoginForm = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const history = useHistory();

  const [redirectLogin, setRedirectLogin] = useState(false);

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");
    let org = url.searchParams.get("org");
    let userId = url.searchParams.get("u");
    let eventId = url.searchParams.get("event");
    let userName = url.searchParams.get("name");
    let referrer = url.searchParams.get("referrer");

    if (code) {
      console.log(code);
      setRedirectLogin(true);
      vMeetHttp.defaults.headers.common["Authorization"] = "Bearer " + code;
      vMeetHttp.defaults.headers.common["userId"] = userId;
      vMeetHttp.defaults.headers.common["organizationId"] = org;

      userManagementHttp.defaults.headers.common["Authorization"] =
        "Bearer " + code;
      userManagementHttp.defaults.headers.common["userId"] = userId;
      userManagementHttp.defaults.headers.common["organizationId"] = org;

      localStorage.setItem("vmAdmin_code", code);
      localStorage.setItem("vmAdmin_userId", userId);
      localStorage.setItem("vmAdmin_organizationId", org);
      localStorage.setItem("vmAdmin_eventId", eventId);
      localStorage.setItem("vmAdmin_userName", userName);
      localStorage.setItem("vmAdmin_referrer", referrer);

      setTimeout(() => {
        history.push(`/vmeet/events/${eventId}/basicinfo`);
      }, 2000);
    } else if (localStorage.getItem("vmAdmin_code")) {
      let eventId = localStorage.getItem("vmAdmin_eventId");
      history.push(`/vmeet/events/${eventId}/basicinfo`);
    }
  }, []);

  if (redirectLogin) {
    return (
      <LoginWrapper>
        <LoaderWrapper>
          <Spin tip="Logging you in..."></Spin>
        </LoaderWrapper>
      </LoginWrapper>
    );
  }

  return (
    <LoginWrapper>
      <LoginFormInner
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item /* {...tailLayout} */>
          <ActionButton htmlType="submit">Submit</ActionButton>
        </Form.Item>
      </LoginFormInner>
    </LoginWrapper>
  );
};

export default LoginForm;
