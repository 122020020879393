import { Dropdown, Menu } from "antd";
import React from "react";
import { MoreMenuStyle, MoreOutlined } from "./style";

export const MoreOutlineMenu = ({
  menuList = [],
  onEdit = () => {},
  callbackObject = {},
}) => {
  const menu = (
    <MoreMenuStyle>
      <Menu.Item key="0" onClick={() => onEdit(callbackObject)}>
        Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">Delete</Menu.Item>
    </MoreMenuStyle>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      onSelect={(e) => console.log(e)}
    >
      <MoreOutlined />
    </Dropdown>
  );
};
