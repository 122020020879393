import React, { useEffect, useState } from "react";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { BannerSideMenu } from "./component/banner-side-menu/BannerSideMenu";
import { LogoPageBanner } from "./component/banner-side-menu/logo-page-banner/LogoPageBanner";

export const BannerAndLogo = () => {
  const [currentMenu, setCurrentMenu] = useState("1");
  const onOptionClick = (e) => {
    setCurrentMenu(e);
  };
  const [renderComponent, setRenderComponent] = useState(true);

  useEffect(() => {
    onOptionClick(1);
  }, []);

  const reRender = () => {
    setRenderComponent(false);
    setTimeout(() => {
      setRenderComponent(true);
    }, 1);
  };
  const menuList = [
    {
      label: "Logo (Color)",
      id: 1,
      propertyPath: "logo_colour",
      location: "logo_colour",
      rules: {
        maxCount: 1,
        size: { width: 210, height: 60 },
      },
      actions: ["preview", "replace"],
    },
    {
      label: "Logo (B&W)",
      id: 2,
      propertyPath: "logo_white",
      location: "logo_white",
      rules: {
        maxCount: 1,
        size: { width: 210, height: 60 },
      },
      actions: ["preview", "replace"],
    },
    {
      label: "Login Page Banners",
      id: 3,
      propertyPath: "banners.login",
      location: "login",
      rules: {
        maxCount: 1,
        size: { width: 632, height: 1050 },
      },
      actions: ["preview", "delete"],
    },
    {
      label: "Welcome Banners",
      id: 4,
      propertyPath: "banners.website",
      location: "website",
      rules: {
        maxCount: 5,
        size: { width: 1024, height: 180, loose: "height" },
      },
      actions: ["preview", "delete"],
    },
    {
      label: "Welcome Banners (Mobile)",
      id: 5,
      propertyPath: "banners.website_mobile",
      location: "website_mobile",
      rules: {
        maxCount: 5,
        size: { width: 320, height: 90, loose: "height" },
      },
      actions: ["preview", "delete"],
    },
    {
      label: "Favicon",
      id: 6,
      propertyPath: "favicon",
      location: "favicon",
      rules: {
        maxCount: 1,
        size: { width: 32, height: 32, loose: "none" },
      },
      actions: ["preview", "replace"],
    },
  ];

  return (
    <EventLayout
      leftMenuElement={
        <BannerSideMenu
          selectedValue={currentMenu}
          onOptionClick={onOptionClick}
          menuList={menuList}
        />
      }
      space="24px"
    >
      {renderComponent && (
        <LogoPageBanner
          currentMenu={currentMenu}
          menuList={menuList}
          reRender={reRender}
        />
      )}
    </EventLayout>
  );
};
