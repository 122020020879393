import { Space } from "antd";
import React from "react";
import theme from "../../../../styles/theme";
import { TagBox } from "./style";

export const TagBoxs = ({ tagList }) => (
  <Space wrap size="small">
    {tagList.map((text) => (
      <TagBox closable color={theme.color.white_1} onClose={() => null}>
        {text}
      </TagBox>
    ))}
  </Space>
);
