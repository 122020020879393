import React from "react";
import { Label } from "./style";

export const FormField = ({
  label = "label",
  required = false,
  children,
  labelColor,
  labelStyle,
}) => (
  <div>
    <Label labelColor={labelColor} style={labelStyle || {}}>
      {label}
      {required ? <sup>*</sup> : null}
    </Label>
    {children}
  </div>
);
