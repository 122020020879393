import styled from "styled-components";

export const Box = styled.div`
  width: 585px;
  height: 360px;
  border: 1px dashed ${({ theme }) => theme.color.grey77};
  background-color: ${({ theme, failed }) =>
    theme.color[failed ? "errorBgColor" : "snow"]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.dimGrey};
    display: inline-block;
    margin: 8px 0 4px 0;
  }
  .subText {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.color.dimGrey};
    cursor: pointer;
    &.underline {
      text-decoration-line: underline;
    }
  }
`;
