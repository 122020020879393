import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AddButton } from "../../../common-components/add-button/AddButton";
import { DayListTable } from "../../../common-components/day-list-table/DayListTable";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import Loader from "../../../common-components/loader";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";
import { dateFormat } from "../../../constant/constant";
import { vMeetHttp } from "../../../http/axiosInstances";
import { NewNotificationDrawer } from "./components/new-notification-drawer/NewNotificationDrawer";
import { NotificationSection } from "./components/notification-section/NotificationSection";
import {
  NotificationContainer,
  PreviousButton,
  NextButton,
  MenuButton,
} from "./style";

export const Notification = () => {
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [notificationLoad, setNotificationLoad] = useState(false);
  const [notificationList, setNotificationList] = useState({});
  const [selectedDay, setSelectedDay] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [currentNotification, setCurrentNotification] = useState({});
  const [notificationMode, setNotificationMode] = useState("create");

  const { id } = useParams();

  useEffect(() => {
    loadNotificationList();
  }, []);
  const loadNotificationList = () => {
    setNotificationLoad(false);
    setMenuData([]);
    const notificationListURL = `event/${id}/notification/list`;
    vMeetHttp
      .get(notificationListURL)
      .then((response) => {
        let data = response.data.data;
        setNotificationList(data);
        Object.keys(data).map((ele, i) => {
          let day = moment.utc(ele).local().format("ddd");
          let date = moment.utc(ele).local().format("Do MMM");
          let notificationCount = data[ele].length + " notifications";
          setMenuData((menuData) => [
            ...menuData,
            {
              day: day,
              date: date,
              text: notificationCount,
              dayCount: i + 1,
              key: ele,
            },
          ]);
          if (i === 0) {
            var scheduleDate = moment.utc(ele).local().format(dateFormat);
            setSelectedDayIndex(scheduleDate);
            setSelectedDay(data[ele]);
          }
        });

        setNotificationLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setNotificationLoad(true);
      });
  };
  const onChangeDay = (key, e) => {
    var scheduleDate = moment.utc(key).local().format(dateFormat);
    setSelectedDayIndex(scheduleDate);
    setSelectedDay(notificationList[key]);
  };
  const notificationDrawerToggle = () => {
    setShowNotificationDrawer(!showNotificationDrawer);
  };
  const onEditNotification = (notificationId) => {
    let newArray = Object.values(notificationList);
    let flattened = newArray.reduce((acc, curVal) => acc.concat(curVal), []);
    let obj = Object.values(flattened).find((o) => o.id == notificationId);
    setCurrentNotification(obj);
  };
  const deleteNotification = (notificationId) => {
    notifyProgress("Changes are being saved...");
    const notificationDeleteURL = `event/${id}/notification/${notificationId}`;
    vMeetHttp
      .delete(notificationDeleteURL)
      .then(() => {
        notifySuccess("Removed successfully");
        loadNotificationList();
      })
      .catch((error) => {
        console.log(error);
        notifyError(error.response.data.message);
      });
  };
  const menuFooter = (
    <MenuButton>
      <PreviousButton>
        <LeftOutlined className="vertical-middle" />
        Previous
      </PreviousButton>
      <NextButton>
        Next
        <RightOutlined className="vertical-middle" />
      </NextButton>
    </MenuButton>
  );

  return (
    <EventLayout
      leftMenuElement={
        menuData.length > 0 && (
          <DayListTable
            dayList={menuData}
            activeIndex={selectedDayIndex}
            onRowClick={onChangeDay}
            footerContent=""
          />
        )
      }
      space="20px"
    >
      {selectedDay ? (
        <NotificationContainer>
          <NotificationSection
            cardList={selectedDay}
            title={"Scheduled Notifications on " + selectedDayIndex}
            buttonAction={notificationDrawerToggle}
            onEdit={onEditNotification}
            onDelete={deleteNotification}
            setMode={setNotificationMode}
            disabled={false}
            sectionFor="notification"
          />
        </NotificationContainer>
      ) : !notificationLoad ? (
        <Loader></Loader>
      ) : (
        <div style={{ width: "200px", margin: "50px auto" }}>
          <p>No notification data..</p>
          <AddButton
            onClick={() => setShowNotificationDrawer(true)}
            name="+ New Notification"
            disabled={false}
          />
        </div>
      )}

      {showNotificationDrawer && (
        <NewNotificationDrawer
          visible={showNotificationDrawer}
          onClose={notificationDrawerToggle}
          session_id={null}
          notificationMode={notificationMode}
          currentNotification={currentNotification}
          loadNotification={loadNotificationList}
        ></NewNotificationDrawer>
      )}
    </EventLayout>
  );
};
