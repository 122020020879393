import { Button } from "antd";
import styled from "styled-components";
import theme from "../../../../../styles/theme";

export const ProfileUploadContent = styled.div`
  height: 300px;
  display: flex;
  position:relative;
  align-items: center;
  box-sizing: border-box;
  justify-content: ${({ imagePresent }) => imagePresent ?'flex-start':'center'};
  flex-direction: column;
  color: ${({ theme }) => theme.color.dimGrey};
  .ant-upload.ant-upload-drag{
    border: none;
    background-color: ${({ theme }) => theme.color.textOnSurface};
  }
  .ant-upload.ant-upload-drag .ant-upload-btn{
    padding: 0;
  }
  input {
    background-color: ${({ theme }) => theme.color.surfaceLighter_2};
  }
`;

export const ProfileUploadTitle = styled.b`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.color.dimGrey};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
`;

export const SmallText = styled.span`
  font-size: 10px;
  line-height: 14px;
  margin: 4px 0;
`;

export const Text = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin: 9px 0;
`;


export const UploadModalButton = styled(Button)`
    width: 92px;
    height:28px;
    font-weight:700;
    display: flex;
    align-items:center;
    justify-content: center;
    align-self: flex-end;
    position:absolute;
    bottom:0;
    background-color: ${({ theme }) => theme.color.accent};
    color:  ${({ theme }) => theme.color.textOnSurface};
    border: 1px solid ${({ theme }) => theme.color.transparent};
`;

export const maskStyle = {
  background: theme.color.dark_grey,
  backdropFilter: "blur(6px)",
};

export const profileUploadBodyStyle = { padding: "28px 35px 35px" }