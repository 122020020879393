import { Menu } from "antd";
import { configConsumerProps } from "antd/lib/config-provider";
import React, { useEffect } from "react";
import { MenuOptionWrapper } from "./style";

export const MenuOption = ({
  menuList = [],
  defaultSelectedValue = "",
  onSelectOption = () => null,
  RightElement = "",
  width,
}) => {
  const onSelect = (menu) => {
    const value = menu.id;
    onSelectOption(value);
  };

  return (
    <MenuOptionWrapper width={width}>
      <Menu theme="dark" defaultSelectedKeys={defaultSelectedValue}>
        {menuList.map((data) => (
          <Menu.Item key={data.id} onClick={() => onSelect(data)}>
            <span>{data.label}</span>
            <span className="right">
              {RightElement ? <RightElement data={data} /> : null}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    </MenuOptionWrapper>
  );
};
