import styled from "styled-components";

export const CropContainer = styled.div`
display: flex;
flex-direction: column;
align-items:center;
margin-top:24px;
  > div:first-child {
    position: relative;
    width: 180px;
    height: 180px;
  }
`;

export const ImageCropSliderRow = styled.div`
  display: flex;
  align-items: center;
  margin-top:10px;
  .image-slider {
    width: 175px;
    margin-top:0;
    margin-bottom:0;
    margin-left:11px;

    .ant-slider-track {
      background: ${({ theme }) => theme.color.grey36};
      height: 3px;

    }
    .ant-slider-rail {
      background: ${({ theme }) => theme.color.grey86};
      border-radius: 1px;
    height: 3px;
    }
    
    .ant-slider-handle {
      background: ${({ theme }) => theme.color.black_2};
      border: 2px solid ${({ theme }) => theme.color.black_2};
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-top:-3px;
      :focus,
      :hover .ant-slider-handle:not(.ant-tooltip-open), :active {
        box-shadow: ${({ theme }) => theme.color.shadowElevated};
      }
    }
    
  }
`;

export const SearchWrapper = styled.span`
  position: relative;
  display: inline-block;
  
  color: ${({ theme }) => theme.color.black_2};
  .anticon-search {
    font-size: 20px;
  }
  .anticon-minus-circle,.anticon-plus-circle {
      cursor: pointer;
    :after {
      content: " ";
      width: 7px;
      position: absolute;
      height: 8px;
      top: 54%;
      left: 13px;
      bottom: 0;
      cursor: pointer;
      transform: rotate(134deg);
      display: inline-block;
      border-right: 2px solid ${({ theme }) => theme.color.black_2};
    }
  }
`;
