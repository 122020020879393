import styled from "styled-components";

export const TableSubHeader = styled.div`
  padding-top: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const UserCountText = styled.i`
  color: ${({ theme }) => theme.color.textDisabled};
  margin-left: 7px;
`;
