import { Menu } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { VmeetHeader } from "../../common-components/vmeet-header";
import {
  vmeetEventChildRoutes,
  vmeetEventRoute,
} from "../../constant/routeConstant";
import { Attendee } from "./attendees";
import { BannerAndLogo } from "./banner-and-logo";
import { BasicInformation } from "./basic-information";
import { Legal } from "./legal";
import { LoungeTable } from "./lounge-table";
import { Notification } from "./notification";
import { Schedule } from "./schedule";
import { Sessions } from "./sessions";
import { Speaker } from "./speaker";
import { EventDetailSideMenu } from "./style";
import { UserConsent } from "./user-consent";
const menus = {
  1: "/basicinfo",
  2: "/session",
  3: "/speakers",
  4: "/attendees",
  5: "/schedule",
  /* 5: "/user-consent",
  6: "/legal",*/
  6: "/notifications", 
  7: "/lounge",
  8: "/banner-logo",
};

export const EventDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const onMenuClick = (e) => {
    const path = menus[e["key"]];
    history.push(`${vmeetEventRoute}/${id}${path}`);
  };

  const { pathname } = history.location;

  const eventInfo = useSelector((state) => state.currentEvent);
  return (
    <Layout>
      <EventDetailSideMenu>
        <Menu mode="inline" expandIcon={<Fragment />} onClick={onMenuClick}>
          {[
            "Basic Information",
            "Sessions",
            "Speakers",
            "Attendee",
            "Schedule",
            /* "User consent",
            "Legal",*/
            "Notification", 
            "Lounge Tables",
            "Banners & Logos",
          ].map((menuLabel, index) => (
            <Menu.Item
              key={`${index + 1}`}
              className={pathname.includes(menus[index + 1]) ? "active" : ""}
            >
              {menuLabel}
            </Menu.Item>
          ))}
        </Menu>
      </EventDetailSideMenu>
      <Content>
        <VmeetHeader title={eventInfo.name || ""} />

        <div style={{ flex: 1 }}>
          <Switch>
            {/* <Route path={"/events"} exact component={Upcomingevent} /> */}
            <Route
              path={vmeetEventChildRoutes.basicInfo}
              exact
              component={BasicInformation}
            />
            <Route
              path={vmeetEventChildRoutes.speakers}
              exact
              component={Speaker}
            />
            {/* <Route path={"/new-event"} exact component={NewEvent} /> */}
            <Route
              path={vmeetEventChildRoutes.attendees}
              exact
              component={Attendee}
            />
            <Route
              path={vmeetEventChildRoutes.schedule}
              exact
              component={Schedule}
            />
            <Route
              path={vmeetEventChildRoutes.userConsent}
              exact
              component={UserConsent}
            />
            <Route path={vmeetEventChildRoutes.legal} exact component={Legal} />
            <Route
              path={vmeetEventChildRoutes.notifications}
              exact
              component={Notification}
            />
            <Route
              path={vmeetEventChildRoutes.session}
              exact
              component={Sessions}
            />
            <Route
              path={vmeetEventChildRoutes.loungleTables}
              exact
              component={LoungeTable}
            />
            <Route
              path={vmeetEventChildRoutes.bannerLogo}
              exact
              component={BannerAndLogo}
            />
          </Switch>
        </div>
      </Content>
    </Layout>
  );
};
