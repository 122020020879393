import {
  Input,
  Space,
  Tag,
  InputNumber,
  Select,
  TimePicker,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ActionButtonHeader } from "../../../common-components/action-button-header/ActionButtonHeader";
import { AddButton } from "../../../common-components/add-button/AddButton";
import { DateAndTime } from "../../../common-components/date-and-time/DateAndTime";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { FormField } from "../../../common-components/form-field/FormField";
import { MenuOption } from "../../../common-components/menu-option/MenuOption";
import { SectionTitle } from "../../../common-components/section-title/SectionTitle";
import { SessionTable } from "../../../common-components/session-table/SessionTable";
import { SwitchBox } from "../../../common-components/switch-box/SwitchBox";
import theme from "../../../styles/theme";
import { AddTableDrawer } from "../lounge-table/components/add-table-drawer/AddTableDrawer";
import { NotificationSection } from "../notification/components/notification-section/NotificationSection";
import { AddPollDrawer } from "./components/add-poll-drawer/AddPollDrawer";
import { SessionSideMenu } from "./components/session-side-menu/SessionSideMenu";
import { SpeakerDrawer } from "./components/speaker-drawer/SpeakerDrawer";
import {
  HintText,
  SessionContainer,
  sessionDescriptionStyle,
  SessionSection,
  SpeakerDeleteIcon,
} from "./style";

import { PlusOutlined } from "@ant-design/icons";
import Loader from "../../../common-components/loader";
import { dateTimeFormat, displayTimeFormat } from "../../../constant/constant";
import moment from "moment";
import { vMeetHttp } from "../../../http/axiosInstances";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";
import { Option } from "antd/lib/mentions";
import { TextLabel } from "../../../common-components/date-and-time/style";
import { NewNotificationDrawer } from "../notification/components/new-notification-drawer/NewNotificationDrawer";
import { WriterDrawer } from "./components/writer-drawer/WriterDrawer";
import { UserCard } from "../../../common-components/user-card/UserCard";
const timeStyle = { width: "105px", marginLeft: "10px" };

export const Sessions = () => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [showAddTable, setShowAddTable] = useState(false);
  const [showSpeakerDrawer, setShowSpeakerDrawer] = useState(false);
  const [showWriterDrawer, setShowWriterDrawer] = useState(false);
  const [showTranslatorDrawer, setShowTranslatorDrawer] = useState(false);
  const [showPollDrawer, setShowPollDrawer] = useState(false);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [selectedId, setSelectedId] = useState(1);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [allWriters, setAllWriters] = useState([]);
  const [selectedSession, setSelectedSession] = useState({
    session_id: "",
    name: "",
    description: "",
    tags: "",
    start_time: moment.utc().local().format(dateTimeFormat),
    end_time: moment.utc().local().format(dateTimeFormat),
    speaker: {},
    medical_writer: {},
    attendee_capacity: "",
    video_service_provider_id: 2,
    type_id: 1,
    chat: {
      configId: 1,
      configValue: 0,
    },
    "q&a": {
      configId: 2,
      configValue: 0,
    },
  });
  const [config, setConfig] = useState({
    moderateChat: -1,
    moderateQuestion: -1,
  });
  const [pollList, setPollList] = useState([]);
  const [pollLoad, setPollLoad] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationLoad, setNotificationLoad] = useState(false);
  const [loungeLoad, setLoungeLoad] = useState(false);
  const [loungeList, setLoungeList] = useState();
  const [speakerList, setSpeakerList] = useState([]);
  const [medicalWriterList, setMedicalWriterList] = useState([]);
  const [translatorList, setTranslatorList] = useState([]);
  const [translatorCardList, setTranslatorCardList] = useState([]);
  const [tagInputEdit, setTagInputEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInputState, setTagInputState] = useState();
  const [editTagInputState, setEditTagInputState] = useState();
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [sessionMode, setSessionMode] = useState();
  const [pollMode, setPollMode] = useState("create");
  const [notificationMode, setNotificationMode] = useState("create");
  const [currentPoll, setCurrentPoll] = useState({});
  const [currentNotification, setCurrentNotification] = useState({});
  const [renderForm, setRenderForm] = useState(false);

  const pollDrawerToggle = () => {
    setShowPollDrawer(!showPollDrawer);
  };

  const notificationDrawerToggle = () => {
    setShowNotificationDrawer(!showNotificationDrawer);
  };

  const { id } = useParams();

  const addTableToggle = () => {
    setShowAddTable(!showAddTable);
  };

  const addSpeakerToggle = () => {
    setShowSpeakerDrawer(!showSpeakerDrawer);
  };
  const addWriterToggle = () => {
    setShowWriterDrawer(!showWriterDrawer);
  };
  const addTranslatorToggle = () => {
    setShowTranslatorDrawer(!showTranslatorDrawer);
  };
  useEffect(() => {
    loadSessionList();
    loadSpeaker();
    loadWriters();
  }, []);

  const loadSpeaker = async () => {
    vMeetHttp
      .get(`/event/${id}/speaker`)
      .then((response) => {
        let res = response.data.data;
        let data = Object.values(res);
        setAllSpeakers(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadWriters = async () => {
    vMeetHttp
      .get(`/event/${id}/medical-writer`)
      .then((response) => {
        let res = response.data.data;
        let data = Object.values(res);
        setAllWriters(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSpeakerChange(value) {
    console.log(`selected ${value}`);
    let speakers = value.includes(",") ? value.split(",") : value;
    setSelectedSession({ ...selectedSession, ["speakers"]: speakers });
  }

  function handleWriterChange(value) {
    console.log(`selected ${value}`);
    let writers = value.includes(",") ? value.split(",") : value;
    setSelectedSession({ ...selectedSession, ["medical_writer"]: writers });
  }

  const loadSessionList = async () => {
    const sessionListURL = `event/${id}/session/list`;
    vMeetHttp
      .post(sessionListURL, {
        filters: {
          type: 1,
        },
      })
      .then((response) => {
        let sessions = response.data.data;
        setSessionList(sessions);
        /* ---------------- SET SELECETED SESSION ------------------------*/
        var result = sessions[0];
        setSelectedSession(result);
        result.tags != null && setTags(result.tags.split("#"));
        loadConfig(result.session_id);
        loadPollList(result.session_id);
        loadNotificationList(result.session_id);
        loadLoungeList(result.session_id);
        if (result.speaker) {
          const speakers = Object.values(result.speaker);
          speakers.map((ele, i) => {
            setSpeakerList((speakerList) => [
              ...speakerList,
              { label: ele.first_name + " " + ele.last_name, value: ele.id },
            ]);
          });
        }
        if (result.medical_writer) {
          const writers = Object.values(result.medical_writer);
          writers.map((ele, i) => {
            setMedicalWriterList((medicalWriterList) => [
              ...medicalWriterList,
              { label: ele.first_name + " " + ele.last_name, value: ele.id },
            ]);
          });
        }
        if (result.translator) {
          setTranslatorCardList(Object.values(result.translator));
          const translators = Object.values(result.translator);
          translators.map((ele, i) => {
            setTranslatorList((translatorList) => [
              ...translatorList,
              { label: ele.first_name + " " + ele.last_name, value: ele.id },
            ]);
          });
        }
        //loadSessionDetail(result.session_id);
        // setTimeout(() => {
        //   selectSession();
        // }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   selectSession();
  // }, [selectedId]);

  const selectSession = () => {
    var result = sessionList.find((obj) => {
      console.log(obj.session_id, selectedId);
      return obj.session_id === selectedId;
    });
    console.log(result);
    setSelectedSession(result);
  };

  const loadConfig = async (session_id) => {
    const sessionconfigURL = `session/${session_id}/config`;
    vMeetHttp
      .get(sessionconfigURL)
      .then((response) => {
        let data = response.data.data;
        setConfig(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    var chatConfig = { configId: 1, configValue: config.moderateChat };
    var qaConfig = { configId: 1, configValue: config.moderateQuestion };
    setSelectedSession({
      ...selectedSession,
      chat: chatConfig,
      "q&a": qaConfig,
    });
  }, [config]);

  const loadPollList = async (session_id) => {
    setPollLoad(false);
    const sessionPollListURL = `session/${session_id}/poll`;
    vMeetHttp
      .get(sessionPollListURL)
      .then((response) => {
        setPollList([]);
        let data = response.data.data;
        for (const [k, arr] of Object.entries(data)) {
          let pollData = arr;
          pollData["poll_id"] = k;
          setPollList((pollList) => [...pollList, pollData]);
        }
        setPollLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setPollLoad(true);
      });
  };

  const loadNotificationList = async (session_id) => {
    setNotificationLoad(false);
    const sessionNotificationListURL = `session/${session_id}/getNotification`;
    vMeetHttp
      .get(sessionNotificationListURL)
      .then((response) => {
        let data = response.data.data;
        setNotificationList(data);
        setNotificationLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setNotificationLoad(true);
      });
  };

  const loadLoungeList = async (session_id) => {
    setLoungeLoad(false);
    setLoungeList([]);
    const sessionLoungeListURL = `session/${session_id}/getSessionLounge`;
    vMeetHttp
      .get(sessionLoungeListURL)
      .then((response) => {
        setLoungeList(Object.values(response.data.data));
        setLoungeLoad(true);
      })
      .catch((error) => {
        console.log(error);
        setLoungeLoad(true);
      });
  };
  const onChangeSession = (e) => {
    setEnableEdit(true);
    setSpeakerList([]);
    setTranslatorList([]);
    setMedicalWriterList([]);
    setConfig({
      moderateChat: -1,
      moderateQuestion: -1,
    });
    setPollList([]);
    setLoungeList();
    setTags([]);
    setSelectedSession({
      session_id: "-1",
      name: "",
      description: "",
      tags: "",
      start_time: moment.utc().local().format(dateTimeFormat),
      end_time: moment.utc().local().format(dateTimeFormat),
      speaker: {},
      medical_writer: {},
      attendee_capacity: "",
      video_service_provider_id: 2,
      type_id: 1,
      chat: {
        configId: 1,
        configValue: 0,
      },
      "q&a": {
        configId: 2,
        configValue: 0,
      },
    });
    if (e != undefined) {
      setEnableEdit(false);
      var result = sessionList.find((obj) => {
        return obj.session_id == e;
      });
      setSelectedSession(result);
      result.tags != null && setTags(result.tags.split("#"));
      loadConfig(result.session_id);
      loadPollList(result.session_id);
      loadNotificationList(result.session_id);
      loadLoungeList(result.session_id);
      if (result.speaker) {
        const speakers = Object.values(result.speaker);
        speakers.map((ele, i) => {
          setSpeakerList((speakerList) => [
            ...speakerList,
            { label: ele.first_name, value: ele.id },
          ]);
        });
      }
      if (result.medical_writer) {
        const writers = Object.values(result.medical_writer);
        writers.map((ele, i) => {
          setMedicalWriterList((medicalWriterList) => [
            ...medicalWriterList,
            { label: ele.first_name + " " + ele.last_name, value: ele.id },
          ]);
        });
      }
      if (result.translator) {
        setTranslatorCardList(Object.values(result.translator));
        const translators = Object.values(result.translator);
        translators.map((ele, i) => {
          setTranslatorList((translatorList) => [
            ...translatorList,
            { label: ele.first_name + " " + ele.last_name, value: ele.id },
          ]);
        });
      }
    }
  };

  useEffect(() => {
    var newTags = tags.join("#");
    setSelectedSession({ ...selectedSession, ["tags"]: newTags });
  }, [tags]);
  const handleTagInputChange = (e) => {
    setTagInputState(e.target.value);
  };
  const handleTagEditInputChange = (e) => {
    setEditTagInputState(e.target.value);
  };
  const handleInputConfirm = () => {
    const inputValue = tagInputState;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags((tags) => [...tags, inputValue]);
    }
    setTagInputState("");
    setTagInputEdit(false);
  };
  const handleEditInputConfirm = () => {
    const inputValue = editTagInputState;
    const newTags = [...tags];
    newTags[editInputIndex] = inputValue;
    setTags(newTags);
    setEditInputIndex(-1);
  };
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const saveSession = () => {
    console.log(selectedSession);
    if (
      selectedSession.session_id == "" ||
      selectedSession.session_id == "-1"
    ) {
      createSession();
    } else {
      updateSession(selectedSession.session_id);
    }
  };
  const createSession = () => {
    const sessionCreateURL = `event/${id}/session`;
    notifyProgress("Changes are being saved...");
    vMeetHttp
      .post(sessionCreateURL, selectedSession)
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const updateSession = (session_id) => {
    notifyProgress("Changes are being saved...");
    const sessionUpdateURL = `session/${session_id}`;
    vMeetHttp
      .put(sessionUpdateURL, selectedSession)
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const changeCapacity = (e) => {
    setSelectedSession({ ...selectedSession, ["attendee_capacity"]: e });
  };

  const setDate = (selectedDate, key) => {
    console.log(selectedSession[key], key);
    let newDate = selectedSession[key]
      ? moment.utc(selectedSession[key]).local()
      : moment();
    console.log(newDate);
    let date = moment(selectedDate).date();
    let month = moment(selectedDate).month();
    let year = moment(selectedDate).year();

    newDate.set({ date, month, year });
    console.log(newDate.utc().format(dateTimeFormat));
    setSelectedSession({
      ...selectedSession,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };

  const setTime = (selectedDate, key) => {
    let newDate = selectedSession[key]
      ? moment.utc(selectedSession[key]).local()
      : moment();

    let hour = moment(selectedDate).hour();
    let minute = moment(selectedDate).minute();
    let second = moment(selectedDate).second();

    newDate.set({ hour, minute, second });
    setSelectedSession({
      ...selectedSession,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "date":
        console.log(e.target.value);
        break;
      case "start":
      case "end":
        console.log(e.target.value);
        break;
        break;
      default:
        setSelectedSession({
          ...selectedSession,
          [e.target.name]: e.target.value,
        });
        break;
    }
    setSelectedSession({ ...selectedSession, [e.target.name]: e.target.value });
  };
  const handleEdit = () => {
    if (!enableEdit) setEnableEdit(true);
    else {
      setEnableEdit(false);
      saveSession();
    }
  };
  const changeChatOption = (e) => {
    var value = e ? 1 : 0;
    var chatConfig = { configId: 1, configValue: value };
    setSelectedSession({ ...selectedSession, ["chat"]: chatConfig });
  };
  const changeQAOption = (e) => {
    var value = e ? 1 : 0;
    var qaConfig = { configId: 2, configValue: value };
    setSelectedSession({ ...selectedSession, ["q&a"]: qaConfig });
  };
  const deleteSession = (session_id) => {
    notifyProgress("Changes are being saved...");
    const sessionDeleteURL = `session/${session_id}`;
    vMeetHttp
      .delete(sessionDeleteURL)
      .then(() => {
        notifySuccess("Removed successfully");
        loadSessionList();
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error in Removing");
        loadSessionList();
      });
  };
  const onEditPoll = (pollId) => {
    console.log(pollId);
    let obj = pollList.find((o) => o.poll_id === pollId);
    setCurrentPoll(obj);
  };
  const onEditNotification = (notificationId) => {
    let obj = notificationList.find((o) => o.id === notificationId);
    setCurrentNotification(obj);
  };
  const deletePoll = (pollId) => {
    notifyProgress("Changes are being saved...");
    const pollDeleteURL = `session/${selectedSession.session_id}/poll/${pollId}`;
    vMeetHttp
      .delete(pollDeleteURL)
      .then(() => {
        notifySuccess("Removed successfully");
        loadPollList();
      })
      .catch((error) => {
        console.log(error);
        notifyError(error.response.data.message);
      });
  };

  const deleteNotification = (notificationId) => {
    notifyProgress("Changes are being saved...");
    const notificationDeleteURL = `event/${id}/notification/${notificationId}`;
    vMeetHttp
      .delete(notificationDeleteURL)
      .then(() => {
        notifySuccess("Removed successfully");
        loadNotificationList();
      })
      .catch((error) => {
        console.log(error);
        notifyError(error.response.data.message);
      });
  };

  const handleFormCancel = () => {
    setEnableEdit(false);
    //To reset the form, unmount and remount the EventInfo component
    setRenderForm(true);
    setTimeout(() => {
      setRenderForm(false);
    }, 1);
  };
  return (
    <EventLayout
      leftMenuElement={
        selectedSession && (
          <SessionSideMenu
            sessionList={sessionList && sessionList}
            onChangeSession={onChangeSession}
            selectedSession={selectedSession}
            deleteSession={deleteSession}
          />
        )
      }
      space="21px"
    >
      <ActionButtonHeader
        leftButtonText="Cancel"
        rightButtonText={enableEdit ? "Save" : "Edit"}
        leftButtonAction={handleFormCancel}
        rightButtonAction={handleEdit}
        leftButtonDisabled={!enableEdit}
      />
      {!renderForm && selectedSession.session_id != "" ? (
        <Space className="flex" direction="vertical" size={20}>
          <SessionContainer className="flex" direction="vertical" size={20}>
            <FormField label="Session Title">
              <Input
                name="name"
                value={selectedSession.name}
                size="small"
                disabled={!enableEdit}
                onChange={onChange}
                maxLength="100"
              />
            </FormField>

            <FormField label="Session Description">
              <Input.TextArea
                value={selectedSession.description}
                onChange={onChange}
                name="description"
                size="small"
                autoSize={{
                  minRows: 8,
                  maxRows: 8,
                }}
                style={sessionDescriptionStyle}
                disabled={!enableEdit}
                maxLength="500"
              />
              <HintText>
                {selectedSession.description.length} / 500 characters left
              </HintText>
            </FormField>

            <FormField label="Attendee Capacity">
              <InputNumber
                min={100}
                max={1000}
                defaultValue={100}
                value={selectedSession.attendee_capacity}
                name="attendee_capacity"
                onChange={changeCapacity}
                disabled={!enableEdit}
              />
              <HintText>Minimum 100 attedees to Maximum 1000 attedees</HintText>
            </FormField>

            <FormField label="Tags">
              {tags.map((tag, i) => {
                if (editInputIndex === i) {
                  return (
                    <Input
                      key={enableEdit}
                      size="small"
                      style={{
                        width: "78px",
                        marginRight: " 8px",
                        verticalAlign: "top",
                      }}
                      value={editTagInputState}
                      onChange={handleTagEditInputChange}
                      onBlur={handleEditInputConfirm}
                      onPressEnter={handleEditInputConfirm}
                    />
                  );
                }
                return (
                  <Tag
                    className="edit-tag"
                    key={tag}
                    closable={enableEdit ? true : false}
                    onClose={() => handleClose(tag)}
                    disabled={enableEdit}
                  >
                    <span
                      onDoubleClick={(e) => {
                        if (enableEdit) {
                          setEditInputIndex(i);
                          setEditTagInputState(tag);
                        }
                      }}
                    >
                      {tag}
                    </span>
                  </Tag>
                );
              })}
              {tagInputEdit && (
                <Input
                  type="text"
                  size="small"
                  style={{
                    width: "78px",
                    marginRight: " 8px",
                    verticalAlign: "top",
                  }}
                  value={tagInputState}
                  onChange={handleTagInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
              {!tagInputEdit && enableEdit && (
                <Tag
                  style={{ background: " #fff", borderStyle: "dashed" }}
                  onClick={() => setTagInputEdit(true)}
                >
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </FormField>
          </SessionContainer>

          <DateAndTime
            selectedSession={selectedSession}
            setDate={setDate}
            setTime={setTime}
            enableEdit={!enableEdit}
          />

          {(selectedSession.session_id == "" ||
            selectedSession.session_id == -1) && (
            <>
              <FormField label="Speakers">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select speakers to add"
                  defaultValue={[]}
                  onChange={handleSpeakerChange}
                >
                  {Object.values(allSpeakers).map((speaker) => (
                    <Option key={speaker.id}>
                      {speaker.first_name + " " + speaker.last_name}
                    </Option>
                  ))}
                </Select>
              </FormField>
              <FormField label="Medical Writer">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Medical writer to add"
                  defaultValue={[]}
                  onChange={handleWriterChange}
                >
                  {Object.values(allWriters).map((writer) => (
                    <Option key={writer.id}>
                      {writer.first_name + " " + writer.last_name}
                    </Option>
                  ))}
                </Select>
              </FormField>
            </>
          )}

          {selectedSession.session_id != "" &&
            selectedSession.session_id != "-1" && (
              <>
                {config && (
                  <>
                    <SessionSection width={"256px"}>
                      <SectionTitle title="Chat" />
                      <Space direction="vertical" size={10} className="flex">
                        <SwitchBox
                          label="Moderator approves comments"
                          checked={config.moderateChat == 0 ? false : true}
                          disabled={!enableEdit}
                          labelColor={theme.color.grey36}
                          unCheckedChildren="Off"
                          onChange={changeChatOption}
                        />
                      </Space>
                    </SessionSection>

                    <SessionSection width={"256px"}>
                      <SectionTitle title="Q&A" />
                      <SwitchBox
                        label="Moderator approves questions"
                        checked={config.moderateQuestion == 0 ? false : true}
                        disabled={!enableEdit}
                        labelColor={theme.color.grey36}
                        unCheckedChildren="Off"
                        onChange={changeQAOption}
                      />
                    </SessionSection>
                  </>
                )}
                <SessionSection>
                  <Space className="flex space-between">
                    <SectionTitle title="Lounge Table" />
                    <AddButton
                      name="+ Add Table"
                      onClick={addTableToggle}
                      disabled={!enableEdit}
                    />
                  </Space>
                  <div style={{ display: "flex" }}>
                    {loungeLoad ? (
                      loungeList && loungeList.length >= 1 ? (
                        loungeList.map((e, i) => (
                          <SessionTable
                            tableName={e.name}
                            tableCapacity={e.attendee_capacity}
                            keyId={i}
                            session_id={e.id}
                            deleteSession={deleteSession}
                          />
                        ))
                      ) : (
                        <p>No lounges in this session</p>
                      )
                    ) : (
                      <Loader></Loader>
                    )}
                  </div>
                </SessionSection>
                {pollLoad ? (
                  <NotificationSection
                    cardList={pollList}
                    title="Poll Questions (Saved)"
                    marginBottom={"0px"}
                    buttonText="+ Create Poll"
                    buttonAction={pollDrawerToggle}
                    setMode={setPollMode}
                    onEdit={onEditPoll}
                    onDelete={deletePoll}
                    disabled={!enableEdit}
                    sectionFor="poll"
                  />
                ) : (
                  <Loader></Loader>
                )}

                {notificationLoad ? (
                  <NotificationSection
                    cardList={notificationList}
                    title="Notification"
                    marginBottom={"0px"}
                    buttonText="+ Add Notification"
                    buttonAction={notificationDrawerToggle}
                    onEdit={onEditNotification}
                    onDelete={deleteNotification}
                    setMode={setNotificationMode}
                    disabled={!enableEdit}
                    sectionFor="notification"
                  />
                ) : (
                  <Loader></Loader>
                )}

                <SessionSection>
                  <Space className="flex space-between">
                    <SectionTitle title="Speakers" />
                    {/* 
                    TODO: uncomment below when the role update fixed in BE
                    <AddButton
                      name="+ Add Speakers"
                      onClick={addSpeakerToggle}
                      disabled={!enableEdit}
                    /> */}
                  </Space>
                  <Space direction="vertical" size={0}>
                    <MenuOption
                      menuList={speakerList}
                      //RightElement={() => <SpeakerDeleteIcon />}
                      width={"150px"}
                    />
                  </Space>
                </SessionSection>

                <SessionSection>
                  <Space className="flex space-between">
                    <SectionTitle title="Medical Writer" />
                    {/* <AddButton
                      name="+ Add Medical Writer"
                      onClick={addWriterToggle}
                      disabled={!enableEdit}
                    /> */}
                  </Space>
                  <Space direction="vertical" size={0}>
                    <MenuOption
                      menuList={medicalWriterList}
                      //RightElement={() => <SpeakerDeleteIcon />}
                      width={"150px"}
                    />
                  </Space>
                </SessionSection>

                <SessionSection>
                  <Space className="flex space-between">
                    <SectionTitle title="Translator" />
                    {/* <AddButton name="+ Add Translator" disabled={!enableEdit} /> */}
                  </Space>
                  <Space>
                    {translatorCardList.map((e, i) => (
                      <UserCard width={"250px"} userData={e} key={i}></UserCard>
                    ))}
                  </Space>
                </SessionSection>
              </>
            )}
        </Space>
      ) : (
        <Loader></Loader>
      )}
      {showAddTable && (
        <AddTableDrawer
          visible={showAddTable}
          session={selectedSession}
          session_id={selectedSession.session_id}
          onClose={addTableToggle}
          loadLounge={loadLoungeList}
        />
      )}

      {speakerList && speakerList.length > 0 && (
        <SpeakerDrawer
          visible={showSpeakerDrawer}
          sessionSpeakers={speakerList}
          session_id={selectedSession.session_id}
          onClose={addSpeakerToggle}
        />
      )}

      {medicalWriterList && (
        <WriterDrawer
          visible={showWriterDrawer}
          sessionWriters={medicalWriterList}
          session_id={selectedSession.session_id}
          onClose={addWriterToggle}
        />
      )}

      {/* {translatorList && (
        <WriterDrawer
          visible={showTranslatorDrawer}
          sessionWriters={translatorList}
          session_id={selectedSession.session_id}
          onClose={addTranslatorToggle}
        />
      )} */}

      {showPollDrawer && (
        <AddPollDrawer
          visible={showPollDrawer}
          session_id={selectedSession.session_id}
          onClose={pollDrawerToggle}
          pollMode={pollMode}
          currentPoll={currentPoll}
          loadPoll={loadPollList}
        />
      )}
      {showNotificationDrawer && (
        <NewNotificationDrawer
          visible={showNotificationDrawer}
          onClose={notificationDrawerToggle}
          session_id={selectedSession.session_id}
          notificationMode={notificationMode}
          currentNotification={currentNotification}
          loadNotification={loadNotificationList}
        ></NewNotificationDrawer>
      )}
    </EventLayout>
  );
};
