import { Button } from "antd";
import styled, { css } from "styled-components";

export const SpeakerAdd = styled(Button)`
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.grey27};
  height: 28px;
  padding: 0 6px;
  ${({ isAdded }) =>
    isAdded &&
    css`
      background: ${({ theme }) => theme.color.grey27};
      color: ${({ theme }) => theme.color.textOnSurface};
      border: 1px solid ${({ theme }) => theme.color.transparent};
    `}
`;
