import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Menu, Space } from "antd";
import React from "react";
import theme from "../../styles/theme";
import { Header } from "../header/Header";
import { InputSearch } from "../input-search/InputSearch";

const dividerStyle = {
  margin: 0,
  display: "inline-block",
  borderColor: theme.color.textOnSurface,
  marginRight: "14px",
};

export const VmeetHeader = ({
  title = "",
}) => {

  return (
    <Header title={title}>
     {/*  <Space size={[13]}>
        <InputSearch onSearch={() => null} width='175px' placeholder='Search settings' />

        <Dropdown
          overlayClassName="dropdown"
          overlay={
            <Menu>
              <Menu.Item key="1">(UTC+5:30)</Menu.Item>
              <Menu.Item key="2">(UTC+5:30)</Menu.Item>
              <Menu.Item key="3">(UTC+5:30)</Menu.Item>
            </Menu>
          }
          overlayStyle={{ marginLeft: '12px' }}
        >
          <Button>
            (UTC+5:30){" "}
            <span>
              <Divider
                type="vertical"
                style={dividerStyle}
              />{" "}
              <CaretDownOutlined />
            </span>
          </Button>
        </Dropdown>
      </Space> */}
    </Header>
  );
};
