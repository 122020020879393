import { Button } from "antd";
import styled, { css } from "styled-components";

export const ChooseFileRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ChooseFileButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.surfaceLighter_2};
  font-size: 12px;
  line-height: 16px;
  opacity: 0.8;
  color: ${({ theme }) => theme.color.black_3};
  width: 93px;
  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `}
    &.ant-btn[disabled]{
      border-color: ${({ theme }) => theme.color.transparent};
    }
`;

export const ChooseFileNote = styled.span`
  font-size: 9px;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black_4};
  display: inline-block;
  margin-left: 6px;
`;
