import styled, { css } from "styled-components";

export const SwitchBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0.5;
  .ant-switch,
  .ant-switch-checked {
    background-color: ${({ theme }) => theme.color.textOnSurface};
    border: 1px solid ${({ theme }) => theme.color.grey27};

    height: 20px;
    min-width: 40px;
    width: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    .ant-switch-handle {
      width: 14px;
      height: 14px;
      top: 2.2px;
      border-radius: 100%;
      box-sizing: border-box;
      :before {
        background-color: ${({ theme }) => theme.color.grey27};
      }
    }
  }

  .ant-switch {
    &:not(.ant-switch-checked) > .ant-switch-inner {
      font-size: 11px;
      color: ${({ theme }) => theme.color.grey27};
      margin-left: 20px;
    }
    &.ant-switch-checked > .ant-switch-inner {
      font-size: 11px;
      color: ${({ theme }) => theme.color.grey27};
      margin-left: 10px;
    }
  }

  .ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 16px);
    }
  }
`;

export const SwitchLabel = styled.span`
  font-size: 12px;
  color: ${({ theme, labelColor, unCheckedLabelColor }) => unCheckedLabelColor || labelColor || theme.color.surface};
  ${({ rightLabel }) =>
    rightLabel
      ? css`
          margin-left: 12px;
        `
      : css`
          margin-right: 12px;
        `}
`;
