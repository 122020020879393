import { SwapOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { UserCardWrapper } from "./style";

export const UserCard = ({ width, userData, key }) => {
  console.log(userData);
  return (
    <UserCardWrapper width={width} key={key}>
      {userData.first_name + " " + userData.last_name}
      {userData.translate_from && (
        <div className="translator-info">
          <div className="translator-language">{userData.translate_from}</div>
          <div className="swap-icon">
            <SwapOutlined />
          </div>
          <div className="translator-language">{userData.translate_to}</div>
        </div>
      )}
    </UserCardWrapper>
  );
};
