import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useState } from "react";
import theme from "../../styles/theme";
import {
  AddCircle,
  Box,
  BoxSubText,
  BoxTitle,
  FileName,
} from "./style";

export const DragAndDrop = ({
  boxHeight,
  boxWidth,
  onFileChange = () => null,
}) => {

  const [fileName, setFileName] = useState('');

  const onFileUpload = (fileData) => {
    const file = fileData.file;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    setFileName(file.name )
    onFileChange({ formData, fileName: file.name });
  };

  return (
    <Box height={boxHeight} width={boxWidth}>
      {fileName  ? (
        <FileName>{fileName}</FileName>
      ) : (
        <Upload.Dragger
          showUploadList={false}
          accept=".docx,.pptx,.pdf,.png"
          customRequest={onFileUpload}
        >
          <AddCircle>
            <PlusOutlined
              style={{ color: theme.color.dimGrey, fontSize: "25px" }}
            />
          </AddCircle>

          <BoxTitle>Drag & drop</BoxTitle>
        </Upload.Dragger>
      )}
      <BoxSubText>
        {fileName ? "proceed with this file" : "your vMeets Starter file here,"}
      </BoxSubText>
      <BoxSubText>
        or{" "}
        <Upload
          showUploadList={false}
          accept=".docx,.pptx,.pdf,.png"
          customRequest={onFileUpload}
        >
          <BoxSubText lineStyle="underline">
            browse files on your computer
          </BoxSubText>
        </Upload>
      </BoxSubText>
    </Box>
  );
};
