import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ActionDrawer } from "../../../../../common-components/action-drawer/ActionDrawer";
import { notifyError, notifyProgress, notifySuccess } from "../../../../../common-components/notification/Notification";
import { SectionTitle } from "../../../../../common-components/section-title/SectionTitle";
import { vMeetHttp } from "../../../../../http/axiosInstances";
import theme from "../../../../../styles/theme";
import { SpeakerMenuBar } from "../../../speaker/components/speaker-side-menu/SpeakerMenuBar";
import { SpeakerAdd } from "./style";

export const SpeakerDrawer = ({
  visible,
  onClose,
  sessionSpeakers,
  session_id,
}) => {
  const { id } = useParams();
  const [selectedSpeaker, setSelectedSpeaker] = useState([]);
  const [speakerData, setSpeakerData] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);
  const onAddClick = (event, data) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const dataIndex = selectedSpeaker.indexOf(data.value);
    if (dataIndex >= 0) {
      if (selectedSpeaker.length === 1) {
        alert("Atleast a speaker need to be there for a session");
        return
      }
      removeSpeaker(data.value);
      selectedSpeaker.splice(dataIndex, 1);
    } else {
      addSpeaker(data.value);
      selectedSpeaker.push(data.value);
    }
    setSelectedSpeaker([...selectedSpeaker]);
    console.log(selectedSpeaker);
  };
  const addSpeaker = (speakerId) => {
    notifyProgress("Changes are being saved...");
    const addSpeakerUrl = `session/${session_id}/map-participant`;
    const request = {
      speakers: [speakerId],
    };
    vMeetHttp
      .post(addSpeakerUrl, request)
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const removeSpeaker = (speakerId) => {
    notifyProgress("Changes are being saved...");
    const removeSpeakerUrl = `session/${session_id}/remove-participant`;
    const request = {
      participant_id: speakerId,
    };
    vMeetHttp
      .post(removeSpeakerUrl, request)
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  useEffect(() => {
    loadSpeaker();
  }, []);
  const loadSpeaker = () => {
    vMeetHttp
      .get(`/event/${id}/speaker`)
      .then((response) => {
        let data = Object.values(response.data.data);
        setSpeakerData(response.data.data);
        data.map((ele, i) => {
          let key = sessionSpeakers.find((s) => s.value === ele.id);
          if (key) {
            selectedSpeaker.push(ele.id);
            setSelectedSpeaker([...selectedSpeaker]);
          }

          setSpeakerList((speakerList) => [
            ...speakerList,
            { label: ele.first_name, value: ele.id },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //mark selected speaker after loded speakerlist

  return (
    <ActionDrawer
      visible={visible}
      onClose={onClose}
      title={<SectionTitle title="Add Speakers" />}
      width="309px"
      leftButtonText="Cancel"
      leftButtonAction={onClose}
      rightButtonText="Save"
      rightButtonAction={onClose}
      bodyStyle={{ padding: "12px 24px" }}
      buttonStyle={{
        buttonBaseColor: theme.color.grey27,
        actionButtonWidth: "84px",
      }}
    >
      <SpeakerMenuBar
        speakerList={speakerList}
        footer={false}
        width={"100%"}
        MenuRightElement={({ data }) => {
          const isAdded = selectedSpeaker.includes(data.value);
          return (
            <SpeakerAdd onClick={(e) => onAddClick(e, data)} isAdded={isAdded}>
              {isAdded ? "Added" : "Add"}
            </SpeakerAdd>
          );
        }}
      />
    </ActionDrawer>
  );
};
