import { Menu } from "antd";
import Layout, { Header } from "antd/lib/layout/layout";
import { Route, Switch, useHistory } from "react-router-dom";
import { Vmeet } from "./vmeet";
import logo from "./logo.png";
import { AppContainer } from "./App.style";
import LoginForm from "./login";

import { basicRoutes } from "./constant/routeConstant";
import { AppConfig } from "./app-config";
import axios from "axios";

const menus = {
  4: "/vmeet",
};

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      console.log("auth error");
      window.location.href = localStorage.getItem("vmAdmin_referrer");
    }
    return Promise.reject(error);
  }
);

function App() {
  const history = useHistory();

  const onMenuClick = (e) => {
    const url = menus[e["key"]];
    history.push(url);
  };

  return (
    <AppContainer className="App">
      <Layout style={{ height: "100vh" }}>
        <Header className="flex space-between header">
          <img src={logo} alt="logo" />

          <Menu
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            onClick={onMenuClick}
          >
            {/* <Menu.Item key="1">HCPs</Menu.Item>
            <Menu.Item key="2">Queries</Menu.Item>
            <Menu.Item key="3">Reports</Menu.Item> */}
            <Menu.Item key="4">vMeet Admin</Menu.Item>
            <Menu.Item key="5">
              {localStorage.getItem("vmAdmin_userName")}
            </Menu.Item>
          </Menu>
        </Header>

        <Switch>
          <Route path={basicRoutes.vmeet} component={Vmeet} />
          <Route path={basicRoutes.login} exact component={LoginForm} />
          {/* <Route component={NotFound} /> */}
          {/* <Route path="/vmeet/events/basicinfo"  component={Vmeet} /> */}
          {/* <Route path="/events" exact component={Vmeet} />
        <Route path={"/basicinfo"} exact component={Vmeet} />
        <Route path={"/speakers"} exact component={Vmeet} />
        <Route path={"/new-event"} exact component={Vmeet} />
        <Route path={"/attendees"} exact component={Vmeet} />
        <Route path={"/schedule"} exact component={Vmeet} />
        <Route path={"/user-consent"} exact component={Vmeet} />
        <Route path={"/legal"} exact component={Vmeet} />
        <Route path={"/notification"} exact component={Vmeet} />
        <Route path={"/sessions"} exact component={Vmeet} /> */}
        </Switch>
      </Layout>
    </AppContainer>
  );
}

export default App;
