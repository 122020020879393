import { Col, Row, Space } from "antd";
import React, { Fragment } from "react";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { FormField } from "../../../common-components/form-field/FormField";
import { RichTextEditor } from "../../../common-components/rich-text-editor/RichTextEditor";
import { SectionTitle } from "../../../common-components/section-title/SectionTitle";
import { SelectDropdown } from "../../../common-components/select-dropdown/SelectDropdown";
import { SwitchBox } from "../../../common-components/switch-box/SwitchBox";
import {
  AddConsent,
  AdditionalOptionText,
  consentWidth,
  OptionDivider,
  UserConsentContainer,
} from "./style";

const consentTypeOptions = [
  { label: "Implicit", value: "Implicit" },
  { label: "Opt in", value: "Opt in" },
];

const additionalOptionList = [
  {
    label: "Attendees can opt-out of Chat/Messaging",
    value: 0,
    disabled: true,
  },
  {
    label: "Attendees can opt-out of Meeting Requests",
    value: 0,
    disabled: true,
  },
  { label: "Attendee can opt out of Attendee List", value: 0, disabled: true },
  {
    label: "Show Privacy Policy and Terms and condition section",
    value: 0,
    disabled: true,
  },
  {
    label: "Attendees can request to delete their data",
    value: 0,
    disabled: true,
  },
];

export const UserConsent = () => {
  return (
    <EventLayout space="24px">
      <Space size={[24]} direction="vertical">
        <span />
        <SectionTitle title="User Consent" />
      </Space>
      <UserConsentContainer>
        <Space className="flex" direction="vertical" size={[18]}>
          <SelectDropdown
            options={consentTypeOptions}
            disabled={false}
            style={consentWidth}
            label="User consent type on Login"
          />

          <div>
            <FormField label="User consent text">
              <RichTextEditor />
            </FormField>
            <AddConsent>+ Add Additional Consent</AddConsent>
          </div>

          <Row>
            <Col flex={0.7}>
              <AdditionalOptionText>Additional Options</AdditionalOptionText>

              {additionalOptionList.map((option, index) => (
                <Fragment key={index}>
                  <SwitchBox label={option.label} disabled={option.disabled} />
                  {additionalOptionList.length !== index + 1 ? (
                    <OptionDivider />
                  ) : null}
                </Fragment>
              ))}
            </Col>
          </Row>
        </Space>
      </UserConsentContainer>
    </EventLayout>
  );
};
