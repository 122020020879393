import { Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../common-components/form-field/FormField";
import { ProfileDetail } from "./components/profile-detail/ProfileDetail";
import {
  ChooseFileRow,
  SpeakerContent,
  leftButtonStyle,
  rightButtonStyle,
  BioFieldStyle,
} from "./style";
import { SocialBlock } from "./components/social-block/SocialBlock";
import { RichTextEditor } from "../../../common-components/rich-text-editor/RichTextEditor";
import { SpeakerMenuBar } from "./components/speaker-side-menu/SpeakerMenuBar";
import { ActionButtonHeader } from "../../../common-components/action-button-header/ActionButtonHeader";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { ChooseFile } from "../../../common-components/choose-file/ChooseFile";

import { userManagementHttp, vMeetHttp } from "../../../http/axiosInstances";
import Loader from "../../../common-components/loader";
import { useParams } from "react-router";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";
import { Option } from "antd/lib/mentions";

export const Speaker = () => {
  const { id } = useParams();
  const [enableEdit, setEnableEdit] = useState(false);
  const [newSpeaker, setNewSpeaker] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState(false);
  const [speakerData, setSpeakerData] = useState({});
  const [speakerList, setSpeakerList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [displayEditor, setDisplayEditor] = useState(true);
  const [speakerProfile, setSpeakerProfile] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    feedProfileImageLink: "",
    profileDescription: "",
  });
  const handleEdit = () => {
    if (!enableEdit) setEnableEdit(true);
    else {
      setEnableEdit(false);
      if (newSpeaker) saveSpeaker();
      else updateSpeaker();
    }
  };
  const handleFormCancel = () => {
    setEnableEdit(false);
    //To reset the form, unmount and remount the EventInfo component
    // setRenderForm(true);
    // setTimeout(() => {
    //   setRenderForm(false);
    // }, 1);
  };
  const onChangeSpeaker = (e) => {
    setEnableEdit(false);
    setNewSpeaker(false);
    setSelectedSpeaker(e);
    setProfile(e);
    setDisplayEditor(false);
    setTimeout(() => {
      setDisplayEditor(true);
    }, 1);
  };
  useEffect(() => {
    loadSpeaker();
    loadSessionList();
  }, []);
  const loadSpeaker = () => {
    setSpeakerData({});
    setSpeakerList([]);
    vMeetHttp
      .get(`/event/${id}/speaker`)
      .then((response) => {
        let res = response.data.data;
        let data = Object.values(res);
        setSpeakerData(res);
        data.map((ele, i) => {
          setSpeakerList((speakerList) => [
            ...speakerList,
            {
              label: ele.first_name + " " + ele.last_name,
              id: ele.system_user_id,
            },
          ]);
        });
        setSelectedSpeaker(data[0].system_user_id.toString());
        setTimeout(() => {
          setProfile(data[0].system_user_id, res);
        }, 10);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadSessionList = async () => {
    const sessionListURL = `event/${id}/session/list`;
    vMeetHttp
      .post(sessionListURL, {
        filters: {
          type: 1,
        },
      })
      .then((response) => {
        let sessions = response.data.data;
        setSessionList(sessions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const setProfile = (e, speaker = false) => {
    let data = Object.keys(speakerData).length > 0 ? speakerData : speaker;
    setSpeakerProfile({
      id: data[e].id,
      system_user_id: data[e].system_user_id,
      firstName: data[e].first_name,
      lastName: data[e].last_name,
      profileDescription: data[e].profile_description,
      email: data[e].email,
      designation: data[e].designation,
      feedProfileImageLink:
        data[e].feed_profile_image_link != null
          ? data[e].feed_profile_image_link
          : data[e].sso_account_profile_image_link,
    });
    setDisplayEditor(false);
    setTimeout(() => {
      setDisplayEditor(true);
    }, 1);
  };

  const onChange = (e) => {
    setSpeakerProfile({ ...speakerProfile, [e.target.name]: e.target.value });
  };
  const changeBio = (e) => {
    newSpeaker
      ? setSpeakerProfile({ ...speakerProfile, ["description"]: e })
      : setSpeakerProfile({ ...speakerProfile, ["profileDescription"]: e });
  };
  const changeProfile = (e) => {
    setSpeakerProfile({ ...speakerProfile, ["feedProfileImageLink"]: e });
  };

  const updateSpeaker = () => {
    notifyProgress("Changes are being saved...");
    userManagementHttp
      .patch(
        `organization/${localStorage.getItem(
          "vmAdmin_organizationId"
        )}/user/${selectedSpeaker}`,
        speakerProfile
      )
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };

  const saveSpeaker = () => {
    notifyProgress("Changes are being saved...");
    vMeetHttp
      .post(`/event/${id}/participant`, speakerProfile)
      .then((response) => {
        console.log(response);
        notifySuccess("Changes saved successfully!");
        loadSpeaker();
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };

  const addSpeaker = () => {
    setEnableEdit(true);
    setNewSpeaker(true);
    setSelectedSpeaker("-1");
    setSpeakerProfile({
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
      feedProfileImageLink: "",
      description: "",
      participatingAs: 3,
      sendInviteEmail: false,
      sessionIds: {},
    });
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    let sessions = value.includes(",") ? value.split(",") : value;
    setSpeakerProfile({ ...speakerProfile, ["sessionIds"]: sessions });
  }
  return (
    <EventLayout
      leftMenuElement={
        selectedSpeaker && (
          <SpeakerMenuBar
            selectedSpeaker={selectedSpeaker}
            onChangeSpeaker={onChangeSpeaker}
            speakerList={speakerList}
            addSpeaker={addSpeaker}
          />
        )
      }
    >
      {Object.keys(speakerData).length > 0 ? (
        <SpeakerContent>
          <ActionButtonHeader
            leftButtonText={enableEdit ? "Cancel" : "Cancel"}
            leftButtonAction={handleFormCancel}
            leftButtonDisabled={!enableEdit}
            rightButtonAction={handleEdit}
            rightButtonText={enableEdit ? "Save" : "Edit"}
            buttonWidth="77px"
            leftButtonStyle={leftButtonStyle}
            rightButtonStyle={rightButtonStyle}
          />

          <Space direction="vertical" size={[16, 20, 20]} className="flex">
            {speakerData && (
              <ProfileDetail
                disabled={!enableEdit}
                speaker={speakerProfile}
                onChange={onChange}
                newSpeaker={newSpeaker}
                changeProfile={changeProfile}
              />
            )}
            {newSpeaker && (
              <>
                <FormField label="Profile Image">
                  <Input
                    placeholder="http://www.linkedin.com/example"
                    size="small"
                    disabled={!enableEdit}
                    name="feedProfileImageLink"
                    onChange={onChange}
                    style={{ width: "577px" }}
                  />
                </FormField>

                <FormField label="Sessions">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "577px" }}
                    placeholder="Select sessions to map"
                    defaultValue={[]}
                    onChange={handleChange}
                  >
                    {Object.values(sessionList).map((e) => (
                      <Option key={e.session_id}>{e.name}</Option>
                    ))}
                  </Select>
                </FormField>
              </>
            )}
            <FormField label="Bio*">
              <BioFieldStyle>
                {displayEditor && (
                  <RichTextEditor
                    value={speakerProfile.profileDescription}
                    onStateChange={changeBio}
                    name="profileDescription"
                    disabled={!enableEdit}
                  />
                )}
              </BioFieldStyle>
            </FormField>

            {/* <Space style={{ display: "none" }} size={[16]}>
              <FormField label="Presentation Title*">
                <Input
                  size="small"
                  disabled={false}
                  placeholder="Title"
                  style={{ width: "201px" }}
                />
              </FormField>
              <FormField label="Presentation File">
                <ChooseFileRow>
                  <ChooseFile
                    disabled={false}
                    noteText="Only docx, pptx and pdf file with maximum size of 50 MB is allowed."
                    accept=".docx,.pptx,.pdf,.png"
                  />
                </ChooseFileRow>
              </FormField>
            </Space>
             <SocialBlock /> */}
          </Space>
        </SpeakerContent>
      ) : (
        <Loader></Loader>
      )}
    </EventLayout>
  );
};
