import React from "react";
import { AddButtonContainer } from "./style";

export const AddButton = ({
  onClick = () => null,
  name = "+ Button",
  width = "",
  fontSize,
  disabled = false,
}) => (
  <AddButtonContainer
    onClick={onClick}
    width={width}
    fontSize={fontSize}
    disabled={disabled}
  >
    {name}
  </AddButtonContainer>
);
