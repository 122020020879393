import { Radio } from "antd";
import styled from "styled-components";

export const RadioButton = styled(Radio.Button)`
  height: 28px;
  width:${({ buttonWidth }) => buttonWidth || '182px'};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.textDisabled};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.whiteSmoke_1};
  border-color: ${({ theme }) => theme.color.grey87};
  
  &.ant-radio-button-wrapper-checked,
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    color: ${({ theme, activeTextColor }) => activeTextColor || theme.color.accent};
    border-color: ${({ theme }) => theme.color.grey87};
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover, :active {
    color: ${({ theme, activeTextColor }) => activeTextColor || theme.color.accent};
    border-color: ${({ theme }) => theme.color.grey87};
  }
  &.ant-radio-button-wrapper:hover{
    color: ${({ theme, activeTextColor }) => activeTextColor || theme.color.accent};
  }

  &.ant-radio-button-wrapper:before {
    display: none;
  }
`;