import React, { useEffect, useRef, useState } from "react";
import { MoreOutlineMenu } from "../../../common-components/more-outline-menu/MoreOutlineMenu";
import { InviteButton } from "./style";
import { InviteDrawer } from "./component/invite-drawer/InviteDrawer";
import { TableComponent } from "../../../common-components/table-component/TableComponent";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import { useParams } from "react-router";
import {
  userManagementHttp,
  vMeetHttp,
  vMeetHttpWithoutVersion,
} from "../../../http/axiosInstances";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";
import Loader from "../../../common-components/loader";

const progressTitle = "Changes are being saved...";
const errorTitle = "Error saving changes";
const successTitle = "Changes saved successfully!";

export const Attendee = () => {
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (modalVisible === false) {
      currentEditSandbox.current = {};
    }
  }, [modalVisible]);
  const [attendeeList, setAttendeeList] = useState({});
  const [languages, setLanguages] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [mode, setMode] = useState("invite");
  const [currentProfile, setCurrentProfile] = useState({});
  const [searchActive, setSearchActive] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentEditSandbox = useRef();

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      let data = {
        filters: {
          type: 1,
        },
      };
      let sl = await vMeetHttp.post(`event/${id}/session/list`, data);
      sl = sl.data.data;
      setSessionList(sl);
    })();
  }, []);

  const inviteModalToggle = () => {
    setModalVisible(!modalVisible);
  };

  const searchOnChange = (e) => {
    let searchTerm = e.target.value;
    if (searchTerm === "") {
      setSearchActive(false);
    } else {
      setSearchActive(true);
      let searchResults = Object.values(attendeeList).filter(
        (a) =>
          (a.first_name &&
            a.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (a.last_name &&
            a.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (a.email && a.email.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      setSearchData(searchResults);
    }
  };

  const onEdit = (profile) => {
    setMode("edit");
    setCurrentProfile({ ...profile });
    currentEditSandbox.current = { ...profile };
    inviteModalToggle();
  };

  const onInvite = () => {
    setMode("invite");
    setCurrentProfile({});
    inviteModalToggle();
    currentEditSandbox.current.participatingAs = 4;
  };

  const saveEdit = async () => {
    console.log(currentEditSandbox.current);
    notifyProgress(progressTitle);

    try {
      await userManagementHttp.patch(
        `/organization/${localStorage.getItem("vmAdmin_organizationId")}/user/${
          currentEditSandbox.current.system_user_id
        }`,
        currentEditSandbox.current
      );

      notifySuccess(successTitle);
      inviteModalToggle();

      let profile = currentEditSandbox.current;

      currentEditSandbox.current.first_name =
        profile.firstName || profile.first_name;
      currentEditSandbox.current.last_name =
        profile.lastName || profile.last_name;

      let attendeeListCopy = { ...attendeeList };
      attendeeListCopy[currentEditSandbox.current.system_user_id] =
        currentEditSandbox.current;

      setAttendeeList((al) => attendeeListCopy);
    } catch (e) {
      console.error(e);
      notifyError(errorTitle);
    }
  };

  const sendInvite = async () => {
    //check translator role have languages filled
    let obj = currentEditSandbox.current.sessionRole.find(
      (o) => o.participatingAs === 6
    );
    if (obj) {
      if (
        !currentEditSandbox.current.translateFrom ||
        !currentEditSandbox.current.translateTo
      ) {
        notifyError("Translate launguage cant be empty");
        return;
      }
      if(currentEditSandbox.current.translateFrom === currentEditSandbox.current.translateTo){
        notifyError("Translate launguage From and To can't be same");
        return;
      }
    }
    notifyProgress("Inviting Attendee...");

    try {
      await vMeetHttp.post(
        `/event/${id}/participant`,
        currentEditSandbox.current
      );

      notifySuccess("Invite successfull!");
      inviteModalToggle();
    } catch (e) {
      console.error(e);
      notifyError(errorTitle);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 150,
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 150,
      render: (text) => <b>{text}</b>,
    },
    /* {
      title: "Attending as",
      dataIndex: "attenderType",
      width: 150,
      render: (text) => (text === "Attendee" ? text : <b>{text}</b>),
    }, */
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    /* {
      title: "Tags",
      dataIndex: "tags",
      render: (tagList) => <TagBoxs tagList={tagList} />,
    }, */
    {
      title: "More",
      dataIndex: "more",
      width: 70,
      render: (a, profile) => (
        <MoreOutlineMenu onEdit={onEdit} callbackObject={profile} />
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await vMeetHttp.get(`/event/${id}/attendee`);
        setAttendeeList(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    getTranslatorLanguages();
  }, []);

  const getTranslatorLanguages = async () => {
    try {
      let orgId = localStorage.getItem("vmAdmin_organizationId");
      let config = {
        headers: {
          organizationId: orgId,
        },
      };
      const response = await vMeetHttpWithoutVersion.get(
        `/event/languages`,
        config
      );
      console.log(response.data.data);
      setLanguages(response.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return (
      <EventLayout>
        <Loader />
      </EventLayout>
    );
  }

  return (
    <EventLayout space={"24px"}>
      <TableComponent
        subHeaderRightElement={
          <InviteButton onClick={onInvite}>Add</InviteButton>
        }
        columns={columns}
        data={!searchActive ? Object.values(attendeeList) : searchData}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)" }}
        searchOnChange={searchOnChange}
      />

      <InviteDrawer
        onClose={inviteModalToggle}
        modalVisible={modalVisible}
        mode={mode}
        currentEditSandbox={currentEditSandbox}
        currentProfile={currentProfile}
        rightButtonAction={mode === "invite" ? sendInvite : saveEdit}
        leftButtonAction={() => inviteModalToggle()}
        sessionList={sessionList}
        languages={languages}
      />
    </EventLayout>
  );
};
