import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import theme from "../../styles/theme";

export const InputSearch = ({
  onSearch,
  placeholder = "Search",
  width = "300px",
  style = {},
  searchIconColor = "",
  inputOnChange = () => null,
}) => (
  <Input
    placeholder={placeholder}
    size="small"
    onSearch={onSearch}
    style={{
      width,
      borderColor: theme.color.searchBorder,
      ...style,
    }}
    bordered={true}
    suffix={
      <SearchOutlined
        style={{ color: searchIconColor || theme.color.grey71 }}
      />
    }
    onChange={inputOnChange}
  />
);
