import { Input, Space } from "antd";
import React from "react";
import { FormField } from "../../../../../common-components/form-field/FormField";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import { ProfileCard } from "../profile-card/ProfileCard";

const titleOptions = [{ label: "Doctor", value: "doctor" }];
const width = { width: "201px" };

export const ProfileDetail = ({ speaker, onChange, disabled, newSpeaker, changeProfile }) => (
  <Space wrap size={[27]}>
    {!newSpeaker && <ProfileCard speaker={speaker} changeProfile={changeProfile} disabled={disabled} />}
    <Space direction="vertical" size={[20]}>
      <Space size={[16]}>
        <FormField label="First Name*">
          <Input
            size="small"
            disabled={disabled}
            style={width}
            value={speaker.firstName}
            onChange={onChange}
            name="firstName"
          />
        </FormField>
        <FormField label="Last Name">
          <Input
            size="small"
            disabled={disabled}
            style={width}
            value={speaker.lastName}
            onChange={onChange}
            name="lastName"
          />
        </FormField>
      </Space>
      <Space size={[16]}>
        {/* <SelectDropdown label='Timezone' options={titleOptions} onChange={()=>null} style={width}/> */}
        <FormField label="Designation*">
          <Input
            size="small"
            disabled={disabled}
            style={width}
            value={speaker.designation}
            onChange={onChange}
            name="designation"
          />
        </FormField>
        <FormField label="Email address*">
          <Input
            size="small"
            disabled={disabled}
            style={width}
            value={speaker.email}
            onChange={onChange}
            name="email"
          />
        </FormField>
      </Space>
    </Space>
  </Space>
);
