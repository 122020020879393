import styled from "styled-components";
import ProfilePlaceHolder from "./profile-placeholder.jpg";

export const ProfileImage = styled.div`
  width: 132px;
  height: 132px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.surfaceLighter_2};
  background: url(${(props) => props.profile}), url(${ProfilePlaceHolder});
  background-size: cover;
`;

export const ProfileCardContainer = styled.div`
  font-size: 10px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 600;

    color: ${({ theme }) => theme.color.nobel};
    text-decoration-line: underline;
    margin-top: 13px;
    margin-bottom: 4px;
    cursor: pointer;
  }
  span {
    opacity: 0.4;
    color: ${({ theme }) => theme.color.nobel};
    cursor: pointer;
  }
`;
