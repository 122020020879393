import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";
import theme from "../../styles/theme";

export const Content = styled.div``;

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  position: absolute;
  bottom: 0;
  height: 100px;
  left: 0;
  right: 0;
  padding: 12px 24px 0;
  background-color: ${({ theme }) => theme.color.grey98};
`;

const ActionButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 2px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearButton = styled(ActionButton)`
  border-color: ${({ theme, buttonBaseColor }) =>
    buttonBaseColor || theme.color.accent};
  color: ${({ theme, buttonBaseColor }) =>
    buttonBaseColor || theme.color.sonicSilver};
  margin-right: 12px;
  width: ${({ actionButtonWidth }) => actionButtonWidth || "114px"};
`;

export const SendInviteButton = styled(ActionButton)`
  background-color: ${({ theme, buttonBaseColor }) =>
    buttonBaseColor || theme.color.accent};

  color: ${({ theme }) => theme.color.textOnSurface};
  width: ${({ actionButtonWidth }) => actionButtonWidth || "114px"};
`;


export const drawerHeaderStyle = {height: '40px', background: theme.color.grey98}
export const drawerBodyStyle = {background: theme.color.grey98, marginBottom:'100px'}
export const CloseIcon = styled(CloseOutlined)`
  color: ${({ theme }) => theme.color.black_2};
  font-size: 18px;
`;