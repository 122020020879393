import { Button } from "antd";
import React from "react";
import { vmeetEventRoute } from "../../../../../constant/routeConstant";
import { EventContainer, EventDivider, FirstBlock, LiveTag, SecondBlock } from "./style";

export const Event = ({
  title = "Relevance of Digital Logistics and SupplyChain Management during COVID-19",
  date = "11th August, 2020",
  time = "0:00 - 23:30 GMT",
  isLive = false,
  history,
}) => {
  const onManageClick = () => {
    history.push(`${vmeetEventRoute}/1/basicinfo`);
  };
  return (
    <EventContainer>
      {isLive ? <LiveTag>LIVE</LiveTag> : null}
      <FirstBlock>
        <div>{title}</div>
        <div>
          <p>{date}</p>
          <p>{time}</p>
        </div>
      </FirstBlock>
      <EventDivider  />
      <SecondBlock>
        <Button type="text">Speakers</Button>

        <Button type="text" onClick={onManageClick}>
          Manage
        </Button>
        <Button type="text">Publish</Button>
      </SecondBlock>
    </EventContainer>
  );
};
