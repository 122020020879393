import styled, { css } from "styled-components";

export const EditorWrapper = styled.div`
  width: ${(props) => props.boxWidth || "100%"};
  border-radius: 2px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.surfaceLighter_2};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.color.surfaceLighter_2};
    `}
  .rdw-editor-wrapper {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        pointer-events: none;
      `}
  }
  :hover {
    border-color: ${({ theme }) => theme.color.grey71};
  }
  .editor-main {
    height: ${(props) => props.boxHeight || "155px"};
    border: none;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0;
    border-width: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.surfaceLighter_2};

    .rdw-option-wrapper {
      img {
        width: 10px;
      }
    }
    .rdw-block-wrapper,
    .rdw-fontsize-wrapper {
      font-size: 12px;
    }
  }
  .public-DraftStyleDefault-block {
    margin: 2px 0;
  }
  .rdw-dropdown-selectedtext {
    span {
      color: #333333;
    }
  }
  .rdw-link-decorator-wrapper {
    a {
      span {
        color: #1895b3;
        text-decoration: underline;
      }
    }
  }
`;
