import { CaretDownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";
import { FormField } from "../form-field/FormField";

const { Option } = Select;
export const SelectDropdown = ({
  label,
  labelColor,
  options = [],
  placeholder = "Select",
  defaultValue,
  disabled = false,
  onChange = () => null,
  style = { width: "100%" },
  value,
  mode,
}) => {
  const props = defaultValue
    ? {
        defaultValue,
      }
    : {};
  const optionList = () => {
    if (mode === "lounge") {
      return (
        <>
          <Option value="2">2</Option>
          <Option value="4">4</Option>
          <Option value="8">8</Option>
          <Option value="10">10</Option>
          <Option value="16">16</Option>
        </>
      );
    } else if (mode === "notification") {
      return (
        <>
          <Option value="1" >All</Option>
          <Option value="2">Moderator</Option>
          <Option value="3">Speaker</Option>
          <Option value="4">Attendee</Option>
        </>
      );
    }
  };
  return (
    <FormField label={label} labelColor={labelColor}>
      <Select
        onChange={onChange}
        suffixIcon={<CaretDownOutlined />}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={value}
        style={style}
      >
        {optionList()}
      </Select>
    </FormField>
  );
};
