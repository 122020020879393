import { DatePicker, Space, TimePicker } from "antd";
import React from "react";
import {
  displayTimeFormat,
  dateFormat,
  timeFormat,
  dateTimeFormat,
} from "../../constant/constant";
import { FormField } from "../form-field/FormField";
import { TextLabel } from "./style";
import moment from "moment";
const timeStyle = { width: "105px", marginLeft: "10px" };

export const DateAndTime = ({
  selectedSession,
  enableEdit,
  onChange = () => null,
  setDate,
  setTime,
}) => {
  return (
    <Space size={[21, 10]} className="flex" wrap>
      <FormField label="Start Date">
        <DatePicker
          placeholder={"Start date"}
          disabled={enableEdit}
          allowClear={false}
          format={"MMM DD[,] YYYY"}
          value={
            selectedSession.start_time
              ? moment.utc(selectedSession.start_time, dateTimeFormat).local()
              : moment.utc().local()
          }
          onChange={(d) => setDate(d, "start_time")}
        />
        <TimePicker
          format={displayTimeFormat}
          suffixIcon={<span />}
          disabled={enableEdit}
          style={timeStyle}
          value={
            selectedSession.start_time
              ? moment.utc(selectedSession.start_time, dateTimeFormat).local()
              : moment.utc().local()
          }
          onChange={(d) => setTime(d, "start_time")}
        />
      </FormField>
      <TextLabel></TextLabel>
      <FormField label="End date">
        <DatePicker
          placeholder={"End date"}
          disabled={enableEdit}
          allowClear={false}
          format={"MMM DD[,] YYYY"}
          value={
            selectedSession.end_time
              ? moment.utc(selectedSession.end_time, dateTimeFormat).local()
              : moment.utc().local()
          }
          onChange={(d) => setDate(d, "end_time")}
        />
        <TimePicker
          use12Hours
          format={displayTimeFormat}
          suffixIcon={<span />}
          disabled={enableEdit}
          style={timeStyle}
          placeholder="end time"
          value={
            selectedSession.end_time
              ? moment.utc(selectedSession.end_time, dateTimeFormat).local()
              : moment.utc().local()
          }
          onChange={(d) => setTime(d, "end_time")}
        />
      </FormField>
    </Space>
  );
};
