import styled, { css } from "styled-components";

export const UserCardWrapper = styled.div`
  width: ${({ width }) => width || "100%"};
  background-color: ${({ theme }) => theme.color.surfaceLighter_2};
  padding: 12px;
  margin: 8px 0;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.shadow};
  .translator-info {
    display: flex;

    .translator-language {
      font-size: x-small;
      font-weight: bold;
      width: 40%;
    }
    .swap-icon{
      width: 20%;
    }
  }
`;
