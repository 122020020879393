import { DeleteFilled, RightOutlined } from "@ant-design/icons";
import styled, { css } from "styled-components";

export const ChairWrapper = styled.div`
  position: absolute;
  transition: all 0.4s ease;
  cursor: pointer;
  width: 58px;
  height: 60px;
  left:  ${({left}) => `${left}%` || '50%'};      
  margin-left: 36px;    
  .user-initials {
    position: absolute;
    color: #43494e;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 9px;
    line-height: 1.4;
    text-transform: uppercase;
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.textOnSurface};
    border: 1px solid #43494e;
    border-radius: 100%;
    box-sizing: border-box;
  }
`;


export const HomeLoungeWrapper = styled.div`
  /* width: 92%;
  margin: 0 auto; */
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.7;
    `}
  .home-lounge-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .tab-wrapper {
    display: flex;

    .heading-txt {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #5a5a5a;
      text-align: center;
      padding-bottom: 4px;
      border-bottom: 1px solid #dae0e0;
      margin: 16px 18px 0px;
    }

    .card {
      width: ${({extraChairWidth='0'}) =>  `calc(${extraChairWidth}px + 225px)` || '225px'};
      height: 250px;
      box-shadow: 0px 16px 16px rgba(37, 37, 37, 0.1);
      background-color: #ffffff;
    }

    .discussion-table-box {
      display: flex;
      flex-wrap: wrap;
      padding: 17px 14px 0px;
      background-color: #ffffff;
      justify-content: center;
      .discussion-table-wrapper {
        position: relative;
        height: 160px;
        
        .discussion-table {
          width: ${({tableWidth='0'}) =>  `calc(${tableWidth}px + 80px)` || '80px'};
          height: 80px;
          background-color: #dae0e0;
          border: 2px solid #ffffff;
          border-radius: 4px;
          color: #5a5a5a;
          font-size: 12px;
          line-height: 1.4;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }

        /* Discussion Style Starts */
        &.session-table {
          width: ${({tableWidth='0'}) =>  `calc(${tableWidth}px + 160px)` || '160px'};
          ${ChairWrapper} {
            &:nth-child(even) {
              top: 0;
              transform: rotate(90deg);

              .user-initials {
                transform: rotate(-90deg);
                left: -24px;
                top: 15px;
              }
            }
            &:nth-child(odd) {
              bottom: 0;
              transform: rotate(-90deg);

              .user-initials {
                transform: rotate(90deg);
                left: -24px;
                top: 15px;
              }
            }

            &.last-chair{
              right: 0;
              transform: scale(-1);
              margin-right: 0;
              margin-left: auto;
              .user-initials {
                transform: scale(-1);
                left: -24px;
                top: 15px;
              }
            }
            &.first-chair {
              left: 0;
              transform: scale(1);
              margin-left: 0;
              .user-initials {
                left: -24px;
                top: 15px;
              }
            }
           
            &.first-chair,
            &.last-chair  {
              top: ${({left}) => left || '50%'};
              margin-top: -28px;
             
            }
          }
        }
        /* Amazon Discussion Style Ends */
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 14px;
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.7;
      `}
    .edit {
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.025em;
      text-decoration-line: underline;
      color: ${({ theme }) => theme.color.surface};
      cursor: pointer;
    }
  }
`;

export const EditArrow = styled(RightOutlined)`
  font-size: 10px;
`;

export const SessionDelete = styled(DeleteFilled)`
  color: ${({ theme }) => theme.color.surfaceLighter};
  cursor: pointer;
`;