import { Radio } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { NumberOfText, PollRow } from "../add-poll-drawer/style";
import { OptionTextArea, OptionWrapper } from "./style";

export const PollOption = ({
  placeholder = "",
  isMultiChoice = false,
  onChange = () => null,
  name,
  key,
  value,
}) => (
  <PollRow>
    <OptionWrapper>
      {isMultiChoice ? (
        <Checkbox className="option-icon" />
      ) : (
        <Radio className="option-icon" />
      )}

      <OptionTextArea
        maxLength={100}
        placeholder={placeholder}
        size="small"
        autoSize={{
          minRows: 1,
        }}
        onChange={onChange}
        name={name}
        key={key}
        defaultValue={value}
        maxLength="100"
      />
    </OptionWrapper>
    <NumberOfText>{value.length} / 100 characters left</NumberOfText>
  </PollRow>
);
