import { MoreOutlined as MoreOutline } from "@ant-design/icons";
import { Menu } from "antd";
import styled from "styled-components";

export const MoreMenuStyle = styled(Menu)`
    width: 100px;
    background-color: ${({ theme }) => theme.color.white_1};
`;

export const MoreOutlined = styled(MoreOutline)`
    vertical-align: middle;
    font-size: 18px;
`;