import { notification, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AddButton } from "../../../common-components/add-button/AddButton";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";
import Loader from "../../../common-components/loader";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../common-components/notification/Notification";
import { SessionTable } from "../../../common-components/session-table/SessionTable";
import { vMeetHttp } from "../../../http/axiosInstances";
import { AddTableDrawer } from "./components/add-table-drawer/AddTableDrawer";
import { LoungeSubHeader, LoungeTableTab, TabDivider } from "./style";

export const LoungeTable = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [loungeList, setLoungeList] = useState();
  const onTabeChange = (event, tabIndex) => {
    event.preventDefault();
    setActiveTab(tabIndex);
  };

  const addTableToggle = () => {
    setShowTable(!showTable);
  };

  useEffect(() => {
    loadLoungeList();
  }, []);
  const loadLoungeList = async () => {
    const sessionListURL = `event/${id}/session/list`;
    vMeetHttp
      .post(sessionListURL, {
        filters: {
          type: 2,
        },
      })
      .then((response) => {
        let sessions = response.data.data;
        setLoungeList(sessions);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteSession = (session_id) => {
    notifyProgress("Changes are being saved...");
    const sessionDeleteURL = `session/${session_id}`;
    vMeetHttp
      .delete(sessionDeleteURL)
      .then(() => {
        notifySuccess("Removed successfully");
        loadLoungeList();
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error in Removing");
      });
  };
  return (
    <EventLayout space="15px">
      {loungeList ? (
        <>
          <LoungeSubHeader>
            <LoungeTableTab>
              <span
                className={activeTab === 0 ? "active" : ""}
                onClick={(event) => onTabeChange(event, 0)}
              >
                Lounge Table
              </span>
            </LoungeTableTab>
            <AddButton name="+ Add Table" onClick={addTableToggle} />
          </LoungeSubHeader>

          <Space wrap size={[20, 20]} align="center" direction="horizontal">
            {loungeList.map((e, i) => (
              <SessionTable
                tableName={e.name}
                tableCapacity={e.attendee_capacity}
                keyId={i}
                session_id={e.session_id}
                deleteSession={deleteSession}
              />
            ))}
          </Space>
        </>
      ) : (
        <Loader></Loader>
      )}

      <AddTableDrawer visible={showTable} onClose={addTableToggle} loadLounge={loadLoungeList} />
    </EventLayout>
  );
};
