import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

export const EventDetailSideMenu = styled(Sider)`
  background: ${({ theme }) => theme.color.surfaceLighter};
  min-width: 130px !important;
  flex: 0 0 130px !important;
  padding: 73px 0 0 0;

  .ant-menu {
    background: ${({ theme }) => theme.color.transparent};
    border: none;
    color: ${({ theme }) => theme.color.textOnSurface};

    &.ant-menu-inline .ant-menu-item {
      width: inherit;
    }
    .ant-menu-item {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      padding: 0 12px;
      margin-bottom: 3px;
      user-select: none;
      :hover {
        opacity: 0.6;
        color: ${({ theme }) => theme.color.textOnSurface};
      }
      :after {
        border: none;
      }
      :active {
        background: ${({ theme }) => theme.color.transparent};
      }
      &.ant-menu-item-selected {
        color: ${({ theme }) => theme.color.transparent};
        background: ${({ theme }) => theme.color.text};
      }
      &.active{
        color: ${({ theme }) => theme.color.textOnSurface};
        background: ${({ theme }) => theme.color.text};
      }
    }
  }
`;
