import { Divider } from "antd";
import styled from "styled-components";

export const LoungeSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 26px 0 15px;
`;

export const LoungeTableTab = styled.div`
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: ${({ theme }) => theme.color.charcoal};
    padding-bottom: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    &.active {
      border-bottom: 3px solid ${({ theme }) => theme.color.primary};
    }
  }
`;

export const TabDivider = styled(Divider)`
  height: 19px;
  margin: 0 10px;
  border-color: ${({ theme }) => theme.color.charcoal};
`;

