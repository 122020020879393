import { Fragment } from "react";

export const ChairIcon = ({ userSeat = false, userSeatDark = false }) => {
  return (
    <Fragment>
      {userSeat ? (
        <svg
          width={60}
          height={58}
          className="chair-icon"
          viewBox="0 0 60 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="48.4585"
            y="49.7769"
            width="41.25"
            height="41.1696"
            rx={14}
            transform="rotate(-180 48.4585 49.7769)"
            fill="#9EACAC"
          />
          <mask
            id="path-2-outside-1"
            maskUnits="userSpaceOnUse"
            x="-0.222168"
            y="0.888911"
            width={24}
            height={56}
            fill="black"
          >
            <rect
              fill="white"
              x="-0.222168"
              y="0.888911"
              width={24}
              height={56}
            />
            <path d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z" />
          </mask>
          <path
            d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
            fill="#9EACAC"
          />
          <path
            d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
            stroke="white"
            strokeWidth={4}
            mask="url(#path-2-outside-1)"
          />
        </svg>
      ) : (
        <Fragment>
          {userSeatDark ? (
            <svg
              width={60}
              height={58}
              className="chair-icon"
              viewBox="0 0 60 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="48.4585"
                y="49.7769"
                width="41.25"
                height="41.1696"
                rx={14}
                transform="rotate(-180 48.4585 49.7769)"
                fill="#43494E"
              />
              <mask
                id="path-2-outside-1"
                maskUnits="userSpaceOnUse"
                x="-0.222168"
                y="0.888911"
                width={24}
                height={56}
                fill="black"
              >
                <rect
                  fill="white"
                  x="-0.222168"
                  y="0.888911"
                  width={24}
                  height={56}
                />
                <path d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z" />
              </mask>
              <path
                d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
                fill="#43494E"
              />
              <path
                d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
                stroke="white"
                strokeWidth={4}
                mask="url(#path-2-outside-1)"
              />
            </svg>
          ) : (
            <svg
              width={60}
              height={58}
              className="chair-icon"
              viewBox="0 0 60 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="48.4585"
                y="49.7769"
                width="41.25"
                height="41.1696"
                rx={14}
                transform="rotate(-180 48.4585 49.7769)"
                fill="#DAE0E0"
              />
              <mask
                id="path-2-outside-1"
                maskUnits="userSpaceOnUse"
                x="-0.222168"
                y="0.888911"
                width={24}
                height={56}
                fill="black"
              >
                <rect
                  fill="white"
                  x="-0.222168"
                  y="0.888911"
                  width={24}
                  height={56}
                />
                <path d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z" />
              </mask>
              <path
                d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
                fill="#DAE0E0"
              />
              <path
                d="M20.7299 5.0669C20.1065 3.59824 18.4017 2.90053 17.0027 3.66744C12.9321 5.89871 9.44599 9.08574 6.85472 12.9712C3.72739 17.6604 2.04011 23.1621 2.00077 28.7983C1.96141 34.4346 3.57171 39.9593 6.63326 44.6917C9.17003 48.613 12.6113 51.8484 16.6503 54.1363C18.0385 54.9226 19.7529 54.2488 20.3968 52.789C21.0406 51.3292 20.3671 49.6388 18.9989 48.818C15.9831 47.0086 13.4085 44.5276 11.4844 41.5534C9.03515 37.7674 7.74692 33.3477 7.7784 28.8387C7.80988 24.3297 9.1597 19.9283 11.6616 16.177C13.627 13.2299 16.236 10.7851 19.2768 9.01798C20.6563 8.21633 21.3533 6.53555 20.7299 5.0669Z"
                stroke="white"
                strokeWidth={4}
                mask="url(#path-2-outside-1)"
              />
            </svg>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
