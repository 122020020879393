import styled from "styled-components";

export const StepContainer = styled.div`
  display: inline-flex;
  opacity: ${({ disable }) => (disable ? 0.3 : 1)};
`;

export const Circle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid
    ${({ completed = false, theme, failed = false }) =>
      theme.color[
        completed ? "green" : failed ? "errorBorderColor" : "dimGrey"
      ]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  span {
    font-size: 21px;
    line-height: 29px;
    font-weight: 300;
    &.anticon {
      font-size: 18px;
      line-height: 0;
    }
    color: ${({ completed = false, theme }) =>
      theme.color[completed ? "green" : "dimGrey"]};
  }
`;

export const TextSection = styled.div`
  margin-left: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  b {
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.color.textDarker};
  }
  p {
    font-size: 14px;
    line-height: 145%;
    color: ${({ theme }) => theme.color.dimGrey};
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
