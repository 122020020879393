export const setCurrentEvent = (currentEvent) => {
  return {
    type: "SET_CURRENT_EVENT",
    payload: currentEvent,
  };
};

export const updateCurrentEvent = (updatables) => {
  return {
    type: "UPDATE_CURRENT_EVENT",
    payload: updatables,
  };
};
