const NODE_ENV = process.env.REACT_APP_NODE_ENV || "local";

let LOGIN_API_URL;
let LOGIN_API_VERSION;
let API_URL;
let API_VERSION;

switch (NODE_ENV) {
    case "production":
      LOGIN_API_URL = "https://login.inqutec.in/";
      LOGIN_API_VERSION = "api/v1/";
      API_URL = "https://vmeet.inqutec.in/";
      API_VERSION = "api/v1/";
      break;
    case "development":
      LOGIN_API_URL = "https://dev-login.inqutec.in/public/";
      LOGIN_API_VERSION = "api/v1/";
      API_URL = "https://dev-vmeet.inqutec.in/vmeet/public/";
      API_VERSION = "api/v1/";
      break;
    case "staging":
      LOGIN_API_URL = "https://login.inqutec.com/";
      LOGIN_API_VERSION = "api/v1/";
      API_URL = "https://vmeet.inqutec.com/";
      API_VERSION = "api/v1/";
      break;
    default:
      LOGIN_API_URL = "https://dev-login.inqutec.in/public/";
      LOGIN_API_VERSION = "api/v1/";
      API_URL = "https://dev-vmeet.inqutec.in/vmeet/public/";
      API_VERSION = "api/v1/";
      break;
  }

  export const AppConfig = {
    login_url: LOGIN_API_URL,
    login_version: LOGIN_API_VERSION,
    api_url: API_URL,
    api_version: API_VERSION,
  };