import styled from "styled-components";

export const DescriptionContainer = styled.div`
  border: none;
  padding: 9px 20px 12px;
  height: 84px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme, bgColor }) => bgColor || theme.color.blue_1};
  box-sizing: border-box;
`;

export const ScheduleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.color.black_2};
  width: 345px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.black_4};
`;

export const Name = styled.p`
  margin: 10px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.black_4};
`;
