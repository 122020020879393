import { LoaderWrapper, LoaderInner } from "./styles";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const Loader = () => {
  return (
    <LoaderWrapper>
      <LoaderInner>
        <Spin indicator={antIcon} />
      </LoaderInner>
    </LoaderWrapper>
  );
};

export default Loader;
