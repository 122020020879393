import { DatePicker, Input, Space, TimePicker } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ActionDrawer } from "../../../../../common-components/action-drawer/ActionDrawer";
import { FormField } from "../../../../../common-components/form-field/FormField";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../../../common-components/notification/Notification";
import { SectionTitle } from "../../../../../common-components/section-title/SectionTitle";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import theme from "../../../../../styles/theme";
import {
  AddTableHeader,
  AddTableRow,
  ClearBox,
  SlotBox,
  TimeSlotContainer,
  addTableBodyStyle,
} from "./style";
import moment from "moment";
import {
  dateTimeFormat,
  displayTimeFormat,
} from "../../../../../constant/constant";
import { TextLabel } from "../../../../../common-components/date-and-time/style";
import { vMeetHttp } from "../../../../../http/axiosInstances";
const timeStyle = { width: "105px", marginLeft: "10px" };

const capacityOptions = [
  { lable: "2", value: "2" },
  { lable: "4", value: "4" },
  { lable: "8", value: "8" },
  { lable: "10", value: "10" },
  { lable: "16", value: "16" },
];
export const AddTableDrawer = ({
  visible = false,
  onClose,
  session,
  session_id,
  loadLounge,
}) => {
  const { id } = useParams();
  const [loungeRequest, setLoungeRequest] = useState({
    name: "",
    description: "new lounge",
    type_id: 2,
    attendee_capacity: "2",
    start_time: "",
    end_time: "",
    video_service_provider_id: 2,
    parent_session_id: session_id,
  });

  useEffect(() => {
    setLoungeRequest({ ...loungeRequest, parent_session_id: session_id });
  }, [session_id]);
  useEffect(() => {
    if (session && session != null)
      setLoungeRequest({
        ...loungeRequest,
        start_time: session.start_time,
        end_time: session.end_time,
      });
    else {
      setLoungeRequest({
        ...loungeRequest,
        start_time: moment().format(dateTimeFormat),
        end_time: moment().format(dateTimeFormat),
      });
      console.log("setting default value");
    }
  }, [session]);
  const onChange = (e) => {
    setLoungeRequest({ ...loungeRequest, [e.target.name]: e.target.value });
  };
  const changeCapacity = (e) => {
    setLoungeRequest({ ...loungeRequest, attendee_capacity: e });
  };

  const setDate = (selectedDate, key) => {
    let newDate = moment.utc(loungeRequest[key]).local();

    let date = moment(selectedDate).date();
    let month = moment(selectedDate).month();
    let year = moment(selectedDate).year();

    newDate.set({ date, month, year });
    setLoungeRequest({
      ...loungeRequest,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };

  const setTime = (selectedDate, key) => {
    let newDate = moment.utc(loungeRequest[key]).local();

    let hour = moment(selectedDate).hour();
    let minute = moment(selectedDate).minute();
    let second = moment(selectedDate).second();

    newDate.set({ hour, minute, second });
    setLoungeRequest({
      ...loungeRequest,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };
  const createLounge = () => {
    notifyProgress("Changes are being saved...");
    const loungeCreateURL = `event/${id}/session`;
    vMeetHttp
      .post(loungeCreateURL, loungeRequest)
      .then((response) => {
        notifySuccess("Changes saved successfully!");
        onClose();
        loadLounge(session_id);
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  return (
    <ActionDrawer
      visible={visible}
      bodyStyle={addTableBodyStyle}
      onClose={onClose}
      leftButtonText="Cancel"
      leftButtonAction={onClose}
      rightButtonText="Save"
      rightButtonAction={createLounge}
      scroll={false}
      buttonStyle={{
        buttonBaseColor: theme.color.grey27,
        actionButtonWidth: "84px",
      }}
    >
      <AddTableHeader>
        <SectionTitle title="Add Table" />
      </AddTableHeader>

      <Space size={[19]} direction="vertical" className="flex">
        <AddTableRow>
          <FormField label="Table Name">
            <Input
              placeholder="Enter Lounge Table Name"
              size="small"
              style={{ letterSpacing: "0.075em" }}
              disabled={false}
              name="name"
              onChange={onChange}
            />
          </FormField>
          <SelectDropdown
            label="Capacity"
            options={capacityOptions}
            disabled={false}
            defaultValue={loungeRequest.attendee_capacity}
            value={loungeRequest.attendee_capacity.toString()}
            onChange={changeCapacity} //todoNaveen
            mode="lounge"
          />
        </AddTableRow>
        {!session && (
          <>
            <FormField label="Start Date">
              <DatePicker
                placeholder={"Start date"}
                disabled={false}
                format={"MMM DD[,] YYYY"}
                defaultValue={moment
                  .utc(loungeRequest.start_time, dateTimeFormat)
                  .local()}
                onChange={(d) => setDate(d, "start_time")}
              />
              <TimePicker
                format={displayTimeFormat}
                suffixIcon={<span />}
                disabled={false}
                style={timeStyle}
                defaultValue={moment
                  .utc(loungeRequest.start_time, dateTimeFormat)
                  .local()}
                onChange={(d) => setTime(d, "start_time")}
              />
            </FormField>

            <FormField label="End date">
              <DatePicker
                placeholder={"End date"}
                disabled={false}
                format={"MMM DD[,] YYYY"}
                defaultValue={moment
                  .utc(loungeRequest.end_time, dateTimeFormat)
                  .local()}
                onChange={(d) => setDate(d, "end_time")}
              />
              <TimePicker
                use12Hours
                format={displayTimeFormat}
                suffixIcon={<span />}
                disabled={false}
                style={timeStyle}
                placeholder="end time"
                defaultValue={moment
                  .utc(loungeRequest.end_time, dateTimeFormat)
                  .local()}
                onChange={(d) => setTime(d, "end_time")}
              />
            </FormField>
          </>
        )}
      </Space>
    </ActionDrawer>
  );
};
