import React from "react";
import { MoreOutlineMenu } from "../../../../common-components/more-outline-menu/MoreOutlineMenu";
import { TagBoxs } from "../../../../common-components/table-component/components/tag-box/TagBox";
import { TableComponent } from "../../../../common-components/table-component/TableComponent";
import { PeopleTableContainer } from "./style";

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    width: 150,
    render: (text) => <b>{text}</b>,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    width: 150,
    render: (text) => <b>{text}</b>,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Designation",
    dataIndex: "designation",
  },
  {
    title: "Tags",
    dataIndex: "tags",
    render: (tagList) => <TagBoxs tagList={tagList} />,
  },
  {
    title: "More",
    dataIndex: "more",
    render: () => <MoreOutlineMenu />,
  },
];

const data = [...Array(30)].map((_, index) => ({
  key: index,
  firstName: "John",
  lastName: "Brown",
  email: "b.daya@email.com",
  designation: "Sales Executive",
  tags: ["nice", "developer"],
}));

export const PeopleTable = () => (
  <PeopleTableContainer>
    <TableComponent data={data} columns={columns} />
  </PeopleTableContainer>
);
