import { Content } from "antd/lib/layout/layout";
import React from "react";
import { VmeetHeader } from "../../common-components/vmeet-header";
import { PeopleTable } from "./components/people-table/PeopleTable";

export const People = () => {
  return (
    <Content>
      <VmeetHeader
        title={"Relevance of Digital Logistics and Supply Chain Manageme..."}
      />
      <PeopleTable />
    </Content>
  );
};
