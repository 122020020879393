import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Open Sans';
    #root{
      height:100%;
    }
    
  }

  /** ant design styles override */ 

  //input and select style
  .ant-input, .ant-select{
      font-size: 12px;
      line-height: 20px;
      height: 30px;
      border-color:${({ theme }) => theme.color.surfaceLighter_2};
      color: ${({ theme }) => theme.color.black_4};
      &[disabled]{
        color: ${({ theme }) => theme.color.dimGrey_3};
        background-color: ${({ theme }) => theme.color.surfaceLighter_2};
        :hover{
          border-color: transparent;
        }
      }
      :hover,:focus{
        border-color:${({ theme }) => theme.color.grey71};
      }

      :active, :focus{
        box-shadow: ${({ theme }) => theme.color.shadow};
      }
  }

  .ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .ant-select{
    .ant-select-selector{
        height: 30px !important;
        display: flex;
        align-items: center;
    }
    &:not(.ant-select-customize-input) .ant-select-selector{
      border-color:${({ theme }) => theme.color.surfaceLighter_2};
    }
    &.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector{
      color: ${({ theme }) => theme.color.dimGrey_3};
      background-color: ${({ theme }) => theme.color.surfaceLighter_2};
      border-color: inherit;
      :hover{
        border-color: transparent;
      }
    }
    &:not(.ant-select-disabled):hover .ant-select-selector{
        border-color:${({ theme }) => theme.color.grey71};
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color:${({ theme }) => theme.color.surfaceLighter_2};
        box-shadow: ${({ theme }) => theme.color.shadow};
  }

  //input search style
  .ant-input-affix-wrapper{
    border-color:${({ theme }) => theme.color.searchBorder} !important;
    &:focus,&.ant-input-affix-wrapper-focused{
    border-color:${({ theme }) => theme.color.grey71} !important;
    box-shadow: ${({ theme }) => theme.color.shadow};
  }
  }

//button dropdown style
.ant-btn{
  &.ant-dropdown-trigger{
    border-color:${({ theme }) => theme.color.searchBorder};
    color:${({ theme }) => theme.color.black_2};
  }
}
  // date and time picker style
  .ant-picker{
    .ant-picker-input input {
      font-size: 12px;
      color: ${({ theme }) => theme.color.black_4};
    }

    border-color:${({ theme }) => theme.color.surfaceLighter_2};
    :hover,:focus{
        border-color:${({ theme }) => theme.color.grey71};
    }

    &.ant-picker-focused{
      border-color:${({ theme }) => theme.color.grey71};
      box-shadow: ${({ theme }) => theme.color.shadow};
    }
    
    &.ant-picker-disabled{
      background: ${({ theme }) => theme.color.surfaceLighter_2};
      border-color: transparent;
      :hover{
          border-color: transparent;
        }
    }
  }
  

  /* .ant-btn:not([disabled]):active{
    opacity: 0.7;
  } */
.ant-drawer-header{
  border:none;
}
  .ant-btn:hover, .ant-btn:focus{
    border-color:${({ theme }) => theme.color.grey};
    box-shadow: ${({ theme }) => theme.color.shadowElevated};
    color:${({ theme }) => theme.color.grey};
  }

  .flex{
    display: flex;
    &.space-between{
      justify-content: space-between;
    }
  }

  //layout style
  .ant-layout{
    background-color: ${({ theme }) => theme.color.transparent};
  }

  //table style
  .ant-table-wrapper{
    .ant-table-container{
      background-color: ${({ theme }) => theme.color.grey98};
      padding: 15px;
      box-shadow: ${({ theme }) => theme.color.shadowElevated};
      .ant-table-thead > tr > th{
        font-weight: 700;
        color: ${({ theme }) => theme.color.black_2};
        border-bottom: 3px solid ${({ theme }) => theme.color.grey91};
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid ${({ theme }) => theme.color.dimGrey_2};
        color: ${({ theme }) => theme.color.black_2};
      }
    }
   
  }

//Scroll bar style
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.grey71};
    border-radius: 10px;
    border: 3px solid transparent;
  }
`;
