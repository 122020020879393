import styled from "styled-components";

export const AppContainer = styled.div`
 .app-container{
  height:100vh;
 }
  .header {
    background: ${({ theme }) => theme.color.backgroundContrast};
    box-shadow: ${({ theme }) => theme.color.shadowElevated};
    .ant-menu-horizontal {
      border: none;

      background: ${({ theme }) => theme.color.backgroundContrast};
      color: ${({ theme }) => theme.color.textOnSurface};
      .ant-menu-item {
        width: 90px;
        text-align: center;
        border: none;
        :hover {
          color: ${({ theme }) => theme.color.white_1};
          opacity: 0.8;
        }
      }
      > .ant-menu-item-selected {
        position: relative;

        :after {
          position: absolute;
          content: " ";
          width: 90px;
          height: 6px;
          background: ${({ theme }) => theme.color.primary};
          bottom: 0;
          left: 0;
        }

        color: ${({ theme }) => theme.color.textOnSurface};
      }
    }
    img {
      width: 140px;
      height: 38px;
      align-self: center;
    }
  }
`;
