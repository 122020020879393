import { Divider } from "antd";
import styled from "styled-components";

export const EventContainer = styled.div`
  background-color: ${({ theme }) => theme.color.surface};
  box-shadow: ${({ theme }) => theme.color.shadowElevated};
  position: relative;
  height: 150px;
  width: 512px;
  /* flex: 1; */
  padding: 21px 12px 17px 12px;
  box-sizing: border-box;
  display: inline-block;
  p {
    margin: 0;
  }
`;

export const FirstBlock = styled.div`
  color: ${({ theme }) => theme.color.whiteSmoke};
  display: flex;
  justify-content: "space-between";
  padding-bottom: 25px;
  div {
    margin: 0 10px;
    font-size: 14px;
    line-height: 140%;
    flex: 1;
    :last-child {
      flex: 0.4;
    }
  }
`;

export const SecondBlock = styled.div`
  display: flex;
  margin: 16px 0;
  button {
    flex: 1;
    color: ${({ theme }) => theme.color.whiteSmoke};
    :nth-child(1){
      border: none;
    }
    :nth-child(2) {
      border-width: 0 2px;
      border-color: ${({ theme }) => theme.color.grey37};
    }
    :nth-child(3){
      border: none;
    }
  }
`;

export const LiveTag = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 17.1px;
  font-size: 8px;
  color: ${({ theme }) => theme.color.textOnSurface};
  background-color: ${({ theme }) => theme.color.carmine};
  position: absolute;
  top: 4px;
  left: 0;
`;

export const EventDivider = styled(Divider)`
  margin: 0;
  border-color: ${({ theme }) => theme.color.grey37};
`