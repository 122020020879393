import { Button } from "antd";
import styled from "styled-components";

export const SubHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey91};
  padding-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 79px;
  margin-bottom: 40px;
  > p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.text};
    margin: 0;
  }
`;

export const ActionButton = styled(Button)`
  width: 90px;
  height: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.textOnSurface};
  background-color: ${({ theme }) => theme.color.accent};
`;

export const ManualUploadContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  align-items: center;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.grey35};
    margin: 0;
    text-align: center;
  }
`;

export const BoxSubText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.dimGrey};
  text-decoration-line: underline;
  cursor: pointer;
  text-align: center;
`;

export const Sperator = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.dimGrey_1};
  margin: 0 20px;
  align-self: center;
`;
