import React from "react";
import { SideMenu } from "../../../../../common-components/side-menu/SideMenu";
import { MenuOption } from "../../../../../common-components/menu-option/MenuOption";

export const BannerSideMenu = ({
  onOptionClick = () => null,
  selectedValue = 1,
  menuList,
}) => {
  return (
    <SideMenu>
      <MenuOption
        menuList={menuList}
        onSelectOption={onOptionClick}
        defaultSelectedValue={selectedValue}
      />
    </SideMenu>
  );
};
