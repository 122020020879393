import { DeleteFilled } from "@ant-design/icons";
import { Space } from "antd";
import styled, { css } from "styled-components";

export const SessionSection = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  >div:first-child {
    margin-bottom: 7px;
  }
`;

export const SpeakerDeleteIcon = styled(DeleteFilled)`
  &.anticon {
    font-size: 11px;
    position: absolute;
    margin: 0;
    top: 35%;
    right: 4px;
  }
`;

export const SessionContainer = styled(Space)`
  width: 449px;
`;

export const sessionDescriptionStyle = { paddingTop: "5px" };

export const HintText = styled.i`
  font-size: 9px;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black_4};
  margin-left: 2px;
`;