import { Button } from "antd";
import styled from "styled-components";

export const NotificationContainer = styled.div`
    padding-top: 26px;
`

const ActionButton = styled(Button)`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  .vertical-middle{
      vertical-align: middle;
  }
`;

export const PreviousButton = styled(ActionButton)`
  width: 75px;
  background-color: ${({ theme }) => theme.color.grey};
  color: ${({ theme }) => theme.color.textOnSurface};
  margin-left: 6px;
`;

export const NextButton = styled(ActionButton)`
  color: ${({ theme }) => theme.color.grey29};
  width: 75px;
`;


export const MenuButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;