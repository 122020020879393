import { Button } from "antd";
import styled from "styled-components";

export const UserCountText = styled.i`
  color: ${({ theme }) => theme.color.textDisabled};
  margin-left: 7px;
`;


export const InviteButton = styled(Button)`
  width: 78px;
  height: 30px;
  background: ${({ theme }) => theme.color.accent};
  font-weight: 600;
  color: ${({ theme }) => theme.color.textOnSurface};
`;
