import React from "react";
import { SideMenuContainer, SideMenuContent, Footer } from "./style";

export const SideMenu = ({ children, width, footer }) => (
  <SideMenuContainer width={width}>
    <SideMenuContent>{children}</SideMenuContent>

    <Footer className="footer">{footer}</Footer>
  </SideMenuContainer>
);
