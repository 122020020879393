import { Col, Input, Row, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../../../common-components/form-field/FormField";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import theme from "../../../../../styles/theme";

const { Option } = Select;
const FormRow = ({ leftElement, rightElement }) => (
  <Row gutter={20}>
    <Col span={12}>{leftElement}</Col>
    <Col span={12}>{rightElement}</Col>
  </Row>
);

const labelColor = theme.color.surface;
/* const titleOptions = [{ label: "Mister", value: "Mister" }]; */
export const SingleBlockcontent = ({
  currentProfile,
  currentEditSandbox,
  mode,
  languages,
  translatorRole,
}) => {
  const [renderForm, setRenderForm] = useState(true);
  useEffect(() => {
    setRenderForm(false);
    setTimeout(() => {
      setRenderForm(true);
    }, 1);
  }, [currentProfile]);

  const setFirstName = (e) => {
    currentEditSandbox.current.firstName = e.target.value;
  };

  const setLastName = (e) => {
    currentEditSandbox.current.lastName = e.target.value;
  };

  const setEmail = (e) => {
    currentEditSandbox.current.email = e.target.value;
  };

  const setDesignation = (e) => {
    currentEditSandbox.current.designation = e.target.value;
  };

  const setTranslateFrom = (id) => {
    currentEditSandbox.current.translateFrom = id;
  };

  const setTranslateTo = (id) => {
    currentEditSandbox.current.translateTo = id;
  };
  console.log(languages);
  return (
    renderForm && (
      <Space direction="vertical" size={[14]} className="flex">
        <FormRow
          leftElement={
            <FormField label="First Name" labelColor={labelColor}>
              <Input
                size="large"
                disabled={false}
                defaultValue={currentProfile.first_name}
                onChange={setFirstName}
              />
            </FormField>
          }
          rightElement={
            <FormField label="Last Name" labelColor={labelColor}>
              <Input
                size="small"
                disabled={false}
                defaultValue={currentProfile.last_name}
                onChange={setLastName}
              />
            </FormField>
          }
          /*{
        <SelectDropdown
          label="Title"
          labelColor={labelColor}
          defaultValue="Mister"
          options={titleOptions}
        />
      } */
        />

        <FormRow
          leftElement={
            <FormField label="Email address" labelColor={labelColor}>
              <Input
                size="small"
                disabled={false}
                defaultValue={currentProfile.email}
                onChange={setEmail}
              />
            </FormField>
          }
          rightElement={
            <FormField label="Designation" labelColor={labelColor}>
              <Input
                size="small"
                disabled={false}
                defaultValue={currentProfile.designation}
                onChange={setDesignation}
              />
            </FormField>
          }
        />
        {translatorRole && (
          <FormRow
            leftElement={
              <FormField label="Translate From" labelColor={labelColor}>
                {/* <Input
                  size="small"
                  disabled={false}
                  defaultValue={currentProfile.translate_from}
                  onChange={setTranslateFrom}
                /> */}
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  size="small"
                  onChange={(id) => setTranslateFrom(id)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {languages.map((e, i) => (
                    <Option value={e.id}>{e.name + " (" + e.code + ")"}</Option>
                  ))}
                </Select>
              </FormField>
            }
            rightElement={
              <FormField label="Translate To" labelColor={labelColor}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  size="small"
                  onChange={(id) => setTranslateTo(id)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {languages.map((e, i) => (
                    <Option value={e.id}>{e.name + " (" + e.code + ")"}</Option>
                  ))}
                </Select>
              </FormField>
            }
          />
        )}
      </Space>
    )
  );
};
