import styled from "styled-components";

export const Box = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "360px"};
  border: 1px dashed ${({ theme }) => theme.color.grey77};
  background-color: ${({ theme }) => theme.color.snow};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const BoxTitle = styled.b`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.color.dimGrey};
  display: inline-block;
  margin: 8px 0 4px 0;
`;

export const BoxSubText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.dimGrey};
  text-decoration-line: ${({ lineStyle = "none" }) => lineStyle};
  cursor: pointer;
`;

export const AddCircle = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  border: 1px solid
    ${({ completed = false, theme }) =>
    theme.color[completed ? "green" : "dimGrey"]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const FileName = styled.b`
  font-size: 14px;
line-height: 19px;
color: ${({ theme }) => theme.color.accent};
`;