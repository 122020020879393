import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { Fragment } from "react";
import { dateFormat } from "../../constant/constant";
import { SideMenu } from "../side-menu/SideMenu";
import { ListRow } from "./style";

export const DayListTable = ({
  dayList = [
    { day: "Tue", date: "11 Oct", text: "7 Sessions", dayCount: "01" },
    { day: "Wed", date: "11 Oct", text: "7 Sessions", dayCount: "02" },
    {
      day: "Thu",
      date: "11 Oct",
      text: "No Sessions",
      dayCount: "03",
      disableText: true,
    },
  ],
  footerContent = <Fragment />,
  activeIndex = 0,
  onRowClick = () => null,
}) => {
  const scheduleDate = (key) => {
    return moment.utc(key).local().format(dateFormat);
  };
  return (
    <SideMenu footer={footerContent}>
      <Content>
        {dayList.map((dayData, index) => (
          <ListRow
            active={scheduleDate(dayData.key) === activeIndex}
            onClick={() => onRowClick(dayData.key, index)}
          >
            <div>
              <b>{dayData.day}</b>
              <b>{dayData.date}</b>
            </div>
            <div>
              <span className={dayData.disableText ? "disabled" : ""}>
                {dayData.text}
              </span>
              <span>Day {dayData.dayCount}</span>
            </div>
          </ListRow>
        ))}
      </Content>
    </SideMenu>
  );
};
