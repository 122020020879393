import { Tag } from "antd";
import styled from "styled-components";

export const TagBox = styled(Tag)`
  background-color: ${({ theme }) => theme.color.white_1};
  border-color: ${({ theme }) => theme.color.grey87};
  color: ${({ theme }) => theme.color.black_2};
  margin: 0;
  font-size: 12px;

  &.ant-tag-has-color .anticon-close {
    color: ${({ theme }) => theme.color.black_2};
    font-size: 10px;
  }
`;