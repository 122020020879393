import { DatePicker, Form, Input, Space, TimePicker } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormField } from "../../../../../common-components/form-field/FormField";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import {
  dateStyle,
  EventInfoContainer,
  descriptionStyle,
  LeftContainer,
  RightContainer,
  selectWidth,
  Title,
  ToText,
  Wrapper,
} from "./style";

const timeStyle = { width: "105px", marginLeft: "10px" };
const timeZoneOptions = [
  {
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  {
    label: "(GMT+10:00) Hobart",
    value: "(GMT+10:00) Hobart",
  },
  {
    label: "(GMT+09:30) Adelaide",
    value: "(GMT+09:30) Adelaide",
  },
];

export const EventInfo = ({ disableFields, formDataRef }) => {
  const eventInfo = useSelector((state) => state.currentEvent);
  const dateTimeFormat = `YYYY-MM-DD HH:mm:ss`;

  const [error, setError] = useState(false);

  const onFinishFailed = (errorInfo) => {
    setError(errorInfo); // set the state if error
  };

  const initialFormState = {
    name: eventInfo.name,
    title: eventInfo.title,
    description: eventInfo.description,
    meta_description: eventInfo.meta_description,
    meta_keywords: eventInfo.meta_keywords,
    start_time: eventInfo.start_time,
    end_time: eventInfo.end_time,
  };

  useEffect(() => {
    formDataRef.current = initialFormState;
  }, []);

  const setTitle = (e) => {
    formDataRef.current.title = e.target.value;
  };

  const setName = (e) => {
    formDataRef.current.name = e.target.value;
  };

  const setDescription = (e) => {
    formDataRef.current.description = e.target.value;
  };

  const setMetaDescription = (e) => {
    formDataRef.current.meta_description = e.target.value;
  };

  const setDate = (selectedDate, key) => {
    let newDate = moment.utc(formDataRef.current[key]).local();

    let date = moment(selectedDate).date();
    let month = moment(selectedDate).month();
    let year = moment(selectedDate).year();

    newDate.set({ date, month, year });
    formDataRef.current[key] = newDate.utc().format(dateTimeFormat);
  };

  const setTime = (selectedDate, key) => {
    let newDate = moment.utc(formDataRef.current[key]).local();

    let hour = moment(selectedDate).hour();
    let minute = moment(selectedDate).minute();
    let second = moment(selectedDate).second();

    newDate.set({ hour, minute, second });
    formDataRef.current[key] = newDate.utc().format(dateTimeFormat);
  };

  return (
    <EventInfoContainer>
      <LeftContainer>
        <Title>Event Information</Title>
        <Wrapper>
          <Space size={[27]} direction="vertical">
            <Form onFinishFailed={onFinishFailed}>
              <FormField label="Event Title">
                <Form.Item
                  name="Event Title"
                  rules={[
                    { required: true },
                    {
                      max: 100,
                      message: "Event Title must be 100 characters or less.",
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input
                    placeholder="Enter event title"
                    size="small"
                    disabled={disableFields}
                    defaultValue={eventInfo.title || ""}
                    onChange={setTitle}
                    required
                    maxLength="101"
                  />
                </Form.Item>
              </FormField>
              <FormField label="Event Name">
                <Form.Item
                  name="Event Name"
                  rules={[
                    { required: true },
                    {
                      max: 100,
                      message: "Event Name must be 100 characters or less.",
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input
                    placeholder="Enter event name"
                    size="small"
                    disabled={disableFields}
                    defaultValue={eventInfo.name || ""}
                    onChange={setName}
                    maxLength="101"
                  />
                </Form.Item>
              </FormField>
              <FormField label="Description">
                <Form.Item
                  name="Description"
                  rules={[
                    { required: true },
                    {
                      max: 5000,
                      message: "Description must be 500 characters or less.",
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input.TextArea
                    placeholder="Enter event details"
                    size="small"
                    autoSize={{
                      minRows: 10,
                    }}
                    style={descriptionStyle}
                    disabled={disableFields}
                    defaultValue={eventInfo.description || ""}
                    onChange={setDescription}
                    maxLength="501"
                  />
                </Form.Item>
              </FormField>
              <FormField label="Meta Description (for SEO & Social Sharing)">
                <Form.Item
                  name="Meta Description"
                  rules={[
                    { required: true },
                    {
                      max: 500,
                      message:
                        "Meta Description must be 500 characters or less.",
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input
                    placeholder="Enter description"
                    size="small"
                    disabled={disableFields}
                    onChange={setMetaDescription}
                    defaultValue={eventInfo.meta_description || ""}
                  />
                </Form.Item>
              </FormField>
            </Form>
          </Space>
        </Wrapper>
      </LeftContainer>
      <RightContainer>
        <Title>Date & Time</Title>
        <Wrapper>
          <Form onFinishFailed={onFinishFailed}>
            <Space size={[27, 30]} direction="vertical" wrap>
              <FormField label="Start Date">
                <div>
                  <DatePicker
                    onChange={(d) => setDate(d, "start_time")}
                    style={dateStyle}
                    placeholder={"Start date"}
                    /* disabled={disableFields} */
                    format={"MMM DD[,] YYYY"}
                    defaultValue={moment
                      .utc(eventInfo.start_time, dateTimeFormat)
                      .local()}
                    disabled={true}
                  />

                  <TimePicker
                    style={timeStyle}
                    format="h:mm A"
                    onChange={(d) => setTime(d, "start_time")}
                    suffixIcon={<span />}
                    disabled={true}
                    defaultValue={moment
                      .utc(eventInfo.start_time, dateTimeFormat)
                      .local()}
                  />
                </div>
              </FormField>
              <FormField label="End Date">
                <div className="time-container">
                  <DatePicker
                    onChange={(d) => setDate(d, "end_time")}
                    style={dateStyle}
                    placeholder={"End date"}
                    /* disabled={disableFields} */
                    format={"MMM DD[,] YYYY"}
                    defaultValue={moment
                      .utc(eventInfo.end_time, dateTimeFormat)
                      .local()}
                    disabled={true}
                  />

                  <TimePicker
                    style={timeStyle}
                    format="h:mm A"
                    onChange={(d) => setTime(d, "end_time")}
                    suffixIcon={<span />}
                    disabled={true}
                    defaultValue={moment
                      .utc(eventInfo.end_time, dateTimeFormat)
                      .local()}
                  />
                </div>
              </FormField>
              {/* <SelectDropdown
              label="Timezone"
              options={timeZoneOptions}
              disabled={disableFields}
              style={selectWidth}
            /> */}
            </Space>
          </Form>
        </Wrapper>
      </RightContainer>
    </EventInfoContainer>
  );
};
