import Layout from "antd/lib/layout/layout";
import React from "react";
import {  EventLayoutContent } from "./style";

export const EventLayout = ({
  leftMenuElement,
  children,
  space,
}) => {
  return (
    <Layout>
      {leftMenuElement}
      <EventLayoutContent space={space}>
       {children}
      </EventLayoutContent>
    </Layout>
  );
};
