import { Space } from "antd";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Event } from "./components/event/Event";
import { UpcomingEventHeader } from "./components/upcoming-header/UpcomingEventHeader";
import { UpcomingEventContainer } from "./style";

export const Upcomingevent = ({ eventList }) => {
  const history = useHistory();
  return (
    <Fragment>
      <UpcomingEventHeader title="Upcoming Events" />

      <UpcomingEventContainer>
        <Space direction="horizontal" wrap={true}>
          <Event history={history} />
          <Event isLive={true} history={history} />
        </Space>
      </UpcomingEventContainer>
    </Fragment>
  );
};
