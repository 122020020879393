import { notification } from "antd";

export const notifySuccess = (message, description = "") => {
  notification.success({
    message: message,
    description: description,
  });
};

export const notifyError = (message, errorMessage = "") => {
  notification.error({
    message: message,
    description: errorMessage,
  });
};

export const notifyProgress = (message, description = "") => {
  notification.info({
    message: message,
    description: description,
  });
};
