import { Button } from "antd";
import styled from "styled-components";

export const AddButtonContainer = styled(Button)`
  width: ${({ width }) => width || "180px"};
  font-weight: bold;
  color: ${({ theme }) => theme.color.textOnSurface};
  background-color: ${({ theme }) => theme.color.accent};
  border: 1px solid transparent;
  font-size: ${({ fontSize }) => fontSize || "14px"};
`;
