import { Menu } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "styled-components";

export const LayoutSider = styled(Sider)`
  background: ${({ theme }) => theme.color.surface};
  min-width: 170px;
  padding: 38px 0;
  .ant-menu {
    background: ${({ theme }) => theme.color.transparent};
    border: none;
    color: ${({ theme }) => theme.color.textOnSurface};
    .ant-menu-submenu {
      margin-bottom: 38px;
      :last-child {
        margin-bottom: 0px;
      }
    }
    .ant-menu-item-active {
      color: ${({ theme }) => theme.color.textOnSurface};
    }
  }

  .ant-menu-submenu-title {
    font-size: 12px;
    line-height: 140%;
    font-weight: bold;
    color: ${({ theme }) => theme.color.textOnSurface};
    padding: 0 !important;
    padding-bottom: 8px;
    height: 30px !important;
    margin-bottom: 8px !important;
    :hover {
      opacity: 0.6;
    }
    :active {
      background: ${({ theme }) => theme.color.transparent};
    }
  }

  .sub-menu {
    &.ant-menu-submenu {
      margin-bottom: 8px;
    }
    .ant-menu-submenu-title {
      border: none;
      color: ${({ theme }) => theme.color.textOnSurface};
      font-size: 10px;
      line-height: 140% !important;
      height: 20px !important;
      padding-left: 28px !important;
      margin-bottom: 0px !important;
      opacity: 0.8;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      margin: 0;
      width: 100%;
    }

    .ant-menu-item {
      padding-left: 42px !important;
      font-weight: bold;
      font-size: 10px;
      line-height: 140% !important;
      height: 20px !important;
      margin-bottom: 2px !important;
      display: flex;
      align-items: center;

      :before {
        content: "-";
        position: absolute;
        left: 34px;
      }
      :after {
        border: none;
      }
      :active {
        background: ${({ theme }) => theme.color.transparent};
      }
      &.ant-menu-item-selected {
        background: ${({ theme }) => theme.color.transparent};
        color: ${({ theme }) => theme.color.textOnSurface};
      }
      :hover {
        opacity: 0.6;
        color: ${({ theme }) => theme.color.textOnSurface};
      }
    }
  }
`;

export const VmeetContainer = styled(Content)`
  overflow: hidden;
`;

export const SubMenuItem = styled(SubMenu)`
  &.active {
    .ant-menu-submenu-title {
      background: ${({ theme }) => theme.color.text} !important;
      color: ${({ theme }) => theme.color.textOnSurface};
    }
  }
`;
export const MenuItem = styled(Menu.Item)`
  &.active {
    background: ${({ theme }) => theme.color.text} !important;
    color: ${({ theme }) => theme.color.textOnSurface};
  }
`;

export const MenuTitle = styled.div`
  font-size: 12px;
  line-height: 140%;
  font-weight: bold;
  height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 99%;
  padding: 0 18px 0px 22px;
  height: 20px;

  &.active {
    background: ${({ theme }) => theme.color.text} !important;
    color: ${({ theme }) => theme.color.textOnSurface};
  }
  :after {
    content: "";
    width: calc(100% - 40px);
    height: 1px;

    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.grey98};
  }
`;
