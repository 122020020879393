import styled from "styled-components";
import { layoutRightSpace } from "../../styles/theme";

export const HeaderContainer = styled.div`
  > div {
    border-style: solid;
    border-width: 0;
    border-bottom-width: 3px;
    border-color: ${({ theme }) => theme.color.grey91};
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    padding-bottom: 17px;
    padding-left: 6px;
    height: 78px;
    margin-right: ${layoutRightSpace};
    margin-left: 15px;
  }
  border-width: 0;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.color.textOnSurface};
  z-index: ${({ theme }) => theme.zIndex.index_1};
  b {
    font-size: 18px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.black_2};
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
