import React from "react";
import { HeaderContainer } from "./style";

export const Header = ({ title = "Upcoming Events", children }) => (
  <HeaderContainer>
    <div>
      <b>{title}</b>
      {children}
    </div>
  </HeaderContainer>
);
