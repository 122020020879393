import React from "react";
import { ChairIcon } from "../../constant/iconConstant";
import { Popconfirm, Button } from "antd";

import {
  ChairWrapper,
  EditArrow,
  HomeLoungeWrapper,
  SessionDelete,
} from "./style";

const oneChairTableWidth = 50; //80px;
const oneChairWidth = 58;

export const SessionTable = ({
  tableName = "Table",
  disabled = false,
  tableCapacity,
  session_id,
  deleteSession,
  keyId,
}) => {
  const verticalChairCount = Math.round((tableCapacity - 2) / 2); // table vertical top chair count
  let percentage = Math.round(oneChairTableWidth / tableCapacity - 2);

  const [showDeletePopover, setShowDeletePopover] = React.useState(false);

  const showPopconfirm = () => {
    setShowDeletePopover(true);
  };

  const handleOk = () => {
    deleteSession(session_id);
    setShowDeletePopover(false);
  };

  const handleCancel = () => {
    setShowDeletePopover(false);
  };

  return (
    <HomeLoungeWrapper
      disabled={disabled}
      extraChairWidth={
        verticalChairCount > 1 ? oneChairWidth * verticalChairCount : 0
      }
      tableWidth={
        verticalChairCount > 1 ? oneChairTableWidth * verticalChairCount : 0
      }
    >
      <div className="tab-wrapper">
        <div className="card">
          <p className="heading-txt">{tableName}</p>

          <div className="discussion-table-box">
            <div>
              <div className="discussion-table-wrapper session-table">
                {Array.apply(null, { length: tableCapacity }).map(
                  (e, index) => {
                    if (
                      index > 1 &&
                      index !== tableCapacity - 1 &&
                      (index + 1) % 2 === 0
                    ) {
                      percentage = Math.floor(
                        percentage + 80 / verticalChairCount
                      );
                    }
                    return (
                      <ChairWrapper
                        className={
                          index
                            ? index === tableCapacity - 1
                              ? "last-chair"
                              : ""
                            : "first-chair"
                        }
                        left={
                          index && index !== tableCapacity - 1 ? percentage : 0
                        }
                      >
                        <ChairIcon />
                      </ChairWrapper>
                    );
                  }
                )}
                <div className="discussion-table">Table - {keyId + 1}</div>
              </div>
            </div>
          </div>
          <div className="footer">
            <span>
              {/* <span className="edit">Edit</span> <EditArrow /> */}
            </span>
            <Popconfirm
              title="Delete this lounge?"
              visible={showDeletePopover}
              onConfirm={handleOk}
              onCancel={handleCancel}
            >
              <SessionDelete
                onClick={showPopconfirm}
                /* onClick={() => {
                if (window.confirm("Are you sure you want to remove?")) {
                  deleteSession(session_id);
                }
              }} */
              />
            </Popconfirm>
          </div>
        </div>
      </div>
    </HomeLoungeWrapper>
  );
};
