import { CaretDownOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Header } from "../../../../../common-components/header/Header";
import { InputSearch } from "../../../../../common-components/input-search/InputSearch";
import theme from "../../../../../styles/theme";
import { NewEventButton } from "./style";

export const UpcomingEventHeader = ({
  title = "",
  onSearch = (va) => {
    console.log(va);
  },
}) => {
  const history = useHistory();
  return (
    <Header title={title} onSearch={onSearch}>
      <InputSearch onSearch={() => null} />
      <NewEventButton onClick={() => history.push("/vmeet")}>
        New Event{" "}
        <span>
          <Divider
            type="vertical"
            style={{
              margin: 0,
              display: "inline-block",
              borderColor: theme.color.textOnSurface,
              marginRight: "14px",
            }}
          />{" "}
          <CaretDownOutlined />
        </span>
      </NewEventButton>
    </Header>
  );
};
