import { Button, Row } from "antd";
import React, { Fragment } from "react";
import { DragAndDrop } from "../../../../../common-components/drag-and-drop/DragAndDrop";
import theme from "../../../../../styles/theme";
const buttonStyle = {
  border: `1px solid ${theme.color.surfaceLighter_1}`,
  marginTop: "9px",
  color: theme.color.text,
};

export const BulkBlockContent = () => {
  return (
    <Fragment>
      <DragAndDrop boxHeight={"200px"} />
      <Row justify="end">
        <Button style={buttonStyle}>Download Blank vMeets List File</Button>
      </Row>
    </Fragment>
  );
};
