import styled, { css } from "styled-components";

export const NotificationCardContainer = styled.div`
  box-shadow: ${({ theme, completed }) =>
    theme.color[completed ? "shadow" : "shadowElevated"]};
  background-color: ${({ theme, completed }) =>
    theme.color[completed ? "grey88" : "textOnSurface"]};
  padding: 15px 10px 15px 10px;
  width: 219px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  width: ${({ width }) => width || "100%"};
  color: ${({ theme }) => theme.color.surfaceLighter_1};
  display: inline-block;
  padding-bottom: 4px;
  padding-right: ${({ rightPadding }) => rightPadding || "32px"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${({ theme }) => theme.color.dimGrey_4};
  margin-bottom: 6px;
`;

export const CardDescription = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineCount }) => lineCount || 2};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 140%;
  color: ${({ theme }) => theme.color.black_6};
  margin-bottom: 15px;
  ${({ lineCount }) =>
    lineCount == 2 &&
    css`
      height: 38px;
    `}
`;

export const ScheduleText = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  margin: 0;
  color: ${({ theme }) => theme.color.surface};
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  align-items: flex-end;
  span {
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.025em;
    color: ${({ theme }) => theme.color.surface};
    text-decoration: underline;
    cursor: pointer;
  }
  .anticon-right {
    font-size: 10px;
    top: 1px;
    position: relative;
  }
  .anticon-plus-square,
  .anticon-delete {
    font-size: 14px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction || "row"};
  ${({ flex }) =>
    flex &&
    css`
      flex: 1;
    `}
`;
