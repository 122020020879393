import styled from "styled-components";

export const NotificationSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const NotificationSectionContainer = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || "59px"};
`;
