import { Input, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Fragment, useState } from "react";
import { ReactImageCropper } from "../../../../../common-components/image-cropper/react-image-cropper/ReactImageCropper";
import theme from "../../../../../styles/theme";
import {
  maskStyle,
  profileUploadBodyStyle,
  ProfileUploadContent,
  ProfileUploadTitle,
  SmallText,
  Text,
  UploadModalButton,
} from "./style";

export const ProfileUploadModal = ({
  isModalVisible,
  handleCancel,
  userId,
  uploadProfileImage,
}) => {
  const [inputImg, setInputImg] = useState(null);
  const [inputLink, setInputLink] = useState();
  const onFileChange = (fileData) => {
    // convert image file to base64 string
    const file = fileData.file;
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const onChange = (e) => {
    setInputLink(e.target.value);
  };
  return (
    <Modal
      title=""
      visible={isModalVisible}
      centered
      width="585px"
      footer={null}
      bodyStyle={profileUploadBodyStyle}
      maskClosable={true}
      onCancel={handleCancel}
      maskStyle={maskStyle}
    >
      <ProfileUploadContent imagePresent={!!inputImg}>
        {inputImg ? (
          <Fragment>
            <ProfileUploadTitle>Crop picture</ProfileUploadTitle>
            <ReactImageCropper image={inputImg} />
          </Fragment>
        ) : (
          <Fragment>
            {/* <Upload.Dragger
              showUploadList={false}
              accept=".jpeg,.jpg,.png"
              customRequest={onFileChange}
            >
              <ProfileUploadTitle underline={true}>
                Browse files on you computer
              </ProfileUploadTitle>
            </Upload.Dragger>
            <SmallText>or</SmallText> */}
            <Text>paste a social media profile link below</Text>
            <Input
              placeholder="http://www.linkedin.com/example"
              size="small"
              style={{ width: "352px" }}
              disabled={false}
              name="file"
              onChange={onChange}
              value={inputLink}
            />
          </Fragment>
        )}

        <UploadModalButton
          onClick={() => {
            uploadProfileImage(inputLink, userId);
          }}
        >
          Update
        </UploadModalButton>
      </ProfileUploadContent>
    </Modal>
  );
};
