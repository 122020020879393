import { DatePicker, Input, message, Space, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ActionDrawer } from "../../../../../common-components/action-drawer/ActionDrawer";
import { DateAndTime } from "../../../../../common-components/date-and-time/DateAndTime";
import { FormField } from "../../../../../common-components/form-field/FormField";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../../../common-components/notification/Notification";
import { RichTextEditor } from "../../../../../common-components/rich-text-editor/RichTextEditor";
import CKEditor from "ckeditor4-react";
import { SectionTitle } from "../../../../../common-components/section-title/SectionTitle";
import { SelectDropdown } from "../../../../../common-components/select-dropdown/SelectDropdown";
import { SwitchBox } from "../../../../../common-components/switch-box/SwitchBox";
import { dateTimeFormat } from "../../../../../constant/constant";
import { vMeetHttp } from "../../../../../http/axiosInstances";
import theme from "../../../../../styles/theme";
import {
  HeaderWrapper,
  NotificationToggleWrapper,
  PreviewButton,
  ScheduleToggleWrapper,
  ErrorMessage,
  NotificationTextArea,
  HintText,
} from "./style";
import { EditorPreview } from "./EditorPreview";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import BasicModal from "../../../../../common-components/modal/basicModal";

const { Option } = Select;
const timeStyle = { width: "105px", marginLeft: "10px" };
const targetOptions = [
  { lable: "All", value: 1 },
  { lable: "Moderator", value: 2 },
  { lable: "Speaker", value: 3 },
  { lable: "Attendee", value: 4 },
];
var selectedTarget = null;
export const NewNotificationDrawer = ({
  visible = false,
  onClose,
  session_id,
  notificationMode,
  currentNotification,
  loadNotification,
}) => {
  const [notificationType, setNotificationType] = useState("");
  const [scheduleTime, setScheduleTime] = useState(true);
  const [enableEdit, setEnableEdit] = useState(false);
  const [isBasicModalVisible, setIsBasicModalVisible] = useState(false);
  const [showEditor, setShowEditor] = useState(true);
  const [notificationRequest, setNotificationRequest] = useState({});
  const [previewRequest, setPreviewRequest] = useState({
    email: "",
    first_name: "",
  });
  const [error, setError] = useState("");
  const { id } = useParams();

  useEffect(() => {
    if (notificationMode == "edit") {
      setNotificationRequest({
        type: currentNotification.type,
        name: currentNotification.name,
        title: currentNotification.title,
        message: currentNotification.message,
        schedule_time: currentNotification.schedule_time,
        session_id: currentNotification.session_id,
        target_id: currentNotification.target_id,
      });
      var result = targetOptions.find((obj) => {
        return obj.value === currentNotification.target_id;
      });
      selectedTarget = result.lable;
      console.log(selectedTarget, currentNotification.target_id);
      setShowEditor(false);
      setTimeout(() => {
        setShowEditor(true);
      }, 1);
    } else {
      setNotificationRequest({
        type: "email",
        name: "",
        title: "",
        message: "",
        schedule_time: "",
        session_id: session_id,
        target_id: 4,
      });
    }
  }, [notificationMode]);
  const changeOption = (e) => {
    setNotificationType(e);
    let responseType = e;
    if (responseType === "web") {
      setEnableEdit(true);
      selectedTarget = "All";
      setNotificationRequest({ ...notificationRequest, target_id: 1 });
    } else {
      setEnableEdit(false);
      selectedTarget = null;
    }
    setNotificationRequest({ ...notificationRequest, ["type"]: responseType });
  };
  const onChange = (e) => {
    setError("");
    setNotificationRequest({
      ...notificationRequest,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePreview = (e) => {
    setError("");
    if (e.target.name === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        setError("Enter Valid Email");
      }
    } else if (e.target.name === "first_name") {
      if (e.target.value.length <= 2) {
        setError("First name should have atleast 3 letters");
      }
    }
    setPreviewRequest({
      ...previewRequest,
      [e.target.name]: e.target.value,
    });
  };

  const changeSubject = (e) => {
    console.log(e);
    var ctl = document.getElementById("subject_input");
    var startPos = ctl.selectionStart;
    var endPos = ctl.selectionEnd;
    console.log(startPos + ", " + endPos);
    let subject = notificationRequest.title;
    console.log(subject.slice(0, startPos) + e + subject.slice(startPos));
    setNotificationRequest({
      ...notificationRequest,
      ["title"]: subject.slice(0, startPos) + e + subject.slice(startPos),
    });
  };

  const changeMessage = (e) => {
    setError("");
    editor.current = e.editor;
    setNotificationRequest({
      ...notificationRequest,
      ["message"]: e.editor.getData(),
    });
  };

  const changeBody = (e) => {
    setNotificationRequest({
      ...notificationRequest,
      ["message"]: e.target.value,
    });
  };

  const editor = useRef();
  const getTextFromHeadToCaret = () => {
    var textEditor = editor.current;
    var range = textEditor.getSelection().getRanges()[0];
    var selection = range.clone();
    selection.selectNodeContents(range.startContainer);
    return selection.toString().substring(0, range.startOffset);
  };

  const changeMessagePersionaliseold = (e) => {
    for (var i in window.CKEDITOR.instances) {
      console.log(window.CKEDITOR.instances[i]);
    }
    var oEditor = window.CKEDITOR.instances["message"];

    var newElement = window.CKEDITOR.dom.element.createFromHtml(e);
    oEditor.insertElement(newElement);
  };
  const changeMessagePersionalise = (e) => {
    // console.log(e);
    // var textEditor = window.CKEDITOR.instances["message"];
    // let sel = textEditor.getSelection();
    // let element = sel.getStartElement();
    // let range = textEditor.getSelection().getRanges()[0];
    // if (!range) {
    //   range = textEditor.createRange();
    //   range.selectNodeContents( textEditor.editable() );
    //   sel.selectRanges( [ range ] );
    // }
    // var selection = range.clone();
    // selection.selectNodeContents(range.startContainer);
    // console.log(selection);
    // var content = selection.toString();
    // var post = content.substring(range.startOffset);
    // console.log(post);
    // var pre = getTextFromHeadToCaret();
    // console.log(pre);
    // range.selectNodeContents(range.startContainer);
    // range.deleteContents();
    // var node = document.createTextNode(pre + post);
    // range.insertNode(node, pre.length);
    // range.setStart(node, pre.length);
    // range.collapse(true);
    // sel.removeAllRanges();
    // sel.addRange(range);

    // console.log(range);
    // let startPos = range.startOffset;
    // let message = notificationRequest.message;
    // console.log(message.slice(0, startPos) + e + message.slice(startPos));
    // setNotificationRequest({
    //   ...notificationRequest,
    //   ["message"]: message.slice(0, startPos) + e + message.slice(startPos),
    // });

    // var Sel = ctl.selection.createRange();

    // Sel.moveStart("character", -e.value.length);

    // let CaretPos = Sel.text.length;
    // console.log(CaretPos);

    // var startPos = ctl.selectionStart;
    let body = notificationRequest.message;
    setNotificationRequest({
      ...notificationRequest,
      ["message"]: body + e,
    });
  };

  // const changeMessagePersionalise = (e) => {

  //   setNotificationRequest({
  //     ...notificationRequest,
  //     ["message"]: notificationRequest.message + e ,
  //   });
  // };
  const changeTarget = (e) => {
    setNotificationRequest({ ...notificationRequest, target_id: e });
  };

  const validateWeb = () => {
    var str = JSON.stringify(notificationRequest);
    if (str.indexOf("{{") !== -1) return true;
    else return false;
  };
  const saveNotification = () => {
    if (notificationRequest.type == "web" && validateWeb()) {
      setError("Personization won't work in web, remove {{...}} tags");
      return;
    }
    notifyProgress("Changes are being saved...");
    const notificationCreateURL = `event/${id}/notification`;
    vMeetHttp
      .post(notificationCreateURL, notificationRequest)
      .then((response) => {
        onClose();
        loadNotification(session_id);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const editNotification = () => {
    notifyProgress("Changes are being saved...");
    const notificationCreateURL = `event/${id}/notification/${currentNotification.id}`;
    vMeetHttp
      .put(notificationCreateURL, notificationRequest)
      .then((response) => {
        onClose();
        loadNotification(session_id);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };

  const setDate = (selectedDate, key) => {
    let newDate = notificationRequest[key]
      ? moment.utc(notificationRequest[key]).local()
      : moment();
    let date = moment(selectedDate).date();
    let month = moment(selectedDate).month();
    let year = moment(selectedDate).year();

    newDate.set({ date, month, year });
    setNotificationRequest({
      ...notificationRequest,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };

  const setTime = (selectedDate, key) => {
    let newDate = notificationRequest[key]
      ? moment.utc(notificationRequest[key]).local()
      : moment();

    let hour = moment(selectedDate).hour();
    let minute = moment(selectedDate).minute();
    let second = moment(selectedDate).second();

    newDate.set({ hour, minute, second });
    setNotificationRequest({
      ...notificationRequest,
      [key]: newDate.utc().format(dateTimeFormat),
    });
  };
  const setSendNow = () => {
    setScheduleTime(!scheduleTime);
    setNotificationRequest({
      ...notificationRequest,
      ["schedule_time"]: moment.utc().format(dateTimeFormat),
    });
  };

  const personalizationOptions = (target) => {
    return [
      { id: "{{first_name}}", value: "Contact first name" },
      { id: "{{last_name}}", value: "Contact last name" },
      { id: "{{full_name}}", value: "Contact full name" },
      { id: "{{designation}}", value: "Contact designation" },
      { id: "{{email}}", value: "Contact email" },
      { id: "{{event_name}}", value: "Event name" },
      { id: "{{event_start_date}}", value: "Event start date" },
      { id: "{{event_start_time}}", value: "Event start date time" },
      {
        id: "{{session_name}}",
        value: "Session name",
        disabled: session_id ? false : true,
      },
      {
        id: "{{session_start_date}}",
        value: "Session start date",
        disabled: session_id ? false : true,
      },
      {
        id: "{{session_start_time}}",
        value: "Session start date time",
        disabled: session_id ? false : true,
      },
      {
        id:
          "<table><thead>  <tr> <th>Speakers List</th> </tr> </thead> <tbody> <tr> <td>{{speaker_list}}</td> </tr> </tbody> </table>",
        value: "Speaker list",
        disabled: target !== "message" ? true : false,
      },
    ];
  };
  const sendPreviewEmail = () => {
    const notificationPreviewURL = `event/${id}/notification/previewEmail`;
    let errorStatus = false;
    if (notificationRequest.title.length < 1) {
      setError("Email subject cannot be empty");
      errorStatus = true;
    } else if (notificationRequest.message.length < 1) {
      setError("Email body cannot be empty");
      errorStatus = true;
    } else if (previewRequest.email.length < 1) {
      setError("Email cannot be empty");
      errorStatus = true;
    } else if (previewRequest.first_name.length < 1) {
      setError("First name cannot be empty");
      errorStatus = true;
    }
    if (errorStatus) return;
    notifyProgress("Changes are being saved...");
    let requestBody = {
      email: previewRequest.email,
      first_name: previewRequest.first_name,
      title: notificationRequest.title,
      message: notificationRequest.message,
      session_id: notificationRequest.session_id,
    };
    vMeetHttp
      .post(notificationPreviewURL, requestBody)
      .then((response) => {
        setPreviewRequest({});
        notifySuccess("Preview email sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error in sending Preview email");
      });
  };
  return (
    <ActionDrawer
      width="856"
      visible={visible}
      bodyStyle={{ padding: "22px" }}
      onClose={onClose}
      leftButtonText="Cancel"
      leftButtonAction={onClose}
      rightButtonText="Save"
      rightButtonAction={
        notificationMode == "create" ? saveNotification : editNotification
      }
      buttonStyle={{
        buttonBaseColor: theme.color.grey27,
        actionButtonWidth: "84px",
      }}
    >
      <HeaderWrapper>
        <SectionTitle
          title={
            notificationMode == "create"
              ? "New Notification"
              : "Edit Notification"
          }
        />
      </HeaderWrapper>

      <Space size={[19]} direction="vertical" className="flex">
        <FormField label="Notification Name">
          <Input
            placeholder="Template Name"
            disabled={false}
            onChange={onChange}
            name="name"
            value={notificationRequest.name}
            style={{ width: "50%" }}
          />
        </FormField>
        <FormField label="Type">
          {console.log(notificationRequest.type)}
          <Select
            suffixIcon={<CaretDownOutlined />}
            disabled={false}
            placeholder="-Type-"
            onChange={changeOption}
            style={{
              width: "20%",
              marginLeft: "80px",
              marginTop: "-25px",
              display: "flex",
            }}
            value={notificationRequest.type ? notificationRequest.type : ""}
            //defaultValue={notificationRequest.type ? notificationRequest.type : ""}
          >
            <Option value="email">Email</Option>
            <Option value="sms">SMS</Option>
            <Option value="web">Web</Option>
          </Select>
        </FormField>
        <FormField label={notificationType == "web" ? "Title" : "Subject"}>
          <Input
            placeholder={
              notificationType == "web" ? "Title of Notification" : "Subject"
            }
            id="subject_input"
            size="small"
            disabled={false}
            onChange={onChange}
            name="title"
            value={notificationRequest.title}
            style={{ width: "40%" }}
          />
          {notificationType == "email" && (
            <Select
              suffixIcon={<CaretDownOutlined />}
              disabled={false}
              placeholder="-Personalization-"
              defaultValue={null}
              onChange={changeSubject}
              style={{ width: "20%" }}
            >
              {personalizationOptions("title").map((e, i) => (
                <Option value={e.id} disabled={e.disabled}>
                  {e.value}
                </Option>
              ))}
            </Select>
          )}
        </FormField>
        <FormField
          label={notificationType == "web" ? "Notification Message" : "Body"}
        >
          {/* <Input.TextArea
            placeholder="Type Message"
            size="small"
            autoSize={{
              minRows: 5,
            }}
            style={{ width: "80%", paddingTop: "5px" }}
            disabled={false}
            onChange={onChange}
            name="message"
            value={notificationRequest.message}
          /> */}
          {notificationType == "email" && (
            <Select
              suffixIcon={<CaretDownOutlined />}
              disabled={false}
              placeholder="-Personalization-"
              onChange={changeMessagePersionalise}
              style={{
                width: "20%",
                marginLeft: "80px",
                marginTop: "-25px",
                display: "flex",
              }}
            >
              {personalizationOptions("message").map((e, i) => (
                <Option value={e.id} disabled={e.disabled}>
                  {e.value}
                </Option>
              ))}
            </Select>
          )}
          {showEditor && notificationType != "sms" ? (
            <CKEditor
              id="body_textbox"
              activeClass="editor"
              editor="ClassicEditor"
              placeholder="Type Message"
              data={notificationRequest.message}
              onChange={changeMessage}
              name="message"
              onInit={(editor) => {
                console.log("Editor is ready to use!", editor);
              }}
            />
          ) : (
            <>
              <NotificationTextArea
                placeholder="Type Message"
                onChange={changeBody}
                size="small"
                autoSize={{
                  minRows: 5,
                }}
                disabled={false}
              />
              <HintText>{notificationRequest.message.length} char </HintText>
            </>
          )}
        </FormField>

        <SelectDropdown
          label="Target type"
          options={targetOptions}
          disabled={enableEdit}
          value={selectedTarget}
          onChange={changeTarget}
          mode="notification"
          style={{ width: "50%" }}
        />
        <ScheduleToggleWrapper scheduleTime={scheduleTime}>
          <SwitchBox
            label="Schedule later"
            rightLabel="Send now"
            onChange={() => setSendNow()}
          />
        </ScheduleToggleWrapper>
        {scheduleTime && (
          <FormField label="Schedule Time">
            <div>
              <DatePicker
                onChange={(d) => setDate(d, "schedule_time")}
                placeholder={"Schedule Time"}
                /* disabled={disableFields} */
                format={"MMM DD[,] YYYY"}
                value={
                  notificationRequest.schedule_time
                    ? moment
                        .utc(notificationRequest.schedule_time, dateTimeFormat)
                        .local()
                    : moment.utc().local()
                }
                disabled={false}
              />

              <TimePicker
                style={timeStyle}
                format="h:mm A"
                onChange={(d) => setTime(d, "schedule_time")}
                suffixIcon={<span />}
                disabled={false}
                value={
                  notificationRequest.schedule_time
                    ? moment
                        .utc(notificationRequest.schedule_time, dateTimeFormat)
                        .local()
                    : moment.utc().local()
                }
              />
            </div>
          </FormField>
        )}
        {notificationType == "email" ? (
          <FormField label="Preview Email">
            <Input
              placeholder="Email"
              style={{ width: "30%" }}
              name="email"
              onChange={onChangePreview}
              value={previewRequest.email}
            />
            <Input
              placeholder="First name"
              style={{ width: "30%", marginLeft: "10px" }}
              name="first_name"
              onChange={onChangePreview}
              value={previewRequest.first_name}
              minLength="3"
              maxLength="25"
            />
            <PreviewButton
              disabled={error != "" ? true : false}
              onClick={sendPreviewEmail}
              style={{ marginLeft: "15px" }}
            >
              Preview
            </PreviewButton>
          </FormField>
        ) : notificationType == "web" ? (
          <FormField label="Preview Web Notification">
            <PreviewButton
              disabled={error != "" ? true : false}
              onClick={() => setIsBasicModalVisible(true)}
            >
              Preview
            </PreviewButton>
          </FormField>
        ) : null}
        <ErrorMessage>{error}</ErrorMessage>
      </Space>
      {isBasicModalVisible && (
        <BasicModal
          title={notificationRequest.title}
          message={notificationRequest.message}
          isBasicModalVisible={isBasicModalVisible}
          handleCancel={() => setIsBasicModalVisible(false)}
        />
      )}
    </ActionDrawer>
  );
};
