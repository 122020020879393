import Form from "antd/lib/form/Form";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const LoginFormInner = styled(Form)`
  height: auto;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const LoaderWrapper = styled.div`
  height: auto;
  width: 40%;
  min-height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;
