import { Input } from "antd";
import styled from "styled-components";

export const OptionTextArea = styled(Input.TextArea)`
  padding: 10px 10px 10px 37px;
  position: relative;
  width: 100%;
`;

export const OptionWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  .option-icon {
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: ${({ theme }) => theme.zIndex.index_1};
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
    .ant-radio {
      &:hover .ant-radio-inner {
        border-color: ${({ theme }) => theme.color.grey27};
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: ${({ theme }) => theme.color.grey27};
          :after {
            background-color: ${({ theme }) => theme.color.black_2};
          }
        }
      }
    }
    .ant-checkbox {
      &:hover .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.color.grey27};
      }
      &.ant-checkbox-checked .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.color.grey27};
        background-color: ${({ theme }) => theme.color.black_2};
      }
    }
  }
`;
