import Sider from "antd/lib/layout/Sider";
import styled from "styled-components";

export const ListRow = styled.div`
  height: 70px;
  display: flex;
  cursor: pointer;
  background: ${({ theme, active }) =>
    theme.color[active ? "textActive" : "surface"]};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey71};
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    color: ${({ theme }) => theme.color.textOnSurface};
  }
  > div:first-child {
    width: 82px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    border-right: 1px solid ${({ theme }) => theme.color.grey71};
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 138px;
    span:first-child {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      &.disabled {
        opacity: 0.4;
      }
    }
    span:last-child {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
