import { Content } from "antd/lib/layout/layout";
import styled from "styled-components";
import { layoutRightSpace } from "../../styles/theme";


export const EventLayoutContent = styled(Content)`
    overflow-y: scroll;
    overflow-x: hidden;
    height:calc(100vh - 142px);
    padding-right: ${layoutRightSpace};
    padding-left: ${({space}) => space||0};
`;