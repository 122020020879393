import styled, { css } from "styled-components";
import { Button, Input } from "antd";

export const HeaderWrapper = styled.div`
    margin: 20px 0 29px;
`;

export const NotificationToggleWrapper = styled.div`
  width: 33%;
  > div {
    > span {
    font-weight: 600;
    :first-child {
      ${({ multiChoice }) =>
        multiChoice &&
        css`
          opacity: 0.5;
        `}
    }
    :last-child {
      ${({ multiChoice }) =>
        !multiChoice &&
        css`
          opacity: 0.5;
        `}
    }
  }
  }
 
`;

export const ScheduleToggleWrapper = styled.div`
  width: 30%;
  > div {
    > span {
    font-weight: 600;
    :first-child {
      ${({ scheduleTime }) =>
      !scheduleTime &&
        css`
          opacity: 0.5;
        `}
    }
    :last-child {
      ${({ scheduleTime }) =>
        scheduleTime &&
        css`
          opacity: 0.5;
        `}
    }
  }
  }
 
`;

export const PreviewButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.grey27};
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.textOnSurface};
  border-radius: 2px;
  width: 84px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  color: #e23a3a;
  font-size: 12px;
`;

export const NotificationTextArea = styled(Input.TextArea)`
  width: 80%;
  padding-top: 5px;
`;

export const HintText = styled.i`
  font-size: 9px;
  line-height: 12px;
  color: ${({ theme }) => theme.color.black_4};
  margin-left: 2px;
`;

