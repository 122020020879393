import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorWrapper } from "./style.js";
import { stateToHTML } from "draft-js-export-html";

export const RichTextEditor = ({
  toolbarOptions,
  inlineOptions,
  onStateChange = () => null,
  name,
  value,
  disabled,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (value != null) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(value))
        )
      );
    } else setEditorState(EditorState.createEmpty());
  }, []);
  const editorOnChange = (state) => {
    setEditorState(state);
    onStateChange(stateToHTML(editorState.getCurrentContent()));
  };
  return (
    <EditorWrapper disabled={disabled}>
      <Editor
        editorState={editorState}
        onEditorStateChange={editorOnChange}
        editorClassName={"editor-main"}
        toolbar={{
          options: toolbarOptions
            ? toolbarOptions
            : [
                "inline",
                "blockType",
                "fontSize",
                "list",
                "textAlign",
                "colorPicker",
                "link",
              ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: inlineOptions
              ? inlineOptions
              : ["bold", "italic", "underline", "strikethrough"],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["unordered", "ordered"],
          },
          textAlign: { inDropdown: false },
          link: { inDropdown: false, className: "link-color" },
          value: { value },
        }}
      />
    </EditorWrapper>
  );
};
