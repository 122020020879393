import { PlusCircleOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { DayListTable } from "../../../common-components/day-list-table/DayListTable";
import { FormField } from "../../../common-components/form-field/FormField";
import theme from "../../../styles/theme";
import { ScheduleDescription } from "./component/schedule-description/ScheduleDescription";
import {
  ScheduleTable,
  AddDay,
  Row,
  ScheduleDateWrapper,
  dateStyle,
  plusCircleStyle,
} from "./style";
import { EventLayout } from "../../../common-components/event-layout/EventLayout";

import { useParams } from "react-router";
import moment from "moment";
import { vMeetHttp } from "../../../http/axiosInstances";
import Loader from "../../../common-components/loader";
import { dateFormat } from "../../../constant/constant";

const colors = [theme.color.blue_1, theme.color.mintCream, theme.color.pink_1];

const columns = [
  {
    title: "Time",
    dataIndex: "time",
    width: 160,
    align: "center",
  },
  {
    title: "Event",
    dataIndex: "event",
    render: (event) => (
      <Row>
        <div>
          {event.map((eventData, index) => (
            <ScheduleDescription bgColor={colors[index % 3]} {...eventData} />
          ))}
        </div>
        <div>
          {/*  <PlusCircleOutlined style={plusCircleStyle} onClick={() => null} /> */}
        </div>
      </Row>
    ),
  },
];

const hourData = [
  { hour: 0, time: "12AM", event: [] },
  { hour: 1, time: "1AM", event: [] },
  { hour: 2, time: "2AM", event: [] },
  { hour: 3, time: "3AM", event: [] },
  { hour: 4, time: "4AM", event: [] },
  { hour: 5, time: "5AM", event: [] },
  { hour: 6, time: "6AM", event: [] },
  { hour: 7, time: "7AM", event: [] },
  { hour: 8, time: "8AM", event: [] },
  { hour: 9, time: "9AM", event: [] },
  { hour: 10, time: "10AM", event: [] },
  { hour: 11, time: "11AM", event: [] },
  { hour: 12, time: "12PM", event: [] },
  { hour: 13, time: "1PM", event: [] },
  { hour: 14, time: "2PM", event: [] },
  { hour: 15, time: "3PM", event: [] },
  { hour: 16, time: "4PM", event: [] },
  { hour: 17, time: "5PM", event: [] },
  { hour: 18, time: "6PM", event: [] },
  { hour: 19, time: "7PM", event: [] },
  { hour: 20, time: "8PM", event: [] },
  { hour: 21, time: "9PM", event: [] },
  { hour: 22, time: "10PM", event: [] },
  { hour: 23, time: "11PM", event: [] },
];
export const Schedule = () => {
  const [dayList, setDayList] = useState([]);
  const [hourEventData, setHourEventData] = useState([]);
  const [activeDay, setActiveDay] = useState(null);
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(true);
  const [scrollTo, setScrollTo] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      try {
        console.log("schedule use effect.");
        const response = await vMeetHttp.get(`/event/${id}/session/schedule`);
        setSchedule(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let dl = Object.keys(schedule);

    let dlArray = dl.map((date, index) => {
      if (index == 0) { setActiveDay(date); setSelectedDayIndex(moment(date).format(dateFormat))}
      return {
        day: moment(date).format("ddd"),
        date: moment(date).format("Do MMM"),
        text: schedule[date].length + " Sessions",
        dayCount: index + 1,
        key: date,
      };
    });

    setDayList(dlArray);
  }, [schedule]);

  useEffect(() => {
    //For deepclone. If we use [...hourData], we will have a new array, but the objects in the array
    //will still be referring to the objects in the old array. hence the initial state will be modified.
    let hData = JSON.parse(JSON.stringify(hourData));
    let dayObject = schedule[activeDay];
    console.log(dayObject);
    if (dayObject) {
      dayObject.forEach((session, index) => {
        //Scroll to first session of the day.
        if (index === 0) setScrollTo(session.session_id);

        let hour = moment(session.start_time).hour();
        let minute = moment(session.start_time).minute();

        /* if (minute >= 30) hour += 1; */

        if (Array.isArray(hData[hour].event)) hData[hour].event.push(session);
        else hData[hour].event = [session];
      });
      setHourEventData(hData);
    }
  }, [schedule, activeDay]);

  useEffect(() => {
    console.log(scrollTo);
    let sessionRender = document.getElementById("s_" + scrollTo);

    if (sessionRender) sessionRender.scrollIntoView();
  }, [scrollTo]);

  if (loading)
    return (
      <EventLayout>
        <Loader></Loader>
      </EventLayout>
    );
  const onChangeDay = (key, e) => {
    var scheduleDate = moment(key).format(dateFormat);
    setSelectedDayIndex(scheduleDate);
    setActiveDay(key);
  };
  return (
    <EventLayout
      leftMenuElement={
        <DayListTable
          /* footerContent={<AddDay>+ Add Day</AddDay>} */
          onRowClick={onChangeDay}
          dayList={dayList}
          activeIndex={selectedDayIndex}
        />
      }
      space="18px"
    >
      <ScheduleTable
        columns={columns}
        dataSource={hourEventData}
        pagination={false}
        showHeader={false}
        bordered
      />
    </EventLayout>
  );
};
