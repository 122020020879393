const initialState = {};

function update(target, src) {
  const res = {};
  Object.keys(target).forEach((k) => (res[k] = src[k] ?? target[k]));
  return res;
}

const currentEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_EVENT":
      return action.payload;
    case "UPDATE_CURRENT_EVENT":
      return update(state, action.payload);
    default:
      return state;
  }
};

export default currentEventReducer;
