import { Upload } from "antd";
import React from "react";
import { ChooseFileNote, ChooseFileRow, ChooseFileButton } from "./style";

export const ChooseFile = ({
  noteText = "",
  accept = ".pdf",
  border = "",
  disabled = false,
  buttonTitle,
  onChange,
  maxFiles,
  beforeUpload,
}) => {
  return (
    <ChooseFileRow className="upload-container">
      <Upload
        showUploadList={false}
        accept={accept}
        disabled={disabled}
        onChange={onChange}
        maxCount={maxFiles || 1}
        beforeUpload={beforeUpload}
      >
        <ChooseFileButton border={border} disabled={disabled}>
          {buttonTitle || "Choose File"}
        </ChooseFileButton>
      </Upload>
      <ChooseFileNote>{noteText}</ChooseFileNote>
    </ChooseFileRow>
  );
};
