import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MoreOutlineMenu } from "../../../../../common-components/more-outline-menu/MoreOutlineMenu";
import { DescriptionContainer, Name, ScheduleTitle, Time } from "./style";

export const ScheduleDescription = ({
  title = "Title",
  timeText = "11:00 PM to 12:00 PM UCT +5:30",
  name = "Dr. Jason Roostaeian",
  bgColor = "",
  speaker = {},
  start_time,
  end_time,
  session_id,
}) => {
  const eventInfo = useSelector((state) => state.currentEvent);
  const history = useHistory();
  var sone = moment.tz.guess();
  var timezone = moment.tz(sone).zoneAbbr();
  const onEdit = () =>
    history.push(`/vmeet/events/${eventInfo.event_id}/session?s=${session_id}`);
  return (
    <DescriptionContainer bgColor={bgColor}>
      <div id={"s_" + session_id}>
        <ScheduleTitle>{name}</ScheduleTitle>
        <Time>
          {moment(start_time).local().format("LT") +
            " to " +
            moment(end_time).local().format("LT") +
            " " +
            timezone}
        </Time>
        <Name>
          {Object.values(speaker)
            .map((s) => s.first_name + " " + s.last_name)
            .join(", ")}
        </Name>
      </div>
      {/* 
      TODO: uncomment below when edit and delete fix is done
      <div>
        <MoreOutlineMenu onEdit={onEdit} />
      </div> */}
    </DescriptionContainer>
  );
};
