import { Input, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ActionDrawer } from "../../../../../common-components/action-drawer/ActionDrawer";
import { AddButton } from "../../../../../common-components/add-button/AddButton";
import { FormField } from "../../../../../common-components/form-field/FormField";
import {
  notifyError,
  notifyProgress,
  notifySuccess,
} from "../../../../../common-components/notification/Notification";
import { SectionTitle } from "../../../../../common-components/section-title/SectionTitle";
import { SwitchBox } from "../../../../../common-components/switch-box/SwitchBox";
import { vMeetHttp } from "../../../../../http/axiosInstances";
import theme from "../../../../../styles/theme";
import { PollOption } from "../poll-option/PollOption";
import {
  AddPollHeader,
  PollToggleWrapper,
  NumberOfText,
  PollRow,
  Div,
  pollQuestionStyle,
} from "./style";

export const AddPollDrawer = ({
  visible = false,
  onClose,
  session_id,
  pollMode,
  currentPoll,
  loadPoll,
}) => {
  const [multiChoice, setMultiChoice] = useState(false);
  const [pollOption, setPollOption] = useState([]);
  const [renderOption, setRenderOption] = useState();
  const [pollRequest, setPollRequest] = useState({});

  useEffect(() => {
    return () => {
      setPollOption([]);
      setPollRequest({});
    };
  }, []);
  const onChange = (e) => {
    setPollRequest({ ...pollRequest, [e.target.name]: e.target.value });
    console.log(pollRequest);
  };

  useEffect(() => {
    if (pollMode == "edit") {
      setPollOption([]);
      setPollRequest({
        question: currentPoll.question,
        responseType: currentPoll.response_type,
        duration: "240",
        options: Object.values(currentPoll.options),
      });
      setPollOption(Object.values(currentPoll.options));
      setMultiChoice(currentPoll.response_type == "multiChoice" ? true : false);
    } else {
      setPollRequest({
        question: "",
        responseType: "singleChoice",
        duration: "240",
        options: [],
      });
      setPollOption(["Option 1", "Option 2"]);
    }
    setRenderOption(false);
    setTimeout(() => {
      setRenderOption(true);
    }, 1);
  }, [currentPoll, pollMode]);

  const addPollOption = () => {
    let pollLength = pollOption.length;
    if (pollLength >= 5) {
      alert("can't have more than 5 option");
      return;
    }
    let newPollOption = "Option " + parseInt(pollLength + 1);
    setPollOption([...pollOption, newPollOption]);
  };
  const changePollOptionValue = (e) => {
    console.log(e.target.name, e.target.value);
    let requestOption = [...pollOption];
    requestOption[e.target.name] = e.target.value;
    setPollOption(requestOption);
    let pollRequestCopy = { ...pollRequest };
    pollRequestCopy.options = requestOption;
    setPollRequest(() => pollRequestCopy);
  };
  const savePoll = () => {
    notifyProgress("Changes are being saved...");
    const pollCreateURL = `session/${session_id}/poll`;
    vMeetHttp
      .post(pollCreateURL, pollRequest)
      .then((response) => {
        onClose();
        loadPoll(session_id);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const editPoll = () => {
    notifyProgress("Changes are being saved...");
    const pollCreateURL = `session/${session_id}/poll/${currentPoll.poll_id}`;
    vMeetHttp
      .put(pollCreateURL, pollRequest)
      .then((response) => {
        onClose();
        loadPoll(session_id);
        notifySuccess("Changes saved successfully!");
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error saving changes");
      });
  };
  const changeOption = () => {
    let responseType = !multiChoice ? "multiChoice" : "singleChoice";
    setPollRequest({ ...pollRequest, ["responseType"]: responseType });
    setMultiChoice(!multiChoice);
  };
  return (
    <ActionDrawer
      visible={visible}
      bodyStyle={{ padding: "22px" }}
      onClose={onClose}
      leftButtonText="Cancel"
      leftButtonAction={onClose}
      rightButtonText="Save"
      rightButtonAction={pollMode == "create" ? savePoll : editPoll}
      buttonStyle={{
        buttonBaseColor: theme.color.grey27,
        actionButtonWidth: "84px",
      }}
    >
      <AddPollHeader>
        <SectionTitle title={pollMode == "create" ? "New Poll" : "Edit Poll"} />
      </AddPollHeader>

      <Space size={[17]} direction="vertical" className="flex">
        <PollToggleWrapper multiChoice={multiChoice}>
          <SwitchBox
            label="Single Choice"
            rightLabel="Multiple Choice"
            checked={multiChoice}
            checkedChildren=""
            onChange={changeOption}
          />
        </PollToggleWrapper>

        <FormField label="Poll Question" labelStyle={{ fontWeight: 600 }}>
          <PollRow>
            <Input.TextArea
              placeholder="Type poll question"
              size="small"
              autoSize={{
                minRows: 5,
              }}
              style={pollQuestionStyle}
              disabled={false}
              onChange={onChange}
              value={pollRequest.question}
              name="question"
              maxLength="500"
            />
            <NumberOfText>
              {pollRequest.question && pollRequest.question.length} / 500
              characters left
            </NumberOfText>
          </PollRow>
          {renderOption &&
            pollOption.map((e, i) => (
              <PollOption
                placeholder={e}
                onChange={changePollOptionValue}
                name={i}
                key={i}
                isMultiChoice={multiChoice}
                value={e}
              />
            ))}

          <Div />
          <AddButton
            width="426px"
            name="+ Add Option"
            onClick={addPollOption}
            fontSize="12px"
            disabled={pollOption.length < 5 ? false : true} 
          />
        </FormField>
      </Space>
    </ActionDrawer>
  );
};
