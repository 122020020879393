import { Tag } from "antd";
import styled, { css } from "styled-components";
import { FormField } from "../../../../../common-components/form-field/FormField";

export const AddTableHeader = styled.div`
  margin: 20px 0 29px;
`;

export const AddTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    :first-child {
      flex: 0.95;
    }
  }
`;

const Box = styled(Tag)`
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  :hover {
    border-color: ${({ theme }) => theme.color.grey71};
  }
`;

export const SlotBox = styled(Box)`
  background: ${({ theme }) => theme.color.surfaceLighter_2};
  ${({ isSlotBox, active }) =>
    isSlotBox
      ? css`
          font-size: 9px;
          line-height: 20px;
          height: 26px;
          background: ${({ theme }) =>
            theme.color[active ? "accent" : "surfaceLighter_2"]};
          border: 1px solid
            ${({ theme }) => theme.color[active ? "accent" : "grey87"]};
          color: ${({ theme }) =>
            theme.color[active ? "textOnSurface" : "dimGrey_3"]};
        `
      : css`
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.075em;
          height: 30px;
          background: ${({ theme }) =>
            theme.color[active ? "accent" : "surfaceLighter_2"]};
          border: 1px solid
            ${({ theme }) => theme.color[active ? "accent" : "grey87"]};
          color: ${({ theme }) =>
            theme.color[active ? "textOnSurface" : "dimGrey_3"]};
        `}
`;

export const ClearBox = styled(Box)`
  height: 20px;
  border: 1px solid ${({ theme }) => theme.color.grey87};
  font-size: 9px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.dimGrey};
  background: ${({ theme }) => theme.color.textOnSurface};
  margin-right: 0;
`;
export const AddTableContainer = styled.div`
  padding-right: 16px;
`;
export const TimeSlotContainer = styled.div`
  height: calc(100vh - 380px);
  overflow-y: scroll;
`;

export const addTableBodyStyle = { padding: "22px", overflow: "hidden" }
